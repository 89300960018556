import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { addTagToSelectedTags, emtpySelectedTags, removeTagFromSelectedTags } from '../autoContentSlice';
import TemplateList from './template-list';
import WorksheetMakerIframeModal from '../worksheet-maker-iframe-modal';
import CollectionList from './collection-list';
import { fetchCategories } from '../../../utils/commonUtil';
import CollectionInfoModal from './collection-info-modal';
import LeftContent from './left-content';

const TemplatesManagement = ({ selectedTemplates = [], setSelectedTemplates, selectedCollections = [], setSelectedCollections, usedTemplateIDs = [], displayMode = 'display' }) => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [templates, setTemplates] = useState([]);
    const [collections, setCollections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestedTags, setSuggestedTags] = useState([]);
    const [showWorksheetMakerIframeModal, setShowWorksheetMakerIframeModal] = useState(false);
    const [showOnlyActiveTemplates, setShowOnlyActiveTemplates] = useState(true);

    const [showNewCollectionModal, setShowNewCollectionModal] = useState(false);
    const [showOnlyActiveCollections, setShowOnlyActiveCollections] = useState(true);

    const pageSize = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [nbResults, setNbResutls] = useState(0);

    const [activeTab, setActiveTab] = useState('template');

    const [filterNoCategories, setFilterNoCategories] = useState([]);

    const { selectedTags } = useSelector(state => state.autoContent);

    useEffect(() => {
        fetchAllCategories();
        fetchCollections();
    }, []);

    useEffect(() => {
        const delaySearchInputDebounce = setTimeout(() => {
            if (keyword) {
                let suggestedTags = [];
                for (let i = 0; i < categories.length; i++) {
                    const catSuggestedTags = categories[i].tags.filter(tag => {
                        if (tag.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                            return true;
                        }
                        if (keyword.toLowerCase().indexOf(tag.name.toLowerCase()) > -1) {
                            return true;
                        }
                        return false;
                    });
                    if (catSuggestedTags.length > 0) {
                        suggestedTags = suggestedTags.concat({
                            category: categories[i].name,
                            tags: catSuggestedTags
                        });
                    }
                }
                setSuggestedTags(suggestedTags);
            } else {
                setSuggestedTags([]);
            }
        }, 500);
        return () => clearTimeout(delaySearchInputDebounce);
    }, [keyword, 500]);

    const fetchAllCategories = async () => {
        const data = await fetchCategories();
        setCategories(data);
    }

    useEffect(() => {
        if (activeTab === 'template') {
            fetchTemplates();
        }
    }, [selectedTags, showOnlyActiveTemplates]);

    useEffect(() => {
        if (activeTab === 'collection') {
            fetchCollections();
        }
    }, [selectedTags, showOnlyActiveCollections]);

    const handleTabSelect = (selectedTab) => {
        setKeyword('');
        setActiveTab(selectedTab);
        dispatch(emtpySelectedTags());
        setFilterNoCategories([]);

        if (selectedTab === 'template') {
            fetchTemplates(null, currentPage, '');
        } else {
            fetchCollections('');
        };
    }

    const resetTemplatesPagination = () => {
        setCurrentPage(1);
        setTotalPages(1);
    }

    const fetchTemplates = async (e, pageIndex = currentPage, provideKeyword = null) => {
        let searchKeyword = provideKeyword !== null ? provideKeyword : keyword;

        if (e) {
            e.preventDefault();
        }
        setLoading(true);
        try {
            const tagIds = selectedTags.map(tag => tag.id);
            let url = `auto-content/templates/?keyword=${searchKeyword}&tags=${tagIds.join(',')}&page=${pageIndex}&page_size=${pageSize}&filterNoCategories=${filterNoCategories.map(cat => cat.name).join(',')}`;
            if (showOnlyActiveTemplates) {
                console.log('only ', showOnlyActiveTemplates);
                url += '&onlyActiveTemplates=true';
            }
            console.log('url', url);
            const res = await getDataFromAPI(url);
            if (res) {
                const data = res.data;
                setNbResutls(data.count)
                setTotalPages(Math.ceil(data.count / pageSize))
                const templates = data.results;
                templates.sort((a, b) => b.id - a.id);
                setTemplates(templates);
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
        setLoading(false);
    }

    const fetchCollections = async (provideKeyword = null) => {
        let searchKeyword = provideKeyword !== null ? provideKeyword : keyword;
        setLoading(true);
        const tagIds = selectedTags.map(tag => tag.id);

        let url = `auto-content/collections/?keyword=${searchKeyword}&tags=${tagIds.join(',')}&filterNoCategories=${filterNoCategories.map(cat => cat.name).join(',')}`;

        if (showOnlyActiveCollections) {
            url += '&onlyActiveCollections=true';
        }
        const res = await getDataFromAPI(url);
        setCollections(res.data);
        setLoading(false);
    }

    const handleSubmitSearchTemplateByTag = (e) => {
        resetTemplatesPagination();
        fetchTemplates(e, 1);
    }

    const handleSubmitSearchCollection = () => {
        fetchCollections();
    }

    const defineModeSearch = (e) => {
        e.preventDefault();
        if (activeTab === 'template') {
            handleSubmitSearchTemplateByTag(e);
        } else {
            handleSubmitSearchCollection();
        }
    }

    useEffect(() => {
        const refreshRightContent = async () => {
            const removedTags = [...selectedTags].filter(((tag) => filterNoCategories.some((cat) => tag.categoryName === cat.name)));
            removedTags.forEach((removedTag) => dispatch(removeTagFromSelectedTags({ id: removedTag.id })));
            if (activeTab === 'template') {
                await fetchTemplates(null, 1);
            } else if (activeTab === 'collection') {
                await fetchCollections();
            }
        };

        refreshRightContent();
    }, [filterNoCategories]);

    const toggleFilterNoCategoriesList = async (category) => {
        const isSelected = filterNoCategories.some(cat => cat.id === category.id);
        if (isSelected) {
            setFilterNoCategories((prev) => prev.filter((cat) => cat.id !== category.id));
        } else {
            setFilterNoCategories((prev) => ([...prev, category]))
        };
    }

    return (
        <Container className="templates-management">
            <Row>
                <Col md={3}>
                    <LeftContent
                        categories={categories}
                        setCategories={setCategories}
                        selectedTags={selectedTags}
                        showOnlyActiveTemplates={showOnlyActiveTemplates}
                        showOnlyActiveCollections={showOnlyActiveCollections}
                        setShowOnlyActiveTemplates={setShowOnlyActiveTemplates}
                        setShowOnlyActiveCollections={setShowOnlyActiveCollections}
                        activeTab={activeTab}
                        filterNoCategories={filterNoCategories}
                        fetchAllCategories={fetchAllCategories}
                        toggleFilterNoCategoriesList={toggleFilterNoCategoriesList}
                    />
                </Col>
                <Col md={9}>
                    <div className="templates-header">
                        <Form onSubmit={defineModeSearch} className="search-form">
                            <InputGroup controlId="activity">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter keyword"
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                                <Button variant="primary" onClick={defineModeSearch}>
                                    Search
                                </Button>
                                <FontAwesomeIcon icon={faClose} className="clear-keyword-icon" onClick={() => setKeyword('')} />
                                {suggestedTags.length > 0 && (
                                    <div className="suggested-tags">
                                        {suggestedTags.map((cat, catIndex) => (
                                            cat.tags.map((tag, tagIndex) => (
                                                <div key={catIndex + '-' + tagIndex} onClick={() => dispatch(addTagToSelectedTags({ tag }))}>
                                                    {cat.category} - {tag.name}
                                                </div>
                                            ))
                                        ))}
                                    </div>
                                )}
                            </InputGroup>
                        </Form>

                        {displayMode === 'display' && (
                            <div>
                                <Button variant="success" onClick={() => setShowWorksheetMakerIframeModal(true)}>New Template</Button>
                                <Button onClick={() => setShowNewCollectionModal(true)}>New Collection</Button>
                            </div>
                        )}

                    </div>

                    <div className="nb-results">{nbResults} templates</div>

                    <Tabs fill activeKey={activeTab} onSelect={handleTabSelect}>
                        <Tab eventKey="template" title={'Template (' + templates.length + ')'}>
                            <TemplateList
                                templates={templates}
                                setTemplates={setTemplates}
                                selectedTemplates={selectedTemplates}
                                usedTemplateIDs={usedTemplateIDs}
                                setSelectedTemplates={setSelectedTemplates}
                                categories={categories}
                                fetchTemplates={fetchTemplates}
                                fetchAllCategories={fetchAllCategories}
                                showOnlyActiveTemplates={showOnlyActiveTemplates}
                                currentPage={currentPage}
                                resetTemplatesPagination={resetTemplatesPagination}
                                totalPages={totalPages}
                                handlePageChange={(pageIndex) => {
                                    setCurrentPage(pageIndex);
                                    fetchTemplates(null, pageIndex);
                                }}
                                showPagination={true}
                            />
                        </Tab>
                        <Tab eventKey="collection" title={'Collection (' + collections.length + ')'}>
                            <CollectionList
                                collections={collections}
                                setCollections={setCollections}
                                selectedCollections={selectedCollections}
                                setSelectedCollections={setSelectedCollections}
                                fetchCollections={fetchCollections}
                                refreshCategories={fetchAllCategories}
                                categories={categories}
                                fetchAllCategories={fetchAllCategories}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>

            {showWorksheetMakerIframeModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id=""
                    hideModal={() => {
                        setShowWorksheetMakerIframeModal(false);
                        fetchTemplates();
                    }}
                />
            )}

            {showNewCollectionModal && (
                <CollectionInfoModal
                    collection={null}
                    hideModal={() => setShowNewCollectionModal(false)}
                    collections={collections}
                    setCollections={setCollections} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default TemplatesManagement;