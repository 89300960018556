import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import Constant from '../../Constant';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import { getDataFromAPI } from '../../utils/query';
import SearchIdeaInput from '../../filters/search-idea-input';
import { hasPermission } from '../../utils/auth';
import { useSelector } from 'react-redux';

const DownloadsPage = () => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [selectedProductIdea, setSelectedProductIdea] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        const selectedProduct = (new URLSearchParams(window.location.search)).get('selectedProduct');
        if (selectedProduct) {
            getDataFromAPI('resources/search-product-ideas/?keyword=' + selectedProduct + '&additionalFields=storeData').then(res => {
                const foundProduct = res.data.find(idea => idea.customID === selectedProduct);
                if (foundProduct) {
                    setSelectedProductIdea(foundProduct);
                }
            });
        }
    }, []);

    return (
        <Container className="form-downloads">
            <Form>
                <Row>
                    <Col>
                        <h2>Product</h2>
                        <SearchIdeaInput selectedIdea={setSelectedProductIdea} />
                        {selectedProductIdea && selectedProductIdea.storeData.descriptionUrl && (
                            <a href={selectedProductIdea.storeData.descriptionUrl} target="_blank" rel="noreferrer">{selectedProductIdea.storeData.name} - Description File</a>
                        )}
                        {selectedProductIdea && selectedProductIdea.standards && (
                            <div>
                                <a href="https://docs.google.com/spreadsheets/d/1IbbLXGogCGX2odfg0MbRhjjQ022dHkJftwySDnF_EX0/edit?usp=sharing" target="_blank" rel="noreferrer">Standards:</a>
                                <span> {selectedProductIdea.standards}</span>
                            </div>
                        )}
                    </Col>
                    <Col>
                        {selectedProductIdea && (
                            <div>
                                <h2>{selectedProductIdea.customID}. {selectedProductIdea.title ? selectedProductIdea.title : selectedProductIdea.name}</h2>
                                <div className="links">
                                    {hasPermission(userRoles, activityPermissions['DOWNLOAD_PAID_FILE']) && selectedProductIdea.paidFileDriveID && (
                                        <Button variant="primary" href={'https://drive.google.com/file/d/' + selectedProductIdea.paidFileDriveID} target="_blank">
                                            Download Paid File
                                        </Button>
                                    )}
                                    {hasPermission(userRoles, activityPermissions['DOWNLOAD_PREVIEW_FILE']) && selectedProductIdea.previewFileDriveID && (
                                        <Button variant="success" href={'https://drive.google.com/file/d/' + selectedProductIdea.previewFileDriveID} target="_blank">
                                            Download Preview File
                                        </Button>
                                    )}
                                    {hasPermission(userRoles, activityPermissions['DOWNLOAD_FREE_FILE']) && selectedProductIdea.freeFileDriveID && (
                                        <Button variant="dark" href={'https://drive.google.com/file/d/' + selectedProductIdea.freeFileDriveID} target="_blank">
                                            Download Free File
                                        </Button>
                                    )}
                                </div>
                                <div className="thumbnails">
                                    <div>
                                        {selectedProductIdea.thumbnail && (
                                            <img src={selectedProductIdea.thumbnail} />
                                        )}
                                        <div>Thumbnail</div>
                                    </div>
                                    <div>
                                        {selectedProductIdea.thumbnailFree && (
                                            <img src={selectedProductIdea.thumbnailFree} />
                                        )}
                                        <div>Thumbnail Free</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default DownloadsPage;