import { useEffect, Fragment, useState } from 'react';
import UploadTopicImageCell from '../upload-topic-image-cell';
import EventListSelect from '../../event-groups/event-list-select';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { updateDataAPI } from '../../../utils/query';

import './styles.scss';

const TopicGroupRow = ({
    topicGroup,
    handleUpdateTopicGroupFields,
    handleSaveTopicGroupChanges,
    handleDeleteTopicGroup,
    selectedTopicGroup,
    setSelectedTopicGroup,
}) => {
    const topicNames = topicGroup?.topics || [];
    const isTopicGroupSelected = selectedTopicGroup?.id === topicGroup.id;
    const topicsLength = topicNames.length !== 0 ? topicNames.length : 1;

    const [topics, setTopics] = useState([]);
    const [topicGroupIcons, setTopicGroupIcons] = useState([]);
    const [searchingWordID, setSearchingWordID] = useState(null);
    const [searchingWordIcons, setSearchingWordIcons] = useState(null);

    useEffect(() => {
        const fetchTopicsByName = async () => {
            if (topicNames.length > 0) {
                try {
                    const url = 'resources/fetch-topics-by-name/';

                    const res = await updateDataAPI('POST', url, { topicNames });

                    setTopics(res.data);

                    await fetchTopicGroupIcons(res.data);
                } catch (err) {
                    console.error(`An error occured when fetching topics by name: ${err.message}`);
                };
            }
        }

        fetchTopicsByName();
    }, [topicNames]);

    const fetchTopicGroupIcons = async (listTopics = []) => {
        if (listTopics.length > 0 && topicGroup?.id) {
            try {
                const url = 'resources/fetch-topic-group-icons-by-topics-and-topic-group/';

                const res = await updateDataAPI('POST', url, {
                    topicIDs: listTopics.map((topic) => topic.id),
                    topicGroupID: topicGroup.id,
                });

                console.log(res.data);
                setTopicGroupIcons(res.data);
            } catch (err) {
                console.error(`An error occured when fetching topic group icons: ${err.message}`);
            };
        }
    }

    const handleUpdatedTopic = (updatedTopic) => {
        setTopics((prev) => prev.map((t) => {
            if (t.id === updatedTopic.id) return updatedTopic;
            return t;
        }));
    };

    const handleChangeTopicGroupIcon = (updatedTopicGroupIcon) => {
        if (updatedTopicGroupIcon) {
            setTopicGroupIcons((prev) => prev.map((topicGroupIcon) => {
                if (topicGroupIcon.id === updatedTopicGroupIcon.id) {
                    return updatedTopicGroupIcon;
                }
                return topicGroupIcon;
            }));
        }
    }

    const searchIconsForWord = async(wordID, word) => {
        setSearchingWordIcons([]);
        word = word.trim();
        word = word.replace(/[^a-zA-Z\s]/g, '');
        console.log(word);
        setSearchingWordID(wordID);
        const res = await updateDataAPI('POST', 'auto-content/search-resources-by-keyword/', {
            keyword: word,
            limit: 40,
            offset: 1,
            typeResource : [5]
        });
        setSearchingWordIcons(res.data);
    }

    const updateTopicIcon = async(topicID, iconID, url) => {
        const res = await updateDataAPI('POST', 'resources/update-topic-icon/', {
            topicGroupID: topicGroup.id,
            topicID,
            iconID,
        });
        setTopics((prev) => prev.map((topic) => {
            if (topic.id === topicID) {
                return {...topic, imageUrl: url};
            }
            return topic;
        }));
        console.log('>', topicID, iconID, url);
        if (topicGroupIcons.find((topicGroupIcon) => topicGroupIcon.topic === topicID)) {
            setTopicGroupIcons((prev) => prev.map((topicGroupIcon) => {
                if (topicGroupIcon.topic === topicID) {
                    return res.data;
                }
                return topicGroupIcon;
            }));
        } else {
            setTopicGroupIcons(topicGroupIcons.concat(res.data));
        }
        setSearchingWordID(null);
    }

    return (
        <>
            <tr key={topicGroup.id} className="topic-group-row">
                <td>{topicGroup.id}</td>
                <td className="name-cell">
                    {isTopicGroupSelected ? (
                        <Form.Control
                            type="text"
                            placeholder="New event group name"
                            value={selectedTopicGroup?.name || ''}
                            onChange={(e) => handleUpdateTopicGroupFields('name', e.target.value)}
                        />) : (
                        <span className="event-groups-page__group-name">{topicGroup.name}</span>
                    )}
                </td>
                <td className="events-cell">
                    <EventListSelect
                        topicGroup={!isTopicGroupSelected ? topicGroup : selectedTopicGroup}
                        handleUpdateTopicGroupFields={handleUpdateTopicGroupFields}
                        isDisabled={!isTopicGroupSelected}
                    />
                </td>
                <td className="topics-textarea-cell">
                    {!isTopicGroupSelected ? (
                        <Form.Control
                            as="textarea"
                            rows={topicsLength * 5}
                            value={topicNames.join('\n')}
                            readOnly
                        />
                    ) : (
                        <Form.Control
                            as="textarea"
                            rows={(topicsLength) * 5}
                            value={selectedTopicGroup?.topics || ''}
                            onChange={(e) => handleUpdateTopicGroupFields('topics', e.target.value)}
                            disabled={!isTopicGroupSelected}
                        />
                    )}
                </td>
                <td className="topics-table-cell">
                    <Table>
                        <thead>
                            <tr>
                                <th>Topic</th>
                                <th>Upload topic image</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topics.map((topic) => (
                                <tr key={topic.id}>
                                    <td>
                                        <div className="topic-words">
                                            {topic.name.split(' ').map((word, wordIndex) => (
                                                <>
                                                    <span onClick={() => searchIconsForWord(topic.id + '-' + wordIndex, word)} key={wordIndex}>
                                                        {word}
                                                    </span>
                                                    {searchingWordID === topic.id + '-' + wordIndex && searchingWordIcons && (
                                                        <div className="searching-word-icons">
                                                            {searchingWordIcons.map((icon, iconIndex) => (
                                                                <img key={iconIndex} src={icon.url} alt={icon.name} onClick={() => {
                                                                    updateTopicIcon(topic.id, icon.id, icon.url);
                                                                }} />
                                                            ))}

                                                            <span onClick={() => setSearchingWordID(null)}>close</span>
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        <UploadTopicImageCell
                                            topicGroup={topicGroup}
                                            topic={topic}
                                            topicGroupIcon={topicGroupIcons.find((topicGroupIcon) => topicGroupIcon.topic === topic.id)}
                                            setTopicGroupIcon={handleChangeTopicGroupIcon}
                                            handleUpdatedTopic={handleUpdatedTopic}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </td>
                <td>
                    <div className="d-flex gap-2">
                        {isTopicGroupSelected ? (
                            <Fragment>
                                <Button
                                    size="sm"
                                    variant="success"
                                    onClick={handleSaveTopicGroupChanges}
                                >
                                    Update
                                </Button>
                                <Button
                                    size="sm"
                                    variant="warning"
                                    onClick={() => setSelectedTopicGroup(null)}
                                >
                                    Cancel
                                </Button>
                            </Fragment>
                        ) : (
                            <Button
                                size="sm"
                                variant="primary"
                                className="me-2"
                                onClick={() => setSelectedTopicGroup({
                                    ...topicGroup,
                                    topics: topicNames.join('\n')
                                })}
                            >
                                Edit
                            </Button>
                        )}

                        <Button
                            size="sm"
                            variant="danger"
                            className="me-2"
                            onClick={() => handleDeleteTopicGroup(topicGroup.id)}
                        >
                            Delete
                        </Button>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default TopicGroupRow;