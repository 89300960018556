import { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';
import { getDataFromAPI } from '../../../utils/query';
import './styles.scss';
import CreatableSelect from 'react-select/creatable';
import NewEventModal from '../new-event-modal';
import { useSelector } from 'react-redux';

export const EventCalendarLabel = ({ option }) => (
    <div className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            <div className="fw-bold">{!option?.name ? option.value : option.name}</div>
            <div className="event-calendar">
                {option.startDate && (
                    <i>{moment(option.startDate).format('DD/MM')}</i>
                )}
                {option.endDate && (
                    <i> - {moment(option.endDate).format('DD/MM')}</i>
                )}
            </div>
        </div>
        {option.bigEvent && (
            <Badge bg="danger" pill>
                Big event
            </Badge>
        )}
    </div>
)

export const initEventOptions = (input = []) => {
    return input.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
    }));
};


const EventListSelect = ({
    topicGroup = {},
    handleUpdateTopicGroupFields = () => { },
    isDisabled=false,
}) => {
    const { events } = useSelector((state) => state.autoContent);
    const [eventOptions, setEventOptions] = useState([]);
    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [newEventName, setNewEventName] = useState('');

    useEffect(() => {
        const generateEventOptions = () => {
            const options = initEventOptions(events);
            setEventOptions(options);
        };

        generateEventOptions();
    }, [events]);

    const handleCreateEventOption = (inputValue) => {
        setNewEventName(inputValue);
        setShowNewEventModal(true);
    };

    const handleAddNewEventToTopicGroup = (newEvent = {}) => {
        handleUpdateTopicGroupFields('events', [...topicGroup.events, newEvent.id]);
        handleUpdateTopicGroupFields('eventDatas', [...topicGroup.eventDatas, newEvent]);
    };

    return (
        <>
            <CreatableSelect
                value={initEventOptions(topicGroup.eventDatas)}
                isMulti
                isSearchable
                name="events"
                options={eventOptions}
                className="event-list-select"
                classNamePrefix="select"
                formatOptionLabel={(option) => (<EventCalendarLabel option={option} />)}
                onChange={(selectedValues) => {
                    handleUpdateTopicGroupFields('events', selectedValues.map((value) => value.id));
                    handleUpdateTopicGroupFields('eventDatas', selectedValues);
                }}
                onCreateOption={handleCreateEventOption}
                isDisabled={isDisabled}
            />

            {showNewEventModal && (
                <NewEventModal
                    newEventName={newEventName}
                    hideModal={() => setShowNewEventModal(false)}
                    setEventOptions={setEventOptions}
                    handleAddNewEventToTopicGroup={handleAddNewEventToTopicGroup}
                />
            )}
        </>
    );
};

export default EventListSelect;