import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import { Button, Container, ProgressBar, Spinner, Table } from 'react-bootstrap';
import SettingDesignTicketModal from '../../components/design-ticket/setting-design-ticket-modal';
import Moment from 'react-moment';

export const FILTER_RANGE_TIME_DAYS = 3;

const DesignTicketsPage = () => {
    const [tickets, setTickets] = useState([]);
    const [ticketInSettingModal, setTicketInSettingModal] = useState(null);
    const [showSettingTicketModal, setShowSettingTicketModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async() => {
        setLoading(true);
        const res = await getDataFromAPI(`product-dev/design-tickets?filterRangeTimeDays=${FILTER_RANGE_TIME_DAYS}`);
        setTickets(res.data);
        setLoading(false);
    }

    const deleteTicket = async(ticket) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-dev/design-tickets/' + ticket.id + '/');
            setTickets(tickets.filter(t => t.id !== ticket.id));
            setLoading(false);
        }
    }

    return (
        <Container className="design-tickets-page" fluid>
            <h2>Design Tickets</h2>
            <div className="btns">
                <Button onClick={fetchTickets}>Refresh</Button>
                <Button onClick={() => setShowSettingTicketModal(true)}>Add New Ticket</Button>
            </div>
            <div>Pending PC: {tickets.filter(ticket => !ticket.done && ticket.authorRoles.indexOf('product-collector') > -1).length} tickets</div>
            <div>Pending Designers: {tickets.filter(ticket => !ticket.done && ticket.authorRoles.indexOf('designer') > -1).length} tickets</div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Idea</th>
                        <th>Template</th>
                        <th>Reset Design</th>
                        <th>Auto Resize Text</th>
                        <th>Progress</th>
                        <th>Author</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map(ticket => (
                        <tr key={ticket.id}>
                            <td><a href={'/content-studio/?customID=' + ticket.customID} target="_blank" rel="noreferrer">{ticket.customID}</a></td>
                            <td>{ticket.template}</td>
                            <td>
                                {ticket.resetDesignItems && (
                                    <span>{ticket.resetDesignItems.join(', ')}</span>
                                )}
                            </td>
                            <td>{ticket.autoResizeText ? 'Yes' : ''}</td>
                            <td>
                                <ProgressBar striped variant={ticket.done ? 'success' : 'warning'}
                                    now={ticket.done ? 100 : Math.floor(((ticket.finishedPages ? ticket.finishedPages.length : 0) / ticket.totalPages) * 100)} />
                            </td>
                            <td>{ticket.authorName}</td>
                            <td><Moment format="HH:mm DD/MM/YYYY">{ticket.createdAt}</Moment></td>
                            <td>
                                <Button size="sm" variant="primary" onClick={() => {
                                    setTicketInSettingModal(ticket);
                                    setShowSettingTicketModal(true);
                                }} className="edit-btn">Edit</Button>
                                <Button size="sm" variant="danger" onClick={() => deleteTicket(ticket)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showSettingTicketModal && (
                <SettingDesignTicketModal
                    initialTicket={ticketInSettingModal}
                    hideModal={action => {
                        setTicketInSettingModal(null);
                        setShowSettingTicketModal(false);
                        if (action === 'refresh') {
                            fetchTickets();
                        }
                    }} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default DesignTicketsPage;