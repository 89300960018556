import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTaskStep } from '../filtersSlice';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';

const FilterByTaskStep = ({ value }) => {
    const dispatch = useDispatch();

    const selectedTaskStep = useSelector((state) => state.filters.selectedTaskStep);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        fetchTaskSteps();
    }, []);

    const fetchTaskSteps = async () => {
        const res = await getDataFromAPI('product-dev/task-flow-steps/');
        setSteps(res.data);
    }

    return (
        <div className="filter-by-task-steps">
            <Form.Select onChange={e => {
                if (e.target.value !== null) {
                    dispatch(setSelectedTaskStep(parseInt(e.target.value)));
                }
            }} value={selectedTaskStep}>
                <option value={null}>Task Step</option>
                {steps.map(step => (
                    <option value={step.id} key={step.id}>{step.categoryName}. {step.label}</option>
                ))}
            </Form.Select>
        </div>
    );
}

export default FilterByTaskStep;