
import { Form, Alert } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faClose, faRobot } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const SearchIdeaInput = ({ selectedIdea, store = null }) => {
    const [searchKeyword, setSearchKeyword] = useState();
    const [result, setResult] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [searching, setSearching] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        const delayInputTitleTimeoutID = setTimeout(async() => {
            if (searchKeyword) {
                setSearching(true);
                let url = 'resources/search-product-ideas/?keyword=' + searchKeyword;
                if (store) {
                    url += '&store=' + store;
                }
                await getDataFromAPI(url + '&additionalFields=storeData').then(res => {
                    setResult(res.data);
                    setNoResults(res.data.length === 0);
                });
                setSearching(false);
            } else {
                setResult([]);
            }
        }, 500);
        return () => clearTimeout(delayInputTitleTimeoutID);
    }, [searchKeyword, 500]);

    return (
        <Form className="search-idea-input-form" onSubmit={e => e.preventDefault()}>
            {searching && <div>Searching...</div>}
            <Form.Control
                ref={inputRef}
                placeholder="Search Idea"
                value={searchKeyword}
                onChange={e => setSearchKeyword(e.target.value)}
                />
            <FontAwesomeIcon className="close-icon" icon={faClose} onClick={() => setSearchKeyword('')} />
            {noResults && (
                <Alert variant="danger" className="mt-3">Can not find any idea with this keyword "{searchKeyword}"</Alert>
            )}
            {result.length > 0 && (
                <div className="result">
                    {result.map(idea => (
                        <div key={idea['id']} className="result-item"
                            onClick={() => {
                                selectedIdea(idea);
                                setSearchKeyword('');
                            }}>
                            <div>
                                <div>Store {idea['store']} - Idea {idea['customID']} - {idea['task']?.step}</div>
                                <div><strong>{(idea['title'] ? idea['title'] : idea['name'])}</strong></div>
                            </div>
                            <div>
                                <div className="link-icons">
                                    <FontAwesomeIcon icon={faRobot} onClick={() => window.open(window.location.origin + '/content-studio/?customID=' + idea.customID, '_blank', 'noreferrer')} />
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} onClick={() => window.open(window.location.origin + '/ideas?selectedStoreID=' + idea['store'] + '&searchKeyword=' + idea['customID'], '_blank', 'noreferrer')} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Form>
    )
};

export default SearchIdeaInput;