import { useEffect, useState } from 'react';
import Constant from '../../../Constant';
import './styles.scss';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCopy, faRobot, faTable } from '@fortawesome/free-solid-svg-icons';

import IdeaModal from '../../../pages/idea-list/idea-modal';
import { useSelector } from 'react-redux';
import Notification from '../../../utils/notification';
import Highlighter from 'react-highlight-words';
import { Badge, Button } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';
import BundleModal from '../bundle-modal';
import { hasPermission } from '../../../utils/auth';

const IdeasListTable = ({
    editingIdea = {},
    setEditingIdea = () => {},
    showIdeaModal = () => {},
    ideas,
    fetchIdeas,
    selectedIdeaIDs,
    setSelectedIdeaIDs
}) => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const selectedCategory = useSelector((state) => state.filters.selectedCategory);
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);
    const selectedGrades = useSelector((state) => state.filters.selectedGrades);
    const selectedSubjects = useSelector((state) => state.filters.selectedSubjects);
    const selectedTaskStep = useSelector((state) => state.filters.selectedTaskStep);
    const { routePermissions, activityPermissions } = useSelector((state) => state.permissions);


    const [userRoles, setUserRoles] = useState([]);
    const [notification, setNotification] = useState(null);
    const [duplicatingIdea, setDuplicatingIdea] = useState(null);
    const [activeTPTProducts, setActiveTPTProducts] = useState([]);
    const [focusingIdea, setFocusingIdea] = useState(null);
    const [bundleInModal, setBundleInModal] = useState(null);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        console.log('>>>', ideas);
    }, [ideas]);

    const getFilteredIdeas = () => {
        const filteredProducts = ideas.filter(p => {
            if (selectedStoreID !== null && !isNaN(selectedStoreID) && p.store !== selectedStoreID) {
                return false;
            }
            if (selectedTaskStep !== null && p.task?.step !== selectedTaskStep) {
                return false;
            }
            if (searchKeyword) {
                let found = false;
                const keywords = searchKeyword.toLowerCase().trim().split(' ');
                for (let i = 0; i < keywords.length; i++) {
                    if (p.name.toLowerCase().indexOf(keywords[i]) > -1 ||
                        (p.title && p.title.toLowerCase().indexOf(keywords[i]) > -1) ||
                        p.customID.toLowerCase().indexOf(keywords[i]) > -1) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    return false;
                }
            }
            if (selectedCategory && p.category !== selectedCategory) {
                return false;
            }
            if (selectedGrades.length > 0) {
                if (p.grades === null) {
                    return false;
                } else if (!p.grades.split(',').some(item => selectedGrades.includes(item))) {
                    return false;
                }
            }
            if (selectedSubjects.length > 0) {
                if (p.subjects === null) {
                    return false;
                } else if (!p.subjects.split(',').some(item => selectedSubjects.includes(item))) {
                    return false;
                }
            }
            return true;
        });
        return filteredProducts;
    }

    const fetchTPTProducts = async (product) => {
        setActiveTPTProducts([]);
        if (focusingIdea !== product.id) {
            setFocusingIdea(product.id);
            const res = await getDataFromAPI('products/?customID=' + product.customID);
            setActiveTPTProducts(res.data);
        } else {
            setFocusingIdea(null);
        }
    }

    return (
        <div className="idea-list-table">
            <div className="table-prefix">
                <div className="table-right">
                    <div className="table-nb-lines">
                        {getFilteredIdeas().length} results
                    </div>
                </div>
            </div>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>
                            <Form.Check type="checkbox" id={'checkbox-all-product'}
                                checked={selectedIdeaIDs.length === getFilteredIdeas().length}
                                onChange={() => {
                                    const uploadedProducts = getFilteredIdeas().filter(product => product.task?.stepDone);
                                    if (selectedIdeaIDs.length === uploadedProducts.length) {
                                        setSelectedIdeaIDs([]);
                                    } else {
                                        setSelectedIdeaIDs(uploadedProducts.map(p => p.id));
                                    }
                                }} />
                        </th>
                        {isNaN(selectedStoreID) && (
                            <th>Store</th>
                        )}
                        <th>Code</th>
                        <th>Thumbnail</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Subjects</th>
                        <th>Grades</th>
                        <th>Task Prio</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {getFilteredIdeas().map((idea, index) => {
                        return (
                            <tr key={idea.id}>
                                <td className="index-cell">
                                    {index + 1}
                                    {idea.task?.stepDone && (
                                        <Form.Check type="checkbox" id={'checkbox-product-' + idea.id}
                                            checked={selectedIdeaIDs.indexOf(idea.id) > -1}
                                            onChange={() => {
                                                if (selectedIdeaIDs.indexOf(idea.id) > -1) {
                                                    setSelectedIdeaIDs(selectedIdeaIDs.filter(cID => cID !== idea.id));
                                                } else {
                                                    setSelectedIdeaIDs(selectedIdeaIDs.concat([idea.id]));
                                                }
                                            }} />
                                    )}
                                </td>
                                {isNaN(selectedStoreID) && (
                                    <th>{idea.storeData.name}</th>
                                )}
                                <td>
                                    <div onClick={() => showIdeaModal(idea)} className="idea-customID">
                                        <strong>{idea.customID}</strong>
                                    </div>
                                </td>
                                <td className="product-thumbnail-cell">
                                    {idea.thumbnail && (
                                        <img src={idea.thumbnail} className="product-thumbnail" />
                                    )}
                                </td>
                                <td className="product-name-cell">
                                    <div onClick={() => showIdeaModal(idea)} className="product-name" title="edit">
                                        {searchKeyword ? (
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={searchKeyword.toLowerCase().trim().split(' ')}
                                                autoEscape={true}
                                                textToHighlight={idea.name}
                                            />
                                        ) : (
                                            <div>{idea.name}</div>
                                        )}
                                    </div>
                                    {idea.title && (
                                        <div className="product-title">Title:
                                            {searchKeyword ? (
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={searchKeyword.toLowerCase().trim().split(' ')}
                                                    autoEscape={true}
                                                    textToHighlight={idea.title}
                                                />
                                            ) : (
                                                <span>{idea.title}</span>
                                            )}
                                        </div>
                                    )}
                                    {/* {productBundles.length > 0 && (
                                        <div className="product-bundles">
                                            <div className="product-nb-bundles">In {productBundles.length} bundles:</div>
                                            <div className="product-bundles-ideas">
                                                {productBundles.map(bundle => (
                                                    <div key={bundle.id} onClick={() => setEdittingBundle(bundle)}>
                                                        - {bundle.productIdeas.map(idea => idea.title.substr(0, 50) + '...').join(' + ')}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )} */}
                                    <div className="other-tools">
                                        {hasPermission(userRoles, routePermissions['/downloads']) && idea.paidFileDriveID && idea.freeFileDriveID && idea.previewFileDriveID && (
                                            <a title="download" href={'/downloads?selectedProduct=' + idea.customID} target="_blank" className="tool-icon">
                                                <FontAwesomeIcon icon={faDownload} />
                                            </a>
                                        )}
                                        {hasPermission(userRoles, activityPermissions['DUPLICATE_PRODUCT_IDEA']) && (
                                            <span title="duplicate" onClick={() => setDuplicatingIdea(idea)} className="tool-icon">
                                                <FontAwesomeIcon icon={faCopy} />
                                            </span>
                                        )}
                                        {hasPermission(userRoles, routePermissions['/content-studio']) && idea.category === Constant.WORKSHEET_CATEGORY_ID && (
                                            <a title="ai studio" href={'/outline-idea?customID=' + idea.customID} target="_blank" className="tool-icon">
                                                <FontAwesomeIcon icon={faTable} />
                                            </a>
                                        )}
                                        {hasPermission(userRoles, routePermissions['/content-studio']) && (
                                            <a title="ai studio" href={'/content-studio?customID=' + idea.customID} target="_blank" className="tool-icon">
                                                <FontAwesomeIcon icon={faRobot} />
                                            </a>
                                        )}
                                        {hasPermission(userRoles, activityPermissions['SHOW_TPT_PRODUCTS']) && idea.relatedProductIDs.length > 0 && (
                                            <span className="tool-icon show-tpt-product-links" onClick={() => fetchTPTProducts(idea)}>
                                                <Badge bg="secondary">TPT</Badge>
                                                {focusingIdea === idea.id && activeTPTProducts.length > 0 && (
                                                    <div>
                                                        {activeTPTProducts.map(product => (
                                                            <a href={product.url} target="_blank" key={product.id}>{product.saleStatus} - {product.title}</a>
                                                        ))}
                                                    </div>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div>{idea.categoryName}</div>
                                    {idea.category === Constant.BUNDLE_CATEGORY_ID && (
                                        <Button size="sm" variant="info" className="mt-2" onClick={() => setBundleInModal(idea)}>Manage Ideas</Button>
                                    )}
                                </td>
                                <td>{idea.subjects}</td>
                                <td>{idea.grades}</td>
                                <td>{idea.task?.priority ? 'Yes' : ''}</td>
                                <td>{idea.task?.stepLabel}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {editingIdea !== null && (
                <IdeaModal
                    idea={editingIdea}
                    defaultStore={editingIdea.store}
                    closeModal={action => {
                        if (action === 'update') {
                            setNotification('Update successfully');
                            fetchIdeas();
                        } else if (action === 'delete') {
                            setNotification('Delete successfully');
                            fetchIdeas();
                        }

                        setEditingIdea(null);
                    }}
                />
            )}
            {notification && (
                <Notification title={notification} closeNotification={() => setNotification(null)} />
            )}
            {duplicatingIdea !== null && (
                <IdeaModal idea={duplicatingIdea} defaultStore={duplicatingIdea.store} duplicating={true} closeModal={() => {
                    setNotification('Duplicate successfully');
                    setDuplicatingIdea(null);
                    fetchIdeas();
                }} />
            )}
            {bundleInModal !== null && (
                <BundleModal
                    bundle={bundleInModal}
                    allProducts={ideas.filter(p => p.store === selectedStoreID)}
                    closeModal={action => {
                        if (action === 'update') {
                            fetchIdeas();
                        }
                        setBundleInModal(null);
                    }} />
            )}
        </div>
    );
}

export default IdeasListTable;