import { Modal } from 'react-bootstrap';
import SearchIdeaInput from '../../filters/search-idea-input';
import IdeaModal from '../../pages/idea-list/idea-modal';
import { useState } from 'react';

const QuickSearchIdeaModal = ({hideModal}) => {
    const [editingIdea, setEditingIdea] = useState(null);

    return (
        <Modal show={true} onHide={hideModal} className="quick-search-idea-modal" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Quick Search Idea</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchIdeaInput selectedIdea={setEditingIdea} />
            </Modal.Body>

            {editingIdea !== null && (
                <IdeaModal
                    idea={editingIdea}
                    defaultStore={editingIdea.store}
                    closeModal={() => setEditingIdea(null)}
                />
            )}
        </Modal>
    )
}

export default QuickSearchIdeaModal;