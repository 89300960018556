import { Fragment, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import { getZoneLabel, getZonesOfResource } from '../../../../utils/autoContentUtils';
import { Col, Row, Table } from 'react-bootstrap';
import { checkObjectHasNullebaleValue } from '../../../../utils/commonUtil';
import './styles.scss';

export const canEditTemplateRequirementFields = [
    {
        id: 1,
        field: 'targetTemplate',
        type: 'select',
    },
    {
        id: 2,
        field: 'accessPath',
        type: 'text',
    },
]

export const getTargetOptionsForCurrentTemplateRequirement = (options = [], currentTargetTemplateID, requirements = []) => {
    const templateRequirementsSet = new Set(requirements.map(templateRequirement => templateRequirement.targetTemplate));

    return options.filter(option => {
        if (option.value === currentTargetTemplateID) {
            return true;
        }
        return !templateRequirementsSet.has(option.id);
    });
}

const TemplateRequirementsSettingModal = ({
    currentTemplate = {},
    templates,
    setTemplates,
    listMainTemplates = [],
    collection,
    hideModal = () => { }
}) => {
    const initialNewTemplateRequirementValue = {
        accessPath: null,
        template: currentTemplate['id'],
        targetTemplate: null,
        collection: collection['id']
    };

    const [targetOptions, setTargetOptions] = useState([]);
    const [templateRequirements, setTemplateRequirements] = useState([]);
    const [editedCell, setEditedCell] = useState(null);
    const [templateRequirementExists, setTemplateRequirementExists] = useState(false);
    const [targetTemplateAccessPaths, setTargetTemplateAccessPaths] = useState([]);

    const [newTemplateRequirement, setNewTemplateRequirement] = useState(initialNewTemplateRequirementValue);

    const targetOptionsForCurrentTemplateRequirement = useMemo(() => {
        return getTargetOptionsForCurrentTemplateRequirement(targetOptions, editedCell?.targetTemplate, templateRequirements);
    }, [templateRequirements, editedCell?.id, targetOptions]);

    useEffect(() => {
        const checkExistTemplateRequirementWithCurrentTemplate = async () => {
            const url = `auto-content/template-requirements/?templateID=${currentTemplate['id']}`;

            try {
                const res = await getDataFromAPI(url);

                if (res.data.result) {
                    const listRequirementsWithThisTemplate = res.data['data'];
                    const requirementOfSelectedCollection = listRequirementsWithThisTemplate.filter((requirement) => listMainTemplates.some((mainTemplate) => mainTemplate.id === requirement.targetTemplate));
                    if (requirementOfSelectedCollection.length > 0) {
                        setNewTemplateRequirement(requirementOfSelectedCollection[0]);
                        setTemplateRequirementExists(true);
                    };
                };
            } catch(e) {
                console.log(`An error occured when check exist template requirements with the current template: ${e.message}`);
            };
        };

        checkExistTemplateRequirementWithCurrentTemplate();
    }, [currentTemplate]);

    useEffect(() => {
        const fetchTemplateRequirements = async () => {
            const url = `auto-content/template-requirements/?templateID=${currentTemplate['id']}&collectionID=${collection?.id}`;

            try {
                const res = await getDataFromAPI(url);
                const data = res.data;
                setTemplateRequirements(data);
            } catch (e) {
                console.log(`An error occured when check exist template requirements with the current template: ${e.message}`);
            };
        };

        fetchTemplateRequirements();
    }, [currentTemplate]);

    useEffect(() => {
        const initTargetOptions = () => {
            const options = listMainTemplates.map((mainTemplate) => ({
                ...listMainTemplates,
                label: `${mainTemplate.id}. ${mainTemplate.name}`,
                value: mainTemplate.id,
            }));

            setTargetOptions(options);
        };

        initTargetOptions();
    }, [listMainTemplates]);

    useEffect(() => {
        const fetchTargetTemplateAccessPaths = async () => {
            if (newTemplateRequirement?.targetTemplate) {
                let zones = await getZonesOfResource(newTemplateRequirement.targetTemplate);
                let accessPathOptions = zones.map((zone) => ({
                    ...zone,
                    value: zone.accessPath,
                    label: getZoneLabel(zone),
                }));
                if (!newTemplateRequirement.accessPath) {
                    setNewTemplateRequirement((prev) => ({
                        ...prev,
                        accessPath: accessPathOptions.find((option) => option.value === newTemplateRequirement.accessPath)
                    }));
                };
                setTargetTemplateAccessPaths(accessPathOptions);
            };
        };

        fetchTargetTemplateAccessPaths();
    }, [newTemplateRequirement.targetTemplate, newTemplateRequirement.accessPath]);

    const handleClickCell = (field, item) => {
        setEditedCell({
            ...item,
            field: field,
            value: item[field],
        });
    };

    const handleCellChange = (value) => {
        setEditedCell((prevCell) => ({
            ...prevCell,
            value,
        }));
    }

    const handleCreateTemplateRequirement = async () => {
        if (!checkObjectHasNullebaleValue(newTemplateRequirement)) return;

        try {
            const url = 'auto-content/template-requirements/';

            const res = await updateDataAPI('POST', url, newTemplateRequirement);
            setTemplates(templates.map(t => {
                if (t.id === currentTemplate.id) {
                    return { ...t, requirements: t.requirements.concat([res.data]) };
                }
                return t;
            }))
            setTemplateRequirements((prev) => ([...prev, res.data]));
            setNewTemplateRequirement(initialNewTemplateRequirementValue);
            hideModal();
            toast.success('Successfully created.')
        } catch (e) {
            console.log(`An error occured when creating template requirement: ${e.message}`);
            toast.error(`An error occured when creating template requirement: ${e.message}`)
        }
    }

    const hanldeUpdateTemplateRequirement = async () => {
        if (editedCell.accessPath === '' | editedCell.targetTemplate === null) return;

        try {
            const url = `auto-content/template-requirements/${editedCell['id']}/`;

            const res = await updateDataAPI('PATCH', url, {
                [editedCell['field']]: editedCell['value'],
                collection: collection['id']
            });
            setTemplateRequirements((prev) => prev.map((item) => {
                if (item.id === editedCell['id']) {
                    return {
                        ...item,
                        [editedCell['field']]: editedCell['value']
                    }
                };

                return item;
            }));
            toast.success('Successfully updated.');
            setEditedCell(null);
        } catch (e) {
            console.log(`An error occured when updating template requirement: ${e.message}`);
            toast.error(`An error occured when updating template requirement: ${e.message}`)
        };
    };

    const handleDeleteTemplateRequirement = async (templateRequirement) => {
        const confirmed = window.confirm('Are you sure want to delete this requirement?');

        if (confirmed) {
            try {
                const url = `auto-content/template-requirements/${templateRequirement['id']}/`;

                const res = await updateDataAPI('DELETE', url);
                setTemplateRequirements((prev) => prev.filter((item) => item.id !== templateRequirement['id']));
                toast.success('Successfully deleted.');
            } catch (e) {
                console.log(`An error occured when deleting template requirement: ${e.message}`);
                toast.error(`An error occured when deleting template requirement: ${e.message}`)
            };
        };
    }

    return (
        <Modal show={true} onHide={hideModal} size="md"
            className="template-requirements-setting-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Template Requirements
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Target Template</th>
                            <th>Access path</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templateRequirements.map((templateRequirement) => (
                            <tr key={templateRequirement.id}>
                                {canEditTemplateRequirementFields.map(({ id, field, type }) => (
                                    <Fragment key={id}>
                                        {editedCell && editedCell.id === templateRequirement.id && editedCell.field === field ? (
                                            <td>
                                                {editedCell.field === 'targetTemplate' && (
                                                    <Select
                                                        className="target-templates"
                                                        classNamePrefix="select"
                                                        defaultValue={targetOptions[0]}
                                                        isSearchable={true}
                                                        name="target-templates"
                                                        options={targetOptionsForCurrentTemplateRequirement}
                                                        value={targetOptions.find(option => option.value === editedCell.value)}
                                                        onChange={(selectedOption) => handleCellChange(selectedOption['value'])}
                                                    />
                                                )}
                                                {editedCell.field === 'accessPath' && (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter access path"
                                                        value={editedCell.value}
                                                        onChange={(e) => handleCellChange(e.target.value)}
                                                    />
                                                )}
                                            </td>
                                        ) : (
                                            <td onClick={() => handleClickCell(field, templateRequirement)}>{templateRequirement[field]}</td>
                                        )}
                                    </Fragment>
                                ))}
                                <td>
                                    {(editedCell && editedCell.id === templateRequirement.id) && (
                                        <>
                                            <Button
                                                variant="success"
                                                size="sm"
                                                onClick={() => hanldeUpdateTemplateRequirement()}
                                                className="me-2"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => setEditedCell(null)}
                                                className="me-2"
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                    <Button variant="danger" size="sm" onClick={() => handleDeleteTemplateRequirement(templateRequirement)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <h2>New Requirement</h2>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Template</Form.Label>
                                <Select
                                    className="target-templates"
                                    classNamePrefix="select"
                                    defaultValue={targetOptions[0]}
                                    isSearchable={true}
                                    name="target-templates"
                                    options={targetOptions}
                                    value={targetOptions.find(option => option.value === newTemplateRequirement['targetTemplate'])}
                                    onChange={(selectedOption) => setNewTemplateRequirement((prev) => ({
                                        ...prev,
                                        targetTemplate: selectedOption['value']
                                    }))}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Field</Form.Label>
                                <Select
                                    className="target-template-access-paths"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="target-template-access-paths"
                                    options={targetTemplateAccessPaths}
                                    value={targetTemplateAccessPaths.find(option => option.accessPath === newTemplateRequirement['accessPath'])}
                                    onChange={(selectedOption) => setNewTemplateRequirement((prev) => ({
                                        ...prev,
                                        accessPath: selectedOption['value']
                                    }))}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={hideModal}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => handleCreateTemplateRequirement()}
                >
                    Add requirement
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export default TemplateRequirementsSettingModal;