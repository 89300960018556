import { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TemplateList from '../template-list';
import AddTemplateToCollection from '../add-template-to-collection';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

const TemplatesInCollectionModal = ({ collection, hideModal }) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async() => {
        setLoading(true);
        const res = await getDataFromAPI('auto-content/get-all-templates-in-collection/?collectionID=' + collection.id);
        setTemplates(res.data.sort((a, b) => {
            if (a.collectionMainTemplate) {
                return -1;
            } else if (b.collectionMainTemplate) {
                return 1;
            }
            return a.positionIndex - b.positionIndex;
        }));
        setLoading(false);
    }

    const handleRemoveTemplateFromCollection = async (template) => {
        const confirmRemove = window.confirm('Are you sure you want to remove this template from this collection?');
        if (confirmRemove && template.hasOwnProperty('templateCollectionID')) {
            setLoading(true);
            try {
                const url = `auto-content/template-collections/${template['templateCollectionID']}/`;

                await updateDataAPI('DELETE', url);
                const updatedData = [...templates].filter(t => t.id !== template['id']);

                await updateTemplatePositionInCollection(updatedData);

                toast.success('Remove template from collection successfully.');
            } catch (e) {
                console.log(`An error occured when removing the template from collection ${e.message}`);
                toast.error(`An error occured when removing the template from collection ${e.message}`)
            };
            setLoading(false);
        };
    };


    const updateTemplatePositionInCollection = async (templatesData = []) => {
        setLoading(true);
        const newOrderTemplates = templatesData.map((item, idx) => {
            item.positionIndex = idx;
            return item;
        });

        setTemplates(newOrderTemplates);

        newOrderTemplates.forEach(async(object, index) => {
            try {
                const url = `auto-content/template-collections/${object['templateCollectionID']}/`;
                await updateDataAPI('PATCH', url, {
                    positionIndex: index,
                    collectionMainTemplate: object.collectionMainTemplate,
                });
            } catch(e) {
                console.log('An error occured when updating templateCollection ', object['templateCollectionID'], e.message);
            };
        });
        setLoading(false);
    };

    return (
        <Fragment>
            <Modal show={true} onHide={hideModal} size="xl"
                className="templates-in-collection-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {collection.id}. {collection.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddTemplateToCollection
                        collection={collection}
                        templates={templates}
                        updateTemplatePositionInCollection={updateTemplatePositionInCollection}
                    />
                    <TemplateList
                        templates={templates}
                        setTemplates={setTemplates}
                        showTemplateActions={true}
                        removeTemplateFromCollection={handleRemoveTemplateFromCollection}
                        updateTemplatePositionInCollection={updateTemplatePositionInCollection}
                        mode="listInCollectionModal"
                        collection={collection}
                    />
                </Modal.Body>
            </Modal>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Fragment>
    );
}

export default TemplatesInCollectionModal;