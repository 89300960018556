import { faClose, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import Constant from '../../../../Constant';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../../utils/query';
import ThumbnailPreviewItem from './thumbnail-preview-item';

import './styles.scss';

const ThumbnailsPreviewsTab = ({
    customID,
    storeID,
    freePages,
    thumbnail,
    setThumbnail,
    thumbnailFree,
    setThumbnailFree,
    thumbnailTitle,
    setThumbnailTitle,
    thumbnailSubTitle,
    setThumbnailSubTitle,
    thumbnailSubTitle2,
    setThumbnailSubTitle2,
    thumbnailSubTitle3,
    setThumbnailSubTitle3,
    thumbnailSubTitle4,
    setThumbnailSubTitle4,
    thumbnailSubTitle5,
    setThumbnailSubTitle5,
    highlightImages,
    previewImage1,
    previewImage2,
    previewImage3,
    previewImageFree1,
    previewImageFree2,
    previewImageFree3,
    setPreviewImage1,
    setPreviewImage2,
    setPreviewImage3,
    setPreviewImageFree1,
    setPreviewImageFree2,
    setPreviewImageFree3,
    setLoading,
    tags, }) => {

    const [freePageImages, setFreePageImages] = useState([]);
    const [ideaThumbnailPreviewPages, setIdeaThumbnailPreviewPages] = useState([]);

    useEffect(() => {
        fetchIdeaThumbnailPages();
    }, []);

    useEffect(() => {
        if (freePages) {
            fetchFreePageImages();
        }
    }, [freePages]);

    const fetchIdeaThumbnailPages = async () => {
        const res = await getDataFromAPI('resources/idea-thumbnails/?customID=' + customID);
        setIdeaThumbnailPreviewPages(res.data);
    }

    const fetchFreePageImages = async () => {
        const res = await getDataFromAPI('resources/idea-pages/?isFreePage=true&customID=' + customID);
        setFreePageImages(res.data.filter(page => page.content && page.content.imageUrl).map(page => page.content.imageUrl));
    }

    const uploadAsset = async (event, val) => {
        setLoading(true);
        const data = new FormData();
        let field;
        if (val === 'thumbnail' || val === 'thumbnailFree') {
            field = val;
        } else {
            field = 'preview';
        }
        data.append('images', event.target.files[0]);
        const res = await axios.post(Constant.API_URL + 'resources/upload-product-idea-asset/?field=' + field, data, {
            headers: {
                Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN),
                'Content-Type': 'multipart/form-data',
            }
        });
        if (val === 'thumbnail') {
            setThumbnail(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'thumbnailFree') {
            setThumbnailFree(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'previewImage1') {
            setPreviewImage1(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'previewImage2') {
            setPreviewImage2(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'previewImage3') {
            setPreviewImage3(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'previewImageFree1') {
            setPreviewImageFree1(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'previewImageFree2') {
            setPreviewImageFree2(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        } else if (val === 'previewImageFree3') {
            setPreviewImageFree3(Constant.API_URL + 'images/' + field + 's/' + res.data[0]);
        }
        setLoading(false);
    }

    return (
        <div className="thumbnails-previews-tab">
            <Row className="mt-3">
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail Title</Form.Label>
                        <Form.Control value={thumbnailTitle} onChange={e => setThumbnailTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle</Form.Label>
                        <Form.Control value={thumbnailSubTitle} onChange={e => setThumbnailSubTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle 2 (only in PAID product)</Form.Label>
                        <Form.Control value={thumbnailSubTitle2} onChange={e => setThumbnailSubTitle2(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle 3 (only in PAID product)</Form.Label>
                        <Form.Control value={thumbnailSubTitle3} onChange={e => setThumbnailSubTitle3(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle 4 (only in PAID product)</Form.Label>
                        <Form.Control value={thumbnailSubTitle4} onChange={e => setThumbnailSubTitle4(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle 5 (only in PAID product)</Form.Label>
                        <Form.Control value={thumbnailSubTitle5} onChange={e => setThumbnailSubTitle5(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col md={8}>
                    <Row>
                        <Alert variant="warning">
                            Remember to <u>remove the number of pages</u> of the PAID product in the Free Product
                        </Alert>
                    </Row>
                    <h2>PAID Product</h2>
                    <Row className="mb-3">
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={thumbnail}
                                itemName="Thumbnail Paid"
                                itemType="paid"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'paid')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={previewImage1}
                                itemName="Preview 1"
                                itemType="preview1"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'preview1')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={previewImage2}
                                itemName="Preview 2"
                                itemType="preview2"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'preview2')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={previewImage3}
                                itemName="Preview 3"
                                itemType="preview3"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'preview3')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                    </Row>
                    <h2>Free Product</h2>
                    <Row>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={thumbnailFree}
                                itemName="Thumbnail Free"
                                itemType="free"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'free')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={previewImageFree1}
                                itemName="Preview 1"
                                itemType="previewFree1"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'previewFree1')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={previewImageFree2}
                                itemName="Preview 2"
                                itemType="previewFree2"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'previewFree2')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                        <Col>
                            <ThumbnailPreviewItem
                                storeID={storeID}
                                customID={customID}
                                item={previewImageFree3}
                                itemName="Preview 3"
                                itemType="previewFree3"
                                itemPage={ideaThumbnailPreviewPages.find(page => page.type === 'previewFree3')}
                                uploadAsset={uploadAsset}
                                hasColorVersion={tags && (tags.includes(Constant.TASKCARD_TAG) || tags.includes(Constant.COLOR_BY_CODE_TAG))}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <h3>Hightlight Images</h3>
                {highlightImages ? (
                    <div className="highlight-images">
                        {highlightImages.map((image, index) => (
                            <img src={image} key={index} alt="highlight" />
                        ))}
                    </div>
                ) : (
                    <>
                        <div className="upload-asset-container">
                            <div className="asset-placeholder" onClick={() => document.getElementById('upload-preview1-input').click()}>
                                {previewImage1 ? (
                                    <>
                                        <img src={previewImage1} alt="preview 1" />
                                        <FontAwesomeIcon icon={faClose} className="close-icon" />
                                    </>
                                ) : (
                                    <FontAwesomeIcon icon={faImage} className="img-icon" />
                                )}
                            </div>
                            <div>Preview Image 1</div>
                            <Form.Control id="upload-preview1-input" type="file" onChange={e => uploadAsset(e, 'previewImage1')} />
                        </div>
                        <div className="upload-asset-container">
                            <div className="asset-placeholder" onClick={() => document.getElementById('upload-preview2-input').click()}>
                                {previewImage2 ? (
                                    <>
                                        <img src={previewImage2} alt="preview 2" />
                                        <FontAwesomeIcon icon={faClose} className="close-icon" />
                                    </>
                                ) : (
                                    <FontAwesomeIcon icon={faImage} className="img-icon" />
                                )}
                            </div>
                            <div>Preview Image 2</div>
                            <Form.Control id="upload-preview2-input" type="file" onChange={e => uploadAsset(e, 'previewImage2')} />
                        </div>
                        <div className="upload-asset-container">
                            <div className="asset-placeholder" onClick={() => document.getElementById('upload-preview3-input').click()}>
                                {previewImage3 ? (
                                    <>
                                        <img src={previewImage3} alt="preview 3" />
                                        <FontAwesomeIcon icon={faClose} className="close-icon" />
                                    </>
                                ) : (
                                    <FontAwesomeIcon icon={faImage} className="img-icon" />
                                )}
                            </div>
                            <div>Preview Image 3</div>
                            <Form.Control id="upload-preview3-input" type="file" onChange={e => uploadAsset(e, 'previewImage3')} />
                        </div>
                    </>
                )}
            </Row>
            <Row>
                <h3>Free Pages</h3>
                <Form.Group className="mb-3">
                    <div className="free-images">
                        {freePageImages.map((image, index) => (
                            <div>
                                <img key={index} src={image} alt="free image page" />
                                {freePages && freePages.split(',').length > 0 && freePages.split(',')[index] && (
                                    <div><strong>Page {freePages.split(',')[index]}</strong></div>
                                )}
                            </div>
                        ))}
                    </div>
                </Form.Group>
            </Row>
        </div>
    )
}

export default ThumbnailsPreviewsTab;