import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';

import './styles.scss';
import ImageWrapper from '../../../../components/common/image-wrapper';

const AnswerKeySheetTab = ({ customID, setLoading }) => {
    const [answerKeys, setAnswerKeys] = useState([]);
    const [answerSheets, setAnswerSheets] = useState([]);

    useEffect(() => {
        fetchAnswerKeyPages();
        fetchAnswerSheetPages();
    }, []);

    const fetchAnswerKeyPages = async() => {
        const res = await getDataFromAPI('auto-content/idea-answer-key-pages/?customID=' + customID);
        setAnswerKeys(res.data);
    }

    const fetchAnswerSheetPages = async() => {
        const res = await getDataFromAPI('auto-content/idea-answer-sheet-pages/?customID=' + customID);
        setAnswerSheets(res.data);
    }

    const createAnswerKeyPages = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'auto-content/create-idea-answer-key-pages/?customID=' + customID);
            setAnswerKeys(res.data);
        } catch (err) {
            window.alert('Check if template using in this idea has answer sheet templates');
        }
        setLoading(false);
    }

    const createAnswerSheetPages = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'auto-content/create-idea-answer-sheet-pages/?customID=' + customID);
            setAnswerSheets(res.data);
        } catch (err) {
            window.alert('Check if template using in this idea has answer sheet templates');
        }
        setLoading(false);
    }

    const deleteAnswerKeyImage = async(id) => {
        await updateDataAPI('PATCH', 'auto-content/idea-answer-key-pages/' + id + '/', {imageUrl: null});
        setAnswerKeys(answerKeys.map(answerKey => {
            if (answerKey.id === id) {
                return {...answerKey, imageUrl: null};
            }
            return answerKey;
        }))
    }

    const deleteAnswerSheetImage = async(id) => {
        await updateDataAPI('PATCH', 'auto-content/idea-answer-sheetey-pages/' + id + '/', {imageUrl: null});
        setAnswerSheets(answerSheets.map(answerSheet => {
            if (answerSheet.id === id) {
                return {...answerSheet, imageUrl: null};
            }
            return answerSheet;
        }))
    }

    return (
        <div className="answer-key-sheet-tab">
            <Row>
                <Col>
                    <h2>Answer Key</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Page</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {answerKeys.map((answerKey, index) => (
                                <tr key={index}>
                                    <td>{answerKey.pageIndex + 1}</td>
                                    <td>
                                        <ImageWrapper src={answerKey.imageUrl} />
                                    </td>
                                    <td className="actions-cell">
                                        <a target="_blank" href={process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=answer_key&autoSave&id=' + answerKey.id}>Edit</a>
                                        <Button size="sm" variant="danger" onClick={() => deleteAnswerKeyImage(answerKey.id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                    <Button size="sm" onClick={createAnswerKeyPages}>Re/Create Answer Keys</Button>
                </Col>
                <Col>
                    <h2>Answer Sheet</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Page</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {answerSheets.map((answerSheet, index) => (
                                <tr key={index}>
                                    <td>{answerSheet.pageIndex + 1}</td>
                                    <td>
                                        <img src={answerSheet.imageUrl} />
                                    </td>
                                    <td className="actions-cell">
                                        <a target="_blank" href={process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=answer_sheet&autoSave&id=' + answerSheet.id}>Edit</a>
                                        <Button size="sm" variant="danger" onClick={() => deleteAnswerSheetImage(answerSheet.id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                    <Button size="sm" onClick={createAnswerSheetPages}>Re/Create Answer Sheets</Button>
                </Col>
            </Row>
        </div>
    )
}

export default AnswerKeySheetTab;