import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import UploadTopicImageModal from '../upload-topic-image-modal';
import { updateDataAPI } from '../../../utils/query';
import Select from 'react-select';
import Image from 'react-bootstrap/Image';
import IconsList from '../../icons-list';

export const TopicIconOption = ({ option }) => {
    const iconUrl = option?.colorUrl ?? option?.colorThumbnailUrl ?? option?.bwUrl ?? option?.bwThumbnailUrl;

    return (
        <div className="d-flex flex-column align-items-center">
            {iconUrl && (
                <Image src={option.colorUrl} alt={option.name} thumbnail width={100} />
            )}
            <span>{option.id}. {option?.name}</span>
        </div>
    )
}

export const generateTopicIconOptions = (input = []) => {
    return input.map((icon) => ({
        ...icon,
        label: icon.id + '. ' + icon.name,
        value: icon.id,
    }));
}

const UploadNewTopicImage = ({
    topic,
    topicIcon,
    topicName,
    handleUploadedTopic = () => { },
    handleChangeIconOption = () => { },
    type = 'topics'
}) => {
    const [showUploadTopicImageModal, setShowUploadTopicImageModal] = useState(false);
    const [topicIconOptions, setTopicIconOptions] = useState([]);
    const [selectedTopicIconOption, setSelectedTopicIconOption] = useState('');

    useEffect(() => {
        const initTopicIconOptions = async () => {
            setSelectedTopicIconOption('');
            let topicData = topic;
            if (!topicData && topicName) {
                const url = 'resources/fetch-topics-by-name/';
                try {
                    const res = await updateDataAPI('POST', url, {
                        topicNames: [topicName]
                    })

                    if (res.data && res.data.length > 0) {
                        topicData = res.data[0];
                    }
                } catch (err) {
                    console.error(`An error occured when fetching topic options: ${err.message}`);
                };
            }
            if (topicData) {
                const options = generateTopicIconOptions(topicData.iconDatas)
                setTopicIconOptions(options);
                if (topicIcon) {
                    const defaultOptionValue = options.find((option) => option.id === topicIcon);
                    setSelectedTopicIconOption(defaultOptionValue)
                }
            }
        };

        initTopicIconOptions();
    }, [topicName, topicIcon]);

    const deleteTopicIcon = async (topicIconID) => {
        const confirmed = window.confirm('Are you sure want to remove this icon from topic?');
        if (confirmed) {
            try {
                const res = await updateDataAPI('POST', 'resources/delete-topic-icon/', { topicName, topicIcon: topicIconID });
                handleUploadedTopic(res.data);
                const iconDatas = res.data?.iconDatas || [];
                const iconOptions = generateTopicIconOptions(iconDatas);
                setTopicIconOptions(iconOptions);
                setSelectedTopicIconOption('');
            } catch (err) {
                console.log(err.message)
                window.alert('Can not delete topic image.');
            }
        }
    }

    const handleChangeTopicIcon = async (updatedValue) => {
        setSelectedTopicIconOption(updatedValue);
        handleChangeIconOption(updatedValue?.id);
    }

    const handleUpdateSelectTopicIconOption = (iconDatas = []) => {
        const updatedOption = iconDatas.reduce((max, obj) => (obj.id > max.id ? obj : max));

        setSelectedTopicIconOption(updatedOption);
        handleChangeIconOption(updatedOption?.id);
    }

    return (
        <div className="d-flex flex-column gap-1">
            {type !== 'topics' ? (
                <Select
                    isClearable
                    value={selectedTopicIconOption}
                    onChange={handleChangeTopicIcon}
                    options={topicIconOptions}
                    formatOptionLabel={(option) => (<TopicIconOption option={option} />)}
                />
            ) : (
                <IconsList
                    icons={topicIconOptions}
                    removeIcon={deleteTopicIcon}
                />
            )}

            <div className="d-flex justify-content-center">
                <Button variant="primary" onClick={() => setShowUploadTopicImageModal(true)} size="sm">
                    Upload New Icon
                </Button>
            </div>

            {showUploadTopicImageModal && (
                <UploadTopicImageModal
                    topicName={topicName}
                    hideModal={() => setShowUploadTopicImageModal(false)}
                    handleUploadedTopic={(value) => {
                        const iconDatas = value?.iconDatas || [];
                        const iconOptions = generateTopicIconOptions(iconDatas);
                        handleUploadedTopic(value);
                        setTopicIconOptions(iconOptions);
                        handleUpdateSelectTopicIconOption(iconDatas);
                    }}
                />
            )}
        </div>
    )
}

export default UploadNewTopicImage;