import { useState } from 'react';
import { Button, Form, ListGroup, OverlayTrigger } from 'react-bootstrap';
import TemplateSearchModal from '../../auto-content/template-search-modal';
import { updateDataAPI } from '../../../utils/query';
import { onlyUnique } from '../../../utils/commonUtil';

import './styles.scss';
import QuickAddNewPagesModal from '../quick-add-new-pages-modal';

const AddNewPageButton = ({ idea, pages, setPages, refresh, setLoading, placement }) => {
    const [showNewAutoIdeaPageModal, setShowNewAutoIdeaPageModal] = useState(false);
    const [showQuickAddNewPagesModal, setShowQuickAddNewPagesModal] = useState(false);
    const [nbNewManualPages, setNbNewManualPages] = useState(1);

    const addNewManualPage = async (e) => {
        e.preventDefault();
        if (isNaN(nbNewManualPages)) {
            window.alert('please enter a number > 0');
            return;
        }
        setLoading(true);
        const newPages = [];
        let newPageIndex = pages[pages.length - 1] ? (pages[pages.length - 1].pageIndex + 1) : 0;
        for (let i = 0; i < nbNewManualPages; i++) {
            const resPage = await updateDataAPI('POST', 'resources/idea-pages/', {
                idea: idea.id,
                pageIndex: newPageIndex,
                type: 'manual',
            });
            const resDW = await updateDataAPI('POST', 'product-dev/design-worksheets/', {
                productIdea: idea.id,
                ideaPage: resPage.data['id'],
            });
            newPages.push({...resPage.data, content: resDW.data});
            newPageIndex++;
        }
        setPages([...pages, ...newPages]);
        setLoading(false);
    }

    const refreshPages = async (action) => {
        if (action === 'add') {
            await refresh(['pages', 'zones']);
        }
        setShowNewAutoIdeaPageModal(false);
        setShowQuickAddNewPagesModal(false);
    }

    return (
        <div className="add-new-page-btn">

            <OverlayTrigger trigger="click" placement={placement}
                overlay={
                    <ListGroup className="add-new-page-actions">
                        <ListGroup.Item onClick={() => setShowNewAutoIdeaPageModal(true)}>
                            <span>Auto</span>
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => setShowQuickAddNewPagesModal(true)}>
                            <span>Quick</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form className="add-manual-page-form">
                                <Form.Label>Manual</Form.Label>
                                <Form.Control value={nbNewManualPages} type="number" onChange={e => setNbNewManualPages(e.target.value)} />
                            </Form>
                            <Button size="sm" onClick={addNewManualPage}>Add</Button>
                        </ListGroup.Item>
                    </ListGroup>
                }>
                <Button variant="success">
                    Add new Page
                </Button>
            </OverlayTrigger >

            {showNewAutoIdeaPageModal && (
                <TemplateSearchModal
                    idea={idea}
                    newPageIndex={pages[pages.length - 1] ? (pages[pages.length - 1].pageIndex + 1) : 0}
                    usedTemplateIDs={pages.filter(page => page.type === 'auto').map(p => p.content.template).filter(onlyUnique)}
                    hideModal={action => refreshPages(action)} />
            )}
            {showQuickAddNewPagesModal && (
                <QuickAddNewPagesModal
                    idea={idea}
                    startPageIndex={pages[pages.length - 1] ? (pages[pages.length - 1].pageIndex + 1) : 0}
                    hideModal={action => refreshPages(action)}
                />
            )}
        </div >
    )
}

export default AddNewPageButton;