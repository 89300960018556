import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateIdeaTemplateContent } from '../../autoContentSlice';
import { updateDataAPI } from '../../../../utils/query';
import './styles.scss';

const AnswerKeyResult = ({ideaTemplate, answerKey, refreshPage, setLoading}) => {
    const dispatch = useDispatch();

    const save = async () => {
        await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
            answerKey
        });
        window.alert('Answer Key saved successfully');
    }

    const fix = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'auto-content/fix-ai-response/?ideaTemplateID=' + ideaTemplate.id + '&field=answerKey');
        refreshPage();
        dispatch(updateIdeaTemplateContent({
            ideaTemplateID: ideaTemplate.id,
            field: 'answerKey',
            value: res.data['content']['answerKey']
        }));
        setLoading(false);
    }

    return (
        <div className="mt-3 worksheet-answer-key">
            <h3>Answer Key</h3>
            <Form.Control
                as="textarea" rows={7}
                value={answerKey} onChange={e => dispatch(updateIdeaTemplateContent({
                    ideaTemplateID: ideaTemplate.id,
                    field: 'answerKey',
                    value: e.target.value
                }))} />

            <div className="btns">
                <Button size="sm" variant="success" onClick={save}>Save</Button>
                <Button size="sm" onClick={fix}>Fix</Button>
            </div>
        </div>
    );
}

export default AnswerKeyResult;