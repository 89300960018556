import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import FilterByCategory from '../../../../filters/category';
import MultiValues from '../../../../forms/multi-values';
import SelectKeywords from '../../select-keywords';
import ReactQuill from 'react-quill';
import { hasPermission } from '../../../../utils/auth';
import { useSelector } from 'react-redux';
import AsyncSelectComponent from '../../../../utils/async-select';

const convertToSelectOptions = (arr = []) => {
    if (arr.length === 0) return [];
    return arr.map((item, index) => ({ id: index + 1, value: item, label: item }));
}

const IdeaModalInformationsTab = ({
    stores,
    idea,
    userRoles,
    loading,
    setLoading,
    store,
    setStore,
    price,
    setPrice,
    category,
    setCategory,
    name,
    setName,
    subjects,
    setSubjects,
    productMapSkill,
    setProductMapSkill,
    productMapSkillLabel,
    setProductMapSkillLabel,
    tags,
    setTags,
    grades,
    setGrades,
    standards,
    setStandards,
    estimatedNbPages,
    setEstimatedNbPages,
    keywords,
    setKeywords,
    note,
    setNote,
    noteEditorActive,
    setNoteEditorActive,
}) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    return (
        <div className="idea-modal-informations-tab">
            <Row className="mt-3">
                <Col>
                    <Form.Group className="mb-3" controlId="productIdeaCategory">
                        <Form.Label><strong>Flow*</strong></Form.Label>
                        <FilterByCategory filtering={false} value={category} setValue={setCategory} hasAllOptions={false} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Store*</strong></Form.Label>
                        <Form.Select onChange={e => setStore(e.target.value)} value={store}>
                            <option value={null}></option>
                            {stores.map(s => (
                                <option value={s.id} key={s.id}>{s.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Name*</strong></Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Subjects (Chọn 3 subjects)</Form.Label>
                        <MultiValues value={subjects} setValue={val => setSubjects(val)} choicesDataAPI="resources/subjects/"
                            labelField="name" valueField="name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="productIdeaTags">
                        <Form.Label>Tags</Form.Label>
                        <MultiValues value={tags} setValue={val => setTags(val)} choicesDataAPI="resources/tags/?type=product"
                            labelField="name" valueField="id" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="productIdeaGrades">
                        <Form.Label>Grades</Form.Label>
                        <MultiValues value={grades} setValue={val => setGrades(val)} defaultChoices={[
                            'PreK',
                            'Kindergarten', 'Homeschool', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
                        ]} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="productIdeaEvents">
                        <Form.Label>Product Map</Form.Label>
                        <AsyncSelectComponent
                            searchUrl="resources/product-map-skills/?keyword="
                            onChange={selectedValue => {
                                setProductMapSkill(selectedValue.value);
                                setProductMapSkillLabel(selectedValue.label);
                            }}
                            value={{value: productMapSkill, label: productMapSkillLabel}}
                            setLoading={setLoading}
                            loading={loading}
                            clearSelection={() => setProductMapSkill(null)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Standards</Form.Label>
                        <MultiValues value={standards} setValue={val => setStandards(val)} choicesDataAPI="standards/"
                            labelField="code" valueField="code" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Estimated Number Pages</Form.Label>
                        <Form.Control value={estimatedNbPages} onChange={e => setEstimatedNbPages(e.target.value)} />
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Keywords</Form.Label>
                        <SelectKeywords
                            selectedKeywords={convertToSelectOptions(keywords ? keywords.split(',') : [])}
                            setSelectedKeywords={(options) => setKeywords(options.map(({ value }) => value).join(','))}
                        />
                    </Form.Group>
                </Col>
                {(hasPermission(userRoles, activityPermissions['VIEW_PRICE'])) && (
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Based Price
                                {idea?.nbPages && (
                                    <span> ({idea.nbPages} pages)</span>
                                )}
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control value={price} onChange={e => setPrice(e.target.value)} />
                            </InputGroup>
                            {price !== null && stores.find(s => s.id === store)?.sale && (
                                <div className="prices-after-discount">
                                    <Form.Text>Giá sau sale: {(price * (100 - stores.find(s => s.id === store)?.sale) / 100).toFixed(2)}$</Form.Text>
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mt-3">
                        <Form.Label className="note-label">
                            <span>Note</span>
                            <span onClick={() => setNoteEditorActive(true)}>edit</span>
                        </Form.Label>
                        {noteEditorActive ? (
                            <ReactQuill theme="snow" value={note} onChange={setNote} />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: note }} className="note-editor-view"></div>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default IdeaModalInformationsTab;