import { useEffect, useState } from 'react';
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';

import './styles.scss';
import { toast } from 'react-toastify';

const NonAIGenerateModal = ({ idea, ideaTemplateIDs, ideaSkill, refresh, hideModal, setLoading, target = 'generate', handleFunctionArgs, showTestResult = null }) => {
    const [functions, setFunctions] = useState([]);
    const [args, setArgs] = useState([]);
    const [selectedFunctionID, setSelectedFunctionID] = useState(ideaSkill?.nonAIFunction);
    const [selectedArgs, setSelectedArgs] = useState({});

    useEffect(() => {
        const generateInitialSelectedOption = async () => {
            const nonAIFunctions = await fetchFunctions();
            setFunctions(nonAIFunctions);
            if (target === 'link' && ideaSkill?.nonAIFunction) {
                const selectedFunction = setArgsOfNonAIFunction(ideaSkill?.nonAIFunction, nonAIFunctions);
                if (selectedFunction) {
                    setSelectedFunctionID(ideaSkill?.nonAIFunction);
                    setSelectedArgs(ideaSkill?.nonAIFunctionArgs);
                }
            }
        };

        generateInitialSelectedOption();
    }, [ideaSkill]);

    const fetchFunctions = async () => {
        const res = await getDataFromAPI('auto-content/non-ai-functions/');
        return res.data;
    }

    const setArgsOfNonAIFunction = (selectedNonAIFunctionID, nonAIFunctions = functions) => {
        const selectedFunction = nonAIFunctions.find(func => func.id === parseInt(selectedNonAIFunctionID));
        if (selectedFunction) {
            setArgs(selectedFunction.arguments);
        }
        return selectedFunction;
    };

    const generate = async () => {
        setLoading(true);
        console.log(selectedFunctionID, selectedArgs);
        for (let i = 0; i < ideaTemplateIDs.length; i++) {
            await updateDataAPI('POST', 'auto-content/reset-zones-and-linked-text-items-in-page/?ideaTemplateID=' + ideaTemplateIDs[i]);
        }
        const res = await updateDataAPI('POST', 'auto-content/generate-ai-response-by-non-ai/', {
            ideaID: idea.id,
            ideaTemplateIDs,
            functionID: selectedFunctionID,
            args: selectedArgs,
        });
        if (showTestResult) {
            showTestResult(res.data['result'][0]);
        }
        refresh(['pages']);
        setLoading(false);
        hideModal();
    }

    const linkIdeaSkillWithNonAIFunction = async () => {
        setLoading(true);
        if (ideaSkill) {
            console.log(selectedFunctionID, selectedArgs);
            try {
                await updateDataAPI('PATCH', `resources/idea-skills/${ideaSkill.id}/`, {
                    nonAIFunction: selectedFunctionID,
                    nonAIFunctionArgs: selectedArgs,
                });
                refresh(['skills']);
                toast.success('Linked successufully.');
            } catch (err) {
                toast.error(`An error occured when linked non ai function to idea skill: ${err.message}`);
            };
        }
        if (handleFunctionArgs) {
            handleFunctionArgs(parseInt(selectedFunctionID), selectedArgs);
        }
        setLoading(false);
        hideModal();
    }

    const handleChangeSelectedNonAIFunction = (value) => {
        setSelectedFunctionID(value);
        if (value) {
            const selectedFunction = setArgsOfNonAIFunction(value, functions);
            if (selectedFunction) {
                let selectedArgs = {};
                if (parseInt(value) === ideaSkill?.nonAIFunction) {
                    selectedArgs = ideaSkill?.nonAIFunctionArgs;
                } else {
                    for (let i = 0; i < selectedFunction.arguments.length; i++) {
                        if (selectedFunction.arguments[i].type === 'choices') {
                            selectedArgs[selectedFunction.arguments[i].name] = selectedFunction.arguments[i].choices[0];
                        }
                    }
                }
                console.log('> selectedArgs', selectedArgs);
                setSelectedArgs(selectedArgs);
            }
        } else {
            setArgs([]);
            setSelectedArgs({});
        }
    }

    return (
        <Modal
            show={true}
            onHide={hideModal} size="lg"
            className="non-ai-generate-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Non-AI Generate
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Label className="mb-3"><strong>Function</strong></Form.Label>
                            <Form.Select
                                onChange={(e) => handleChangeSelectedNonAIFunction(e.target.value)}
                                value={selectedFunctionID}
                            >
                                <option></option>
                                {functions.map((func, index) => (
                                    <option value={func.id} key={index}>{func.id}. {func.name}</option>
                                ))}
                            </Form.Select>
                            {selectedFunctionID !== null && (
                                <Form.Text>{functions.find(f => f.id === parseInt(selectedFunctionID))?.description}</Form.Text>
                            )}
                        </Col>
                        <Col>
                            <Form.Label className="mb-3"><strong>Options</strong></Form.Label>
                            {args.map((arg, index) => (
                                <Form.Group id={'function-option-' + index} key={index} className="mb-3">
                                    <Row>
                                        <Col>
                                            <Form.Label>{arg.name}</Form.Label>
                                            <Form.Text>{arg.description}</Form.Text>
                                        </Col>
                                        <Col>
                                            {arg.type === 'choices' && (
                                                <Form.Select
                                                    onChange={(e) => setSelectedArgs({ ...selectedArgs, [arg.name]: e.target.value })}
                                                    value={selectedArgs[arg.name]}
                                                >
                                                    {arg.choices.map((choice, index) => (
                                                        <option value={choice} key={index}>{choice}</option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                            {arg.type === 'input' && (
                                                <Form.Control
                                                    type="text"
                                                    onChange={(e) => setSelectedArgs({ ...selectedArgs, [arg.name]: e.target.value })}
                                                    value={selectedArgs[arg.name]}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            ))}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {target === 'generate' ? (
                    <Button onClick={generate}>Generate</Button>
                ) : (
                    <Button onClick={linkIdeaSkillWithNonAIFunction}>Link</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default NonAIGenerateModal