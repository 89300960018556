import { Form } from 'react-bootstrap';
import AsyncSelectComponent from '../../../utils/async-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './styles.scss';

const OutlineIdeaContentRow = ({ rows, row, rowIndex, setRows, deleteRow, duplicateRow, setLoading, loading, setNonAIFunctionInModal, setNonAIFunctionRowIndex }) => {
    return (
        <tr className="outline-idea-content-row">
            <td className="index-cell">
                <div>{rowIndex + 1}</div>
                <div className="btns">
                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteRow(rowIndex)} />
                    <FontAwesomeIcon icon={faCopy} onClick={() => duplicateRow(rowIndex)} />
                </div>
            </td>
            <td>
                <AsyncSelectComponent
                    searchUrl='resources/topic-groups/?searchKeyword='
                    onChange={selectedValue => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, dataSet: selectedValue.value, dataSetLabel: selectedValue.label};
                        }
                        return r;
                    }))}
                    value={{value: row['dataSet'], label: row['dataSetLabel']}}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return _.omit(r, ['dataSet', 'dataSetLabel']);
                        }
                        return r;
                    }))}
                />
            </td>
            <td>
                <AsyncSelectComponent
                    searchUrl='auto-content/collections/?keyword='
                    onChange={selectedValue => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, collection: selectedValue.value, collectionLabel: selectedValue.label};
                        }
                        return r;
                    }))}
                    value={{value: row['collection'], label: row['collectionLabel']}}
                    setLoading={setLoading}
                    loading={loading}
                    labelField="nameWithNbTemplates"
                    clearSelection={() => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return _.omit(r, ['collection', 'collectionLabel']);
                        }
                        return r;
                    }))}
                />
            </td>
            <td className="function-cell">
                <AsyncSelectComponent
                    searchUrl='auto-content/non-ai-functions/?keyword='
                    onChange={selectedValue => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, function: selectedValue.value, functionLabel: selectedValue.label};
                        }
                        return r;
                    }))}
                    value={{value: row['function'], label: row['functionLabel']}}
                    setLoading={setLoading}
                    loading={loading}
                    clearSelection={() => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return _.omit(r, ['function', 'functionLabel']);
                        }
                        return r;
                    }))}
                />
                {row['function'] && (
                    <FontAwesomeIcon className="config-function-icon" icon={faCog} onClick={() => {
                        setNonAIFunctionInModal(row['function']);
                        setNonAIFunctionRowIndex(rowIndex);
                    }} />
                )}
                {row['functionArgs'] && (
                    <div className="function-args">{JSON.stringify(row['functionArgs'])}</div>
                )}
            </td>
            <td>
                <Form.Control
                    value={row['skills']}
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, skills: e.target.value};
                        }
                        return r;
                    }))} />
            </td>
            <td className="nb-pages-cell">
                <Form.Control
                    value={row['nbPages']}
                    type="number"
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, nbPages: parseInt(e.target.value)};
                        }
                        return r;
                    }))} />
            </td>
            <td>
                <Form.Control
                    value={row['note']}
                    as="textarea"
                    rows={3}
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, note: e.target.value};
                        }
                        return r;
                    }))} />
            </td>
            <td>
                <Form.Select
                    value={row['ready']}
                    onChange={e => setRows(rows.map((r, rIndex) => {
                        if (rIndex === rowIndex) {
                            return {...r, ready: e.target.value};
                        }
                        return r;
                    }))}>
                    <option value=""></option>
                    <option value="yes">Yes</option>
                </Form.Select>
            </td>
            <td>
                {row['done'] && (
                    <div>Done</div>
                )}
            </td>
        </tr>
    );
}

export default OutlineIdeaContentRow;