import { useState, useCallback } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async';

import './styles.scss';

export const IdeaLabel = ({ option }) => (
    <span>{option.customID}. {option.label}</span>
);

export const TemplateLabel = ({ option }) => (
    <span>{option.id}. {option.label}</span>
);

const SettingGenDataTicketModal = ({
    hideModal,
    initialTicket = {}
}) => {
    const [idea, setIdea] = useState(initialTicket?.idea ? { label: initialTicket.idea, value: initialTicket.idea } : '');
    const [loading, setLoading] = useState(false);

    const loadOptions = useCallback((url, callback) => {
        // Use the debounced fetch function
        fetchOptions(url, callback);
    }, []);

    const handleSettingTicket = async () => {
        let url;
        if (initialTicket === true) {
            url = 'product-dev/gen-data-tickets/';
            await updateDataAPI('POST', 'product-dev/gen-data-tickets/', {customID: idea['customID']});
        } else if (initialTicket?.id) {
            url = `product-dev/gen-data-tickets/${initialTicket?.id}/`;
            await updateDataAPI('PATCH', url, {idea: idea['value']});
        }

        hideModal('refresh');
    }

    const fetchOptions = debounce(async (url, callback) => {
        setLoading(true);
        try {
            const res = await getDataFromAPI(url);

            const formattedData = res.data.map((item) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            callback(formattedData);
        } catch (err) {

        }
        setLoading(false);
    }, 300);

    const hanldeIdeaChange = (value) => {
        setIdea(value);
    }

    return (
        <Modal show={true} onHide={hideModal} className="new-gen-data-ticket-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {initialTicket === true ? 'New Gen Data Ticket' : 'Edit Gen Data Ticket'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Idea*</strong>&#40; Search by customID and title &#41;: {idea?.value}</Form.Label>
                        <AsyncSelect
                            value={idea}
                            onChange={hanldeIdeaChange}
                            isLoading={loading}
                            loadOptions={(inputString, callback) => loadOptions(`resources/search-product-ideas/?keyword=${inputString}&filterByTitle=false`, callback)}
                            defaultOptions
                            isClearable
                            isSearchable
                            formatOptionLabel={(option) => (<IdeaLabel key={option.id} option={option} />)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSettingTicket} disabled={idea === null || idea === ''}>{initialTicket === true ? 'Create' : 'Save'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SettingGenDataTicketModal;