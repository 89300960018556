import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import EventListSelect from '../../event-groups/event-list-select';
import { getDataFromAPI } from '../../../utils/query';
import { initOptions } from '../../content-studio/quick-add-new-pages-modal';

const CustomizeTopicsEventsPerTopicGroup = ({
    topicGroup,
    handleChangeTopicGroupField = () => {}
}) => {
    const [selectedTopicGroup, setSelectedTopicGroup] = useState(topicGroup);
    const [topicGroupOptions, setTopicGroupOptions] = useState([]);

    useEffect(() => {
        const fetchTopicGroups = async () => {
            const url = 'resources/get-all-topic-groups/';

            const res = await getDataFromAPI(url);

            setTopicGroupOptions(initOptions(res.data));
        };

        fetchTopicGroups();
    }, []);

    useEffect(() => {
        handleChangeTopicGroupField(selectedTopicGroup);
    }, [selectedTopicGroup])

    return (
        <div>
            <Select
                value={selectedTopicGroup}
                isSearchable
                isClearable
                options={topicGroupOptions}
                onChange={(selectedValue) => {
                    const updatedValue = selectedValue == null ? null : { ...selectedValue, topics: (selectedValue?.topics || []).join('\n')};
                    setSelectedTopicGroup(updatedValue);
                }}
            />
            {selectedTopicGroup && (
                <div>
                    <div className="mt-2">
                        <span>Customize topics:</span>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={selectedTopicGroup?.topics || []}
                            onChange={(e) => setSelectedTopicGroup({ ...selectedTopicGroup, topics: e.target.value })}
                        />
                    </div>
                    <div className="mt-2">
                        <span>Customize events:</span>
                        <EventListSelect
                            topicGroup={selectedTopicGroup}
                            handleUpdateTopicGroupFields={(fieldName, fieldValue) => setSelectedTopicGroup({ ...selectedTopicGroup, [fieldName]: fieldValue })}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default CustomizeTopicsEventsPerTopicGroup;