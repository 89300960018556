import { v4 as uuidv4 } from 'uuid';
import Constant from '../Constant';
import { updateDataAPI, getDataFromAPI } from './query';

export const getFormatedMessage = (item) => {
    let result = {};
    result['_id'] = uuidv4();
    result['createdAt'] = item.timestamp;
    result['user'] = {
        _id: item.staff,
        name: item.staffName
    }
    if (item.type === 'image') {
        result['image'] = item.message;
    } else {
        result['text'] = item.message;
    }
    return result;
}

export const getFormatedChatMessages = (messages) => {
    const { results } = messages;
    const formatedMessages = results.reduce((acumulator, item) => {
        const newMessage = getFormatedMessage(item);
        acumulator.push(newMessage);

        return acumulator;
    }, []);

    return formatedMessages;
}

export const getGroupChatID = (chats = [], task) => {
    const res = chats.filter((chat) => chat.task === task);

    if (res.length > 0) {
        return res[0].chatGroupId
    } else return false;
}

export const getCurrentStaffId = () => {
    const staffId = localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID);

    return staffId;
}

export const getAbsoluteImagePath = (relativeUrl) => {
    const url = Constant.API_URL + relativeUrl;
    return url;
}

export const markAsReadChatGroup = async (chatGroupId) => {
    const staffId = localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID);
    const url = `product-dev/staffs/${staffId}/mark-as-read-chat-group`;

    const data = {
        'chatGroupId': chatGroupId
    };

    await updateDataAPI('POST', url, data);
}

export const createSufffleArray = (input) => {
    let array = [...input];
    for (let i = array.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }
    return array;
}

export const getRandomItemsFromArray = (array, count) => {
    const shuffledArray = createSufffleArray(array);
    return shuffledArray.slice(0, count);
}

export const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
}

export const singularize = word => {
    const endings = {
        ves: 'fe',
        ies: 'y',
        i: 'us',
        zes: 'ze',
        ses: 's',
        es: 'e',
        s: ''
    };
    return word.replace(
        new RegExp(`(${Object.keys(endings).join('|')})$`),
        r => endings[r]
    );
}

export const checkSkillExisted = (skillName, arrSkills) => {
    return arrSkills.some((skill) => skill.name === skillName);
}

export const fetchCategories = async () => {
    const res = await getDataFromAPI('auto-content/categories');
    const categories = res.data;
    categories.sort((a, b) => {
        if (a.posIndex > b.posIndex) {
            return 1;
        } else if (a.posIndex < b.posIndex) {
            return -1;
        }
        return 0;
    });

    return res.data;
}

export async function loadFonts(fontInfo) {
    let allFonts = Array.from(document.fonts);
    if (!allFonts.find((e) => e.family === fontInfo.name)) {

        const font = new FontFace(
            fontInfo.name,
            `url(${fontInfo.url})`
        );
        const style = document.createElement("style");
        style.textContent = `
            @font-face {
                font-family: '${fontInfo.name}';
                src: url(${fontInfo.url});
            }
        `;
        document.head.appendChild(style);

        // wait for font to be loaded
        await font.load();
    }
}

export const checkObjectHasNullebaleValue = (obj) => {
    return Object.values(obj).every((value) => value !== null && value !== undefined && value !== '');
}

export const getSizeDivText = ({
    textAttribute,
    width,
    wordBreak = "break-word",
}) => {
    const id = "ABC9KtJKNTt0FZhjs2E08FFhQ";
    let divElement = document.getElementById(id);
    if (!divElement) {
        divElement = document.createElement("div");
        divElement.className = "canvas-text";
        divElement.id = id;
        document.body.appendChild(divElement);
    }
    divElement.style.width = width + "px";
    divElement.style.height = "fit-content";
    divElement.style.opacity = "0";
    divElement.style.fontSize = textAttribute.fontSize + "px";
    divElement.style.fontFamily = textAttribute.fontFamily;
    divElement.style.fontStyle = textAttribute.isItalic ? "italic" : "normal";
    divElement.style.fontWeight = textAttribute.isBold ? "bold" : null;
    divElement.style.textDecoration = textAttribute.underline
        ? "underline"
        : "none";
    divElement.style.whiteSpace = "pre-line";
    divElement.style.wordBreak = wordBreak;
    divElement.style.lineHeight = textAttribute.lineHeight
        ? textAttribute.lineHeight.toString()
        : null;
    divElement.style.letterSpacing = textAttribute.letterSpacing + "em";
    makeDivText(textAttribute, divElement);
    const divHeight = divElement.offsetHeight;
    const divWidth = divElement.offsetWidth;
    divElement.style.width = null;
    divElement.style.fontSize = null;
    divElement.style.fontFamily = null;
    divElement.style.fontStyle = null;
    divElement.style.fontWeight = null;
    divElement.style.textDecoration = "none";
    divElement.textContent = "";
    divElement.style.lineHeight = null;
    divElement.style.letterSpacing = null;
    divElement.style.lineHeight = null;
    divElement.innerHTML = "";
    while (divElement.firstChild) {
        divElement.removeChild(divElement.firstChild);
    }
    return { width: divWidth, height: divHeight };
};

export const makeDivText = (
    textAttribute,
    node
) => {
    const makeSpan = ({
        subText,
        node,
        key,
        text,
    }) => {
        let spanElement = document.createElement("span");
        spanElement.className = "canvas-text";
        if (subText) {
            const {
                fontColor,
                isBold,
                isItalic,
                isStroke,
                strokeColor,
                strokeWidth,
                underline,
                fontFamily,
                fontSize,
            } = subText;
            spanElement.style.color = fontColor.length ? fontColor : null;
            spanElement.style.fontStyle = isItalic ? "italic" : "normal";
            spanElement.style.fontWeight = isBold ? "bold" : null;
            spanElement.style.textDecoration = underline ? "underline" : null;
            spanElement.style.fontFamily = fontFamily.length
                ? fontFamily
                : null;
            spanElement.style.fontSize = fontSize ? fontSize + "px" : null;
            spanElement.style.webkitTextStrokeWidth = isStroke ? `${strokeWidth}px` : null;
            spanElement.style.webkitTextStrokeColor = isStroke ? `${strokeColor}` : '';
        } else {
            spanElement.style.textDecoration = textAttribute.underline
                ? "underline"
                : null;
        }
        spanElement.textContent = text;
        node.append(spanElement);
    };

    if (node) {
        while (node.firstChild) {
            node.removeChild(node.firstChild);
        }
        let { subTexts } = textAttribute;
        if (subTexts.length) {
            let listText = getListTextStyles(textAttribute);
            listText.forEach((child) => {
                let { subText, text, startOffset } = child;
                makeSpan({
                    key: startOffset,
                    subText: subText,
                    text: text,
                    node,
                });
            });
        } else {
            node.innerHTML = textAttribute.content;
        }
    }
};

export const getListTextStyles = (textAttribute) => {
    let { subTexts, content } = textAttribute;
    let listText = [];
    if (!subTexts.length) {
        listText.push({ startOffset: 0, text: textAttribute.content });
    } else {
        let startOffset = 0;
        subTexts.forEach((element) => {
            let text = content.substring(startOffset, element.startOffset);
            if (text.length) {
                listText.push({ text: text, startOffset });
            }
            listText.push({
                text: content.slice(element.startOffset, element.endOffset),
                subText: element,
                startOffset: element.startOffset,
            });
            startOffset = element.endOffset;
        });
        if (startOffset < content.length) {
            listText.push({ text: content.slice(startOffset), startOffset });
        }
    }
    return listText;
};