import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { Button, Container, Table } from 'react-bootstrap';

import './styles.scss';
import OutlineIdeaContentTable from '../../components/outlines/outline-idea-content-table';
import IdeaModal from '../idea-list/idea-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const OutlinePage = () => {
    const [outline, setOutline] = useState();
    const [showOutlineIdeaModal, setShowOutlineIdeaModal] = useState(false);
    const [outlineIdeaInModal, setOutlineIdeaInModal] = useState();

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        fetchOutline(id);
    }, []);

    const fetchOutline = async (id) => {
        const res = await getDataFromAPI('product-dev/outlines/' + id + '/?additionalFields=ideas');
        setOutline(res.data);
    }

    const fetchOutlineIdeas = async() => {
        const res = await getDataFromAPI('product-dev/outline-ideas/?outlineID=' + outline.id);
        setOutline({...outline, ideas: res.data});
    }

    const deleteOutlineIdea = async(idea) => {
        if (window.confirm('Are you sure you want to delete this idea?')) {
            await updateDataAPI('DELETE', 'product-dev/outline-ideas/' + idea.id + '/');
            if (idea.ideaData) {
                await updateDataAPI('DELETE', 'product-ideas/' + idea.ideaData.id + '/');
            }
            fetchOutlineIdeas();
        }
    }

    const addNewOutlineIdea = async(idea) => {
        const res = await updateDataAPI('POST', 'product-dev/outline-ideas/', {
            outline: outline.id,
            name: idea.name,
            ideaIndex: outline.ideas.length,
            idea: idea.id,
            content: []
        });
        setOutline({...outline, ideas: [...outline.ideas, res.data]});
    }

    if (outline === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <Container className="outline-page" fluid>
            <h1 className="mb-3">{outline.name} - {outline.staffName}</h1>
            <div className="btns">
                <Button onClick={() => setShowOutlineIdeaModal(true)}>New Idea</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Idea</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {outline.ideas.map((idea, index) => (
                        <tr key={index}>
                            <td className="idea-index-cell">
                                <div>{index + 1}</div>
                                <div className="idea-btns">
                                    <Button size="sm" variant="danger" onClick={() => deleteOutlineIdea(idea)}>Delete</Button>
                                </div>
                            </td>
                            <td className="idea-content-cell">
                                {idea.ideaData ? (
                                    <div className="active-idea-name">
                                        <span onClick={() => {
                                            setOutlineIdeaInModal(idea.ideaData);
                                            setShowOutlineIdeaModal(true);
                                        }}>
                                            {idea.ideaData.customID + '. ' + idea.ideaData.name}
                                        </span>
                                        <a href={'/content-studio/?customID=' + idea.ideaData.customID} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faRobot} />
                                        </a>
                                    </div>
                                ) : (
                                    <div>{idea.name}</div>
                                )}
                                <OutlineIdeaContentTable outline={outline} idea={idea} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showOutlineIdeaModal && (
                <IdeaModal
                    idea={outlineIdeaInModal}
                    defaultStore={outlineIdeaInModal ? outlineIdeaInModal.store : null}
                    closeModal={action => {
                        if (action === 'create') {
                            console.log('create new idea');
                        }
                        setShowOutlineIdeaModal(false);
                        fetchOutlineIdeas();
                        setOutlineIdeaInModal(null);
                    }}
                    openEditIdeaModal={addNewOutlineIdea}/>
            )}
        </Container>
    );
}

export default OutlinePage;