import { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import './styles.scss';
import { toast } from 'react-toastify';
import { updateDataAPI } from '../../../utils/query';

const UploadFile = ({
    label,
    id = null,
    setLoading = () => { },
    uploadUrl = null,
    allowedFileExtentions = [],
    fieldName = null,
    uploadedFileName,
    setUploadedFileName,
    showImage = false,
}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (selectedFile) {
            upload();
        }
    }, [selectedFile]);

    const getFileExtension = (filename) => {
        return filename.split('.').pop().toLowerCase();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const fileExtension = getFileExtension(file.name);

        if (file && allowedFileExtentions.includes(fileExtension)) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
            toast.warn(`Invalid file extension. Please select a file with folowing extensions: ${allowedFileExtentions.join(',')}`)
        }
    };

    const upload = async () => {
        if (fieldName === null || uploadUrl === null || id === null || selectedFile == null) return;

        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('id', id);
        formData.append('fieldName', fieldName);

        const res = await updateDataAPI('POST', uploadUrl, formData);

        if (res.status == 200) {
            toast.info('Your file uploaded successfully.');
            fileInputRef.current.value = null;
            setSelectedFile(null);
            setUploadedFileName(res.data);
        } else {
            toast.error('An error occured when uploading file.');
        };

        setLoading(false);
    };

    return (
        <div className="upload-design-file">
            <Form >
                <Form.Group controlId="fileUpload">
                    {label && (
                        <Form.Label>{label}</Form.Label>
                    )}
                    <Form.Control
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </Form.Group>
            </Form>
            {showImage && (
                <img src={uploadedFileName} />
            )}
            {uploadedFileName && (
                <div className="uploaded-file-name">{uploadedFileName}</div>
            )}
        </div>
    )
};

export default UploadFile;