import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';
import NonAIGenerateModal from '../../auto-content/auto-content-prompt-cell/non-ai-generate-modal';

import './styles.scss';
import OutlineIdeaContentRow from '../outline-idea-content-row';

const OutlineIdeaContentTable = ({ outline, idea }) => {
    const [rows, setRows] = useState(idea.content);
    const [loading, setLoading] = useState(false);
    const [nonAIFunctionInModal, setNonAIFunctionInModal] = useState(null);
    const [nonAIFunctionRowIndex, setNonAIFunctionRowIndex] = useState(null);

    useEffect(() => {
        console.log('rows', rows);
        updateIdeaContent();
    }, [rows]);

    const updateIdeaContent = async() => {
        await updateDataAPI('PATCH', 'product-dev/outline-ideas/' + idea.id + '/', {
            content: rows,
        });
    }

    const addNewRow = () => {
        setRows([...rows, []]);
    }

    const deleteRow = (index) => {
        setRows(rows.filter((row, rowIndex) => rowIndex !== index));
    }

    const duplicateRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 0, {...rows[index]});
        setRows(newRows);
    }

    return (
        <div className="outline-idea-content-table">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Data Set</th>
                        <th>Collection</th>
                        <th>Function</th>
                        <th>Skills</th>
                        <th>NB Pages</th>
                        <th>Note</th>
                        <th>Ready</th>
                        <th>Done</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <OutlineIdeaContentRow
                            key={rowIndex}
                            rows={rows}
                            row={row}
                            setRows={setRows}
                            rowIndex={rowIndex}
                            deleteRow={deleteRow}
                            duplicateRow={duplicateRow}
                            setLoading={setLoading}
                            loading={loading}
                            setNonAIFunctionInModal={setNonAIFunctionInModal}
                            setNonAIFunctionRowIndex={setNonAIFunctionRowIndex}
                        />
                    ))}
                </tbody>
            </Table>
            <div className="btns">
                <Button size="sm" onClick={addNewRow}>Add new row</Button>
            </div>

            {nonAIFunctionInModal !== null && nonAIFunctionRowIndex !== null && (
                <NonAIGenerateModal
                    setLoading={setLoading}
                    idea={null}
                    ideaTemplateIDs={[]}
                    refresh={() => {}}
                    hideModal={() => {
                        setNonAIFunctionInModal(null);
                        setNonAIFunctionRowIndex(null);
                    }}
                    target={null}
                    ideaSkill={null}
                    handleFunctionArgs={(selectedFunctionID, selectedArgs) => {
                        if (nonAIFunctionInModal === selectedFunctionID) {
                            setRows(rows.map((r, rIndex) => {
                                if (rIndex === nonAIFunctionRowIndex) {
                                    return {...r, functionArgs: selectedArgs};
                                }
                                return r;
                            }));
                        } else {
                            window.alert('Function does not match');
                        }
                    }} />
            )}
        </div>
    )
}

export default OutlineIdeaContentTable;