import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

import './styles.scss';
import { useState } from 'react';
import SelectThumbnailTemplateModal from '../select-thumbnail-template-modal';
import ImageWrapper from '../../../../../components/common/image-wrapper';
import { updateDataAPI } from '../../../../../utils/query';

const ThumbnailPreviewItem = ({storeID, customID, item, itemName, itemType, itemPage, uploadAsset, hasColorVersion = false}) => {
    const [showSelectThumbnailTemplateModal, setShowSelectThumbnailTemplateModal] = useState(false);
    const [templateImageUrl, setTemplateImageUrl] = useState(null);

    const deleteIdeaThumbnail = async() => {
        if (window.confirm('Are you sure you want to delete this thumbnail?')) {
            await updateDataAPI('DELETE', 'resources/idea-thumbnails/' + itemPage.id + '/');
        }
    }

    return (
        <div className="thumbnail-preview-item">
            <div className="asset-placeholder">
                {item ? (
                    <ImageWrapper src={item} />
                ) : (
                    templateImageUrl ? (
                        <ImageWrapper src={templateImageUrl} />
                    ) : (
                        <FontAwesomeIcon icon={faImage} className="img-icon" />
                    )
                )}
            </div>
            <div className="item-title">
                <div>{itemName}</div>
                <span>
                    <span>(</span>
                    {itemPage && (
                        <span>
                            <a href={process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=thumbnail&autoSave&id=' + itemPage?.id + ((!itemType.toLowerCase().includes('free') && hasColorVersion) ? '&colorVersion' : '')} target="_blank" rel="noreferrer">
                                thumbnail, {' '}
                            </a>
                            <span className="text-danger" onClick={deleteIdeaThumbnail}>delete, {' '}</span>
                        </span>
                    )}
                    <span className="create-item-button" onClick={() => setShowSelectThumbnailTemplateModal(true)}>set</span>
                    <span>)</span>
                </span>
            </div>
            <Form.Control id={'upload-' + itemName.toLowerCase().replace(' ', '-') + '-input'} type="file" onChange={e => uploadAsset(e, itemType)} />

            {showSelectThumbnailTemplateModal && storeID && (
                <SelectThumbnailTemplateModal
                    storeID={storeID}
                    customID={customID}
                    itemType={itemType}
                    hideModal={(templateImageUrl = null) => {
                        if (templateImageUrl) {
                            setTemplateImageUrl(templateImageUrl);
                        }
                        setShowSelectThumbnailTemplateModal(false);
                    }} />
            )}
        </div>
    )
}

export default ThumbnailPreviewItem;