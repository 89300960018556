import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { toast } from 'react-toastify';
import './styles.scss';
import TopicGroupRow from '../../components/topics/topic-group-row';
import PagePagination from '../../components/auto-content/templates-management/page-pagination';

const DataSetPage = () => {
    const [topicGroups, setTopicGroups] = useState([]);
    const [inputGroupName, setInputGroupName] = useState('');
    const [selectedTopicGroup, setSelectedTopicGroup] = useState(null);

    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState(null);

    useEffect(() => {
        fetchTopicGroups();
    }, []);

    const fetchTopicGroups = async (pageIndex = currentPage) => {
        let url = `resources/topic-groups/?page=${pageIndex}&page_size=${pageSize}`;

        if (searchKeyword) {
            url += '&searchKeyword=' + searchKeyword;
        }

        const res = await getDataFromAPI(url);

        if (res) {
            const data = res.data;
            setTotalPages(Math.ceil(data.count / pageSize))
            const results = data.results;
            results.sort((a, b) => b.id - a.id);
            setTopicGroups(results)
        }
    };

    const handleUpdateTopicGroupFields = async (fieldName, newFieldValue) => {
        setSelectedTopicGroup((prev) => ({
            ...prev,
            [fieldName]: newFieldValue,
        }));
    };

    const handleSaveTopicGroupChanges = async () => {
        if (!selectedTopicGroup) return;
        const url = `resources/topic-groups/${selectedTopicGroup.id}/`;

        try {
            const res = await updateDataAPI('PATCH', url, {
                ...selectedTopicGroup,
                topics: formatInputTopicValues(selectedTopicGroup.topics)
            });

            setTopicGroups((prev) => prev.map((group) => {
                if (group.id === selectedTopicGroup.id) {
                    return res.data;
                }
                return group;
            }));
            setSelectedTopicGroup(null);
        } catch (err) {
            console.error(`An error occured when updating topic group: ${err.message}`);
        }
    };

    const handleDeleteTopicGroup = async (topicGroupID) => {
        const confirmed = window.confirm('Are you sure want to delete this topic group?');
        if (confirmed) {
            const url = `resources/topic-groups/${topicGroupID}/`;

            try {
                await updateDataAPI('DELETE', url);
                setTopicGroups((prev) => prev.filter((group) => group.id !== topicGroupID))
                toast.success('Deleted!');
            } catch (err) {
                console.error(`An error occured when deleting topic group: ${err.message}`);
            };
        }
    };

    const handleAddNewTopicGroup = async (e) => {
        e.preventDefault();

        if (inputGroupName) {
            const url = 'resources/topic-groups/';

            try {
                const res = await updateDataAPI('POST', url, {
                    name: inputGroupName
                });
                setTopicGroups((prev) => ([...prev, res.data]));
                setInputGroupName('');
            } catch (err) {
                console.error(`An error occured when adding new topic group: ${err.message}`);
            };
        }
    };

    const formatInputTopicValues = (input = '') => {
        return input.split('\n').filter((item) => item.trim() !== '');
    }

    const search = async(e) => {
        e.preventDefault();
        console.log(searchKeyword);
        if (searchKeyword) {
            fetchTopicGroups(1);
        }
    }

    return (
        <Container className="topic-groups-page" fluid>
            <Form className="form-search mb-3" onSubmit={search}>
                <Form.Control className="search-input" value={searchKeyword} onChange={e => setSearchKeyword(e.target.value)} placeholder="Search topic group" />
                <Button onClick={search}>Search</Button>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Set</th>
                        <th>Events</th>
                        <th>Topics</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {topicGroups.map((topicGroup) => (
                        <TopicGroupRow
                            key={topicGroup.id}
                            topicGroup={topicGroup}
                            handleUpdateTopicGroupFields={handleUpdateTopicGroupFields}
                            handleSaveTopicGroupChanges={handleSaveTopicGroupChanges}
                            handleDeleteTopicGroup={handleDeleteTopicGroup}
                            selectedTopicGroup={selectedTopicGroup}
                            setSelectedTopicGroup={setSelectedTopicGroup}
                        />
                    ))}
                </tbody>
            </Table>
            <PagePagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={(pageIndex) => {
                    setCurrentPage(pageIndex);
                    fetchTopicGroups(pageIndex);
                }}
            />
            <Form className="d-flex mt-2">
                <Form.Control
                    type="text"
                    placeholder="New group name"
                    value={inputGroupName}
                    onChange={(e) => setInputGroupName(e.target.value)}
                />
                <Button variant="primary" type="submit" onClick={handleAddNewTopicGroup}>
                    Add new group
                </Button>
            </Form>
        </Container>
    );
};

export default DataSetPage;