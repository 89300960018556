import { Badge, Carousel, Container, Dropdown, ListGroup, Spinner, Table } from 'react-bootstrap';

import './styles.scss';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import IdeaPageAssetModal from '../../components/content-studio/idea-page-asset-modal';
import AutoContentResultCell from '../../components/auto-content/auto-content-result-cell';
import { useDispatch, useSelector } from 'react-redux';
import {
    setIdeaSetting,
    setIdeaSkills,
    setIdeaTemplateContents,
    updateIdeaTemplateContent,
    setIdeaPageThemes,
    setTrainingTexts,
} from '../../components/auto-content/autoContentSlice';
import AutoContentPromptCell from '../../components/auto-content/auto-content-prompt-cell';
import Constant from '../../Constant';
import IdeaPageComments from '../../components/content-studio/idea-page-comments';
import AddNewPageButton from '../../components/content-studio/add-new-page-button';
import PagesControlModal from '../../components/content-studio/pages-control-modal';
import FloatingToolbar from '../../components/content-studio/floating-toolbar';
import PageIndexCell from '../../components/content-studio/page-index-cell';
import IdeaPageSelectOption from '../../components/content-studio/idea-page-select-option';
import IdeaTemplateSelectTrainingText from '../../components/content-studio/idea-template-select-training-text';
import SelectPageParameters from '../../components/content-studio/select-page-parameters';
import IdeaModal from '../idea-list/idea-modal';
import OutlineIdeaModal from '../../components/content-studio/outline-idea-modal';
import HotKeyController from '../../components/content-studio/hotkey-controller';
import ContentReportModal from '../../components/auto-content/auto-content-result-cell/content-report-modal';
import TemplateVersionsModal from '../../components/auto-content/templates-management/template-versions-modal';
import NonAIGenerateModal from '../../components/auto-content/auto-content-prompt-cell/non-ai-generate-modal';
import ManualContentResultCell from '../../components/auto-content/manual-content-result-cell';
import ContentStudioHeaderBtns from '../../components/content-studio/header-btns';

const ContentStudioPage = () => {
    const dispatch = useDispatch();

    const { ideaSetting } = useSelector((state) => state.autoContent);

    const [idea, setIdea] = useState();
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [pageInAssetModal, setPageInAssetModal] = useState(null);
    const [assetTypeInModal, setAssetTypeInModal] = useState(null);
    const [assetInModal, setAssetInModal] = useState(null);
    const [showPagesControlModal, setShowPagesControlModal] = useState(false);
    const [showOutlineModal, setShowOutlineModal] = useState(false);
    const [socket, setSocket] = useState(null);
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [task, setTask] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [showIdeaModal, setShowIdeaModal] = useState(false);
    const [showContentReportModal, setShowContentReportModal] = useState(false);
    const [selectedPageIDs, setSelectedPageIDs] = useState([]);
    const [originalTemplateInChangeVersionModal, setOriginalTemplateInChangeVersionModal] = useState(null);
    const [appliedVersionToAllPages, setAppliedVersionToAllPages] = useState(true);
    const [showNonAIGenerateModal, setShowNonAIGenerateModal] = useState(false);
    const [pageResultDisplayMode, setPageResultDisplayMode] = useState('simple');
    const [selectedIdeaSkill, setSelectedIdeaSkill] = useState(null);

    useEffect(() => {
        const connectWebSocket = () => {
            const newSocket = new WebSocket(
                Constant.WEBSOCKET_URL + 'ws/' + localStorage.getItem(Constant.LOCAL_STORAGE_USER_NAME).replace(' ', '_') + '/'
            );
            newSocket.onopen = () => {
                console.log('WebSocket connection opened');
                setIsSocketConnected(true);
                setSocket(newSocket);
            }
            newSocket.onerror = (error) => {
                console.log('WebSocket error:', error);
                newSocket.close();
            };
            newSocket.onclose = () => {
                console.log('Websocket closed.');
                setSocket(null);
                setIsSocketConnected(false);
                setTimeout(() => {
                    console.log('WebSocket reconnecting...');
                    connectWebSocket();
                }, 5000);
            };
            newSocket.onmessage = event => {
                const data = JSON.parse(event.data);
                console.log('new message', data);
                if (data['text'] === 'query_end') {
                    dispatch(updateIdeaTemplateContent({
                        ideaTemplateID: data['ideaTemplateID'],
                        field: 'loading',
                        value: false,
                    }));
                } else if ('text' in data) {
                    dispatch(setIdeaTemplateContents({
                        ...data,
                        icons: [],
                        loading: true,
                    }));
                }
            }
        };

        if (!isSocketConnected) {
            console.log('WebSocket connecting...');
            if (process.env.REACT_APP_DEV !== 'true') {
                connectWebSocket();
            }
        }

        return () => {
            if (socket) {
                socket.close();
            }
        }
    }, []);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        const customID = (new URLSearchParams(window.location.search)).get('customID');
        init(customID);
    }, []);

    const init = async (customID) => {
        setLoading(true);
        await fetchIdea(customID);
        await fetchIdeaSetting(customID);
        await fetchIdeaSkills(customID);
        await fetchIdeaPageThemes(customID);
        await fetchTask(customID);
        await fetchTrainingTexts();
        setLoading(false);
    }

    const fetchIdea = async (customID) => {
        const res = await getDataFromAPI('resources/get-product-idea-by-customID/?customID=' + customID);
        const idea = res.data;
        setIdea(idea);
        if (idea.subjects === null || idea.grades === null) {
            window.alert('Subjects or Grades are empty');
        }
        await fetchPages(idea);
    }

    const fetchIdeaSetting = async (customID) => {
        const res = await getDataFromAPI('auto-content/idea-settings/?customID=' + customID);
        dispatch(setIdeaSetting(res.data[0]));
    }

    const fetchPages = async (idea) => {
        const res = await getDataFromAPI('resources/idea-pages/?ideaID=' + idea.id);
        setPages(res.data);
    }

    const fetchTask = async (customID) => {
        const res = await getDataFromAPI('product-dev/tasks/?customID=' + customID);
        if (res.data.length > 0) {
            const task = res.data[0];
            setTask(task);
        }
    }

    const fetchIdeaPageThemes = async (customID) => {
        if (customID) {
            const url = `resources/idea-page-themes/?customID=${customID}&additionalFields=topicImage`;
            try {
                const res = await getDataFromAPI(url);
                if (res.data) dispatch(setIdeaPageThemes(res.data));
            } catch (e) {
                console.log(`An error occured when fetch themes: ${e.message}`);
            };
        };
    };

    const fetchIdeaSkills = async (customID) => {
        if (customID) {
            const url = `resources/idea-skills/?customID=${customID}`;
            const res = await getDataFromAPI(url);
            dispatch(setIdeaSkills(res.data));
        }
    }

    const fetchTrainingTexts = async () => {
        const res = await getDataFromAPI('auto-content/training-texts/');
        dispatch(setTrainingTexts(res.data));
    }

    const refreshPageAsset = async (pageID) => {
        const res = await getDataFromAPI('resources/idea-page-assets/?ideaPageID=' + pageID);
        setPages(pages.map(p => {
            if (p.id === pageID) {
                return { ...p, assets: res.data };
            }
            return p;
        }));
    }

    const refreshPage = async (pageID) => {
        setLoading(true);
        const res = await getDataFromAPI('resources/idea-pages/' + pageID + '/');
        setPages(pages.map(page => {
            if (page.id === pageID) {
                return res.data;
            }
            return page;
        }));
        dispatch(updateIdeaTemplateContent({
            ideaTemplateID: res.data.content.id,
            field: 'worksheet',
            value: res.data.content.content.worksheet,
        }));
        dispatch(updateIdeaTemplateContent({
            ideaTemplateID: res.data.content.id,
            field: 'answerKey',
            value: res.data.content.content.answerKey,
        }));
        if (res.data.content?.imageUrl) {
            dispatch(updateIdeaTemplateContent({
                ideaTemplateID: res.data.content.id,
                field: 'updated',
                value: false,
            }));
        }
        setLoading(false);
    }

    const refresh = async (refreshItems) => {
        setLoading(true);
        if (refreshItems.indexOf('pages') > -1) {
            await fetchPages(idea);
        }
        if (refreshItems.indexOf('skills') > -1) {
            await fetchIdeaSkills(idea.customID);
        }
        setLoading(false);
    }

    const handleShowReportWrongContentModal = () => {
        setShowContentReportModal(true);
    };

    const applyNewVersionToPages = async (newVersion, appliedToAllPages = appliedVersionToAllPages) => {
        setLoading(true);
        let updatedPages = appliedToAllPages ? pages.filter((page) => page.id !== selectedPageIDs[0]) : pages.filter((page) => selectedPageIDs.includes(page.id));

        const ideaTemplateIDs = [];
        for (let i = 0; i < updatedPages.length; i++) {
            if (updatedPages[i].content && updatedPages[i].content.templateData && (
                (newVersion.originalVersionTemplate !== null && (
                    updatedPages[i].content.templateData.originalVersionTemplate === newVersion.originalVersionTemplate ||
                    updatedPages[i].content.template === newVersion.originalVersionTemplate
                )) ||
                updatedPages[i].content.templateData.originalVersionTemplate === newVersion.id ||
                updatedPages[i].content.template === newVersion.id)) {
                ideaTemplateIDs.push(updatedPages[i].content.id);
            }
        }
        await updateDataAPI('POST', 'auto-content/apply-new-version-to-multi-pages/', {
            ideaTemplateIDs,
            newVersionID: newVersion.id
        });
        window.location.reload();
    }

    const fetchOriginalTemplateThenOpenChangeVersionModal = async (ideaTemplate, originalTemplateID) => {
        if (!ideaTemplate && !originalTemplateID) return;
        if (!originalTemplateID) {
            if (ideaTemplate.templateData.originalVersionTemplate) {
                // if the current template is a version of another template
                originalTemplateID = ideaTemplate.templateData.originalVersionTemplate;
            } else {
                originalTemplateID = ideaTemplate.template;
            }
        }
        const res = await getDataFromAPI('auto-content/templates/' + originalTemplateID + '/');
        setOriginalTemplateInChangeVersionModal(res.data);
    }

    const handleShowNonAIGenerateModal = (page) => {
        setShowNonAIGenerateModal('generate');
        setSelectedPageIDs([page.id]);
    }

    if (idea === undefined) {
        return null;
    }

    return (
        <Container fluid className="content-studio-page">
            <div className="content-studio-page__header">
                <div className="header-left">
                    {/* <a href={'/ideas?selectedStoreID=' + idea['store'] + '&searchKeyword=' + idea['customID']} target="_blank"> */}
                    <h2 onClick={() => setShowIdeaModal(true)}>{idea.customID}. {idea.title ? idea.title : idea.name}</h2>
                    {/* </a> */}
                    <div className="nb-pages">{pages.length} pages</div>
                </div>
                <ContentStudioHeaderBtns
                    idea={idea}
                    setIdea={setIdea}
                    pages={pages}
                    setPages={setPages}
                    refresh={refresh}
                    setLoading={setLoading}
                    setShowNonAIGenerateModal={setShowNonAIGenerateModal}
                    setSelectedIdeaSkill={setSelectedIdeaSkill}
                    userRoles={userRoles}
                />
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Page</th>
                        <th>Skill & Note</th>
                        <th>Auto Prompt</th>
                        <th>Result</th>
                        <th>Valid</th>
                        <th>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {pages.map((page, index) => (
                        <tr key={page.type + '-' + page.id} id={'row-' + page.id}>
                            <td className="page-index-cell-container">
                                <PageIndexCell
                                    pages={pages}
                                    page={page}
                                    index={index}
                                    refresh={refresh}
                                    setLoading={setLoading}
                                />
                            </td>
                            <td className="skill-note-cell">
                                {/* <AutoContentSelectSkill
                                    pages={pages}
                                    page={page}
                                    updateSkill={value => (
                                        setPages(pages.map(p => {
                                            if (p.id === page.id) {
                                                return { ...p, skill: value['id'], skillName: value['name'] };
                                            }
                                            return p;
                                        }))
                                    )}
                                    setLoading={setLoading}
                                /> */}

                                <IdeaPageSelectOption
                                    pages={pages}
                                    page={page}
                                    updateOption={value => (
                                        setPages(pages.map(p => {
                                            if (p.id === page.id) {
                                                return {
                                                    ...p,
                                                    skill: value?.id,
                                                    skillName: value?.name,
                                                    skillDetails: value?.details
                                                };
                                            }
                                            return p;
                                        }))
                                    )}
                                    setLoading={setLoading}
                                />
                                <IdeaPageSelectOption
                                    pages={pages}
                                    page={page}
                                    updateOption={value => (
                                        setPages(pages.map(p => {
                                            if (p.id === page.id) {
                                                return { ...p, theme: value?.id, themeName: value?.name };
                                            }
                                            return p;
                                        }))
                                    )}
                                    setLoading={setLoading}
                                    type={Constant.TYPE_IDEA_PAGE_SELECT_OPTION.THEME_TYPE}
                                />
                                <IdeaTemplateSelectTrainingText
                                    ideaTemplates={pages.map(page => page.content)}
                                    ideaTemplate={page.content}
                                    updateOption={value => (
                                        setPages(pages.map(p => {
                                            if (p.id === page.id) {
                                                return {
                                                    ...p, content: {
                                                        ...p.content, trainingText: value?.id, trainingTextData: {
                                                            ...p.content['trainingTextData'],
                                                            name: value?.name,
                                                            definition: value?.definition,
                                                            examples: value?.examples,
                                                        }
                                                    }
                                                };
                                            }
                                            return p;
                                        }))
                                    )}
                                    setLoading={setLoading}
                                />

                                <hr />

                                <div className="page-assets" title="Click to see more">
                                    {page.assets.length > 0 && (
                                        <Carousel variant="dark" interval={null}>
                                            {page.assets.map(asset => (
                                                <Carousel.Item key={asset.id}>
                                                    {asset.type === 'note' && (
                                                        <div dangerouslySetInnerHTML={{ __html: asset.note.substr(0, 300) + '...' }}
                                                            className="asset-note"
                                                            onClick={() => {
                                                                setPageInAssetModal(page);
                                                                setAssetTypeInModal('note');
                                                                setAssetInModal(asset);
                                                            }}>

                                                        </div>
                                                    )}
                                                    {asset.type === 'image' && (
                                                        <img src={'https://idea-assets.s3.us-west-2.amazonaws.com/' + asset.image}
                                                            onClick={() => {
                                                                setPageInAssetModal(page);
                                                                setAssetTypeInModal('image');
                                                                setAssetInModal(asset);
                                                            }} />
                                                    )}
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    )}
                                </div>
                                <div className="btns">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" size="sm">
                                            Add Note/Image
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                setPageInAssetModal(page);
                                                setAssetTypeInModal('note');
                                            }}>Add Note</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                setPageInAssetModal(page);
                                                setAssetTypeInModal('image');
                                            }}>Add Image</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </td>
                            <td className="prompt-cell">
                                {page.type === 'auto' && (
                                    <AutoContentPromptCell
                                        handleChangeTemplateVersion={() => {
                                            fetchOriginalTemplateThenOpenChangeVersionModal(page.content);
                                            setAppliedVersionToAllPages(true);
                                            setSelectedPageIDs([page.id]);
                                        }}
                                        socket={socket}
                                        page={page}
                                        pages={pages}
                                        idea={idea}
                                        customID={idea.customID}
                                        ideaTemplate={page.content}
                                        refreshPage={refreshPage}
                                        setLoading={setLoading}
                                        handleShowNonAIGenerateModal={handleShowNonAIGenerateModal}
                                        updateIdeaTemplate={(changes) => {
                                            setPages(pages.map(p => {
                                                if (p.id === page.id) {
                                                    const oldContent = p.content;
                                                    for (let i = 0; i < changes.length; i++) {
                                                        oldContent[changes[i][0]] = changes[i][1];
                                                    }
                                                    return {
                                                        ...p,
                                                        content: oldContent
                                                    };
                                                }
                                                return p;
                                            }));
                                        }}
                                    />
                                )}
                            </td>
                            <td className="result-cell">
                                {page.type === 'manual' && (
                                    <div>
                                        <ManualContentResultCell
                                            page={page}
                                            pages={pages}
                                            setPages={setPages}
                                            setLoading={setLoading}
                                            customID={idea.customID}
                                        />
                                    </div>
                                )}

                                {page.type === 'auto' && ideaSetting && Object.keys(ideaSetting).length > 0 && (
                                    <div>
                                        <AutoContentResultCell
                                            page={page}
                                            setSelectedPages={setSelectedPageIDs}
                                            ideaTemplate={page.content}
                                            refreshPage={refreshPage}
                                            setLoading={setLoading}
                                            pageResultDisplayMode={pageResultDisplayMode}
                                            handleShowReportWrongContentModal={handleShowReportWrongContentModal}
                                        />
                                    </div>
                                )}
                            </td>
                            <td className="valid-cell">
                                {page.type === 'auto' && (
                                    <ListGroup>
                                        <ListGroup.Item active>Quality Checklist</ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Badge bg="primary" pill>1</Badge>
                                            <span> Thông tin có đúng chủ đề không?</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Badge bg="primary" pill>2</Badge>
                                            <span> Answer key có đúng không?</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Badge bg="primary" pill>3</Badge>
                                            <span> Thông tin có khớp với design không?</span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                )}
                                <SelectPageParameters
                                    selectedPageIDs={[page.id]}
                                    setLoading={setLoading}
                                    setPages={setPages}
                                    customID={idea.customID}
                                    currentPage={page}
                                    modeDisplay='list'
                                />
                            </td>
                            <td className="comments-cell">
                                <IdeaPageComments
                                    page={page}
                                    pages={pages}
                                    setPages={setPages}
                                    setLoading={setLoading}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <AddNewPageButton idea={idea} pages={pages} setPages={setPages} refresh={refresh}
                setLoading={setLoading} placement="top" />

            {pageInAssetModal && assetTypeInModal && (
                <IdeaPageAssetModal page={pageInAssetModal} defaultType={assetTypeInModal} asset={assetInModal}
                    hideModal={action => {
                        if (action === 'refresh') {
                            refreshPageAsset(pageInAssetModal.id);
                        }
                        setPageInAssetModal(null);
                        setAssetTypeInModal(null);
                    }} />
            )}

            {showPagesControlModal && (
                <PagesControlModal
                    handleChangeTemplateVersion={(originalTemplateID) => {
                        console.log(originalTemplateID)
                        fetchOriginalTemplateThenOpenChangeVersionModal(null, originalTemplateID);
                        setAppliedVersionToAllPages(false);
                    }}
                    hideModal={() => {
                        setShowPagesControlModal(false);
                        setSelectedPageIDs([]);
                    }}
                    pages={pages}
                    customID={idea.customID}
                    selectedPages={selectedPageIDs}
                    setPages={setPages}
                    setSelectedPages={setSelectedPageIDs}
                    setLoading={setLoading}
                    refresh={refresh}
                    userRoles={userRoles}
                    handleShowReportWrongContentModal={handleShowReportWrongContentModal}
                    setShowNonAIGenerateModal={setShowNonAIGenerateModal}
                />
            )}

            <FloatingToolbar pages={pages}
                discordChannelID={task?.discordChannelID}
                showPagesControl={() => {
                    setShowPagesControlModal(true);
                    setSelectedPageIDs([]);
                }}
                customID={idea.customID}
                pageResultDisplayMode={pageResultDisplayMode}
                setPageResultDisplayMode={setPageResultDisplayMode}
                showOutlineModal={() => setShowOutlineModal(true)} />

            {showIdeaModal && idea && (
                <IdeaModal
                    idea={idea}
                    defaultStore={idea.store}
                    closeModal={action => {
                        if (action === 'update') {
                            fetchIdea(idea.customID);
                        } else if (action === 'delete') {
                            fetchIdea(idea.customID);
                        }

                        setShowIdeaModal(false);
                    }}
                />
            )}

            {showOutlineModal && idea && (
                <OutlineIdeaModal
                    customID={idea.customID}
                    hideModal={() => setShowOutlineModal(false)}
                    pages={pages} />
            )}

            <HotKeyController
                pages={pages}
                showPagesControlModal={() => setShowPagesControlModal(true)}
                showOutlineModal={() => setShowOutlineModal(true)} />

            {showContentReportModal && (
                <ContentReportModal
                    selectedPages={pages.filter((page) => selectedPageIDs.includes(page.id))}
                    hideModal={() => setShowContentReportModal(false)}
                />
            )}

            {originalTemplateInChangeVersionModal && (
                <TemplateVersionsModal
                    originalTemplate={originalTemplateInChangeVersionModal}
                    appliedVersionToAllPages={appliedVersionToAllPages}
                    ideaTemplateIDs={pages.filter((page) => selectedPageIDs.includes(page.id)).map((page) => page.content.id)}
                    customID={idea?.customID}
                    hideModal={(action, newVersion = null) => {
                        setOriginalTemplateInChangeVersionModal(null);
                        setAppliedVersionToAllPages(true);
                        if (action === 'refresh') {
                            applyNewVersionToPages(newVersion, appliedVersionToAllPages);
                        }
                    }}
                />
            )}

            {showNonAIGenerateModal && (
                <NonAIGenerateModal
                    setLoading={setLoading}
                    idea={idea}
                    ideaTemplateIDs={pages.filter((page) => selectedPageIDs.length === 0 || selectedPageIDs.includes(page.id)).map((page) => page.content.id)}
                    refresh={refresh}
                    hideModal={() => setShowNonAIGenerateModal(false)}
                    target={showNonAIGenerateModal}
                    ideaSkill={selectedIdeaSkill} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ContentStudioPage;