import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import TemplatesManagement from '../templates-management';
import { useState } from 'react';
import { updateDataAPI } from '../../../utils/query';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import CustomizeTopicsEventsPerTopicGroup from '../customize-topics-events-per-topic-group';

const TemplateSearchModal = ({ idea, newPageIndex, usedTemplateIDs, hideModal }) => {
    const dispatch = useDispatch();
    const { ideaPageThemes } = useSelector(state => state.autoContent);

    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nbNewPages, setNbNewPages] = useState(1);
    const [selectedTopicGroup, setSelectedTopicGroup] = useState(null);

    const createIdeaPagesBySelectedTemplates = async (pageIndex, themeID) => {
        for (let j = 0; j < selectedTemplates.length; j++) {
            let data = {
                idea: idea.id,
                pageIndex: pageIndex,
                type: 'auto',
            };
            if (themeID) data['theme'] = themeID;

            const resIdeaPage = await updateDataAPI('POST', 'resources/idea-pages/', data);

            await updateDataAPI('POST', 'auto-content/idea-templates/', {
                idea: idea.id,
                ideaPage: resIdeaPage.data['id'],
                template: selectedTemplates[j].id,
                activity: selectedTemplates[j].activity,
                note: selectedTemplates[j].note,
            });
            pageIndex++;
        }
    }

    const createIdeaPagesBySelectedCollections = async (themeID) => {
        for (let j = 0; j < selectedCollections.length; j++) {
            let url = 'resources/insert-collection-to-idea/?collectionID=' + selectedCollections[j].id + '&customID=' + idea.customID;
            if (themeID) {
                url += '&themeID=' + themeID;
            }
            await updateDataAPI('POST', url);
        }
    }

    const insertTemplates = async () => {
        setLoading(true);
        // let pageIndex = newPageIndex;
        let themeNames = [];
        if (selectedTopicGroup?.topics && selectedTopicGroup?.eventDatas) {
            themeNames = [...selectedTopicGroup.topics.split('\n'), ...selectedTopicGroup.eventDatas.map((event) => event.name)];
        }
        await updateDataAPI('POST', 'resources/quick-create-idea-pages/', {
            customID: idea.customID,
            rows: [
                {
                    collections: selectedCollections,
                    templates: selectedTemplates,
                    duplication: parseInt(nbNewPages),
                    themes: themeNames,
                    topicGroupID: selectedTopicGroup?.id,
                }
            ]
        });
        // for (let i = 0; i < nbNewPages; i++) {
        //     await createIdeaPagesBySelectedTemplates(pageIndex);
        // }

        // for (let i = 0; i < nbNewPages; i++) {
        //     await createIdeaPagesBySelectedCollections();
        // }
        // if (selectedTopicGroup?.topics && selectedTopicGroup?.eventDatas) {
        //     const themeNames = [...selectedTopicGroup.topics.split('\n'), ...selectedTopicGroup.eventDatas.map((event) => event.name)];
        //     for (const themeName of themeNames) {
        //         const res = await getDataFromAPI(`resources/get-or-create-idea-page-theme/?customID=${idea?.customID}&themeName=${themeName}`);

        //         if (res.data) {
        //             const themeData = res.data;

        //             if (!ideaPageThemes.find((theme) => theme.id === themeData.id)) {
        //                 dispatch(addIdeaPageTheme(themeData));
        //             };

        //             await createIdeaPagesBySelectedTemplates(pageIndex, themeData.id);
        //             await createIdeaPagesBySelectedCollections(themeData.id);
        //         }
        //     }
        // }

        hideModal('add');
    }

    return (
        <Modal
            fullscreen={true}
            centered
            show={true}
            onHide={() => hideModal('hide')}
            className="template-search-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    New Template
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplatesManagement
                    selectedTemplates={selectedTemplates}
                    setSelectedTemplates={setSelectedTemplates}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                    usedTemplateIDs={usedTemplateIDs}
                    insertTemplates={insertTemplates}
                    displayMode="insert"
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => hideModal('hide')}>Close</Button>
                <Button onClick={insertTemplates}>Insert</Button>
                <Form>
                    <Form.Control
                        value={nbNewPages}
                        type="number"
                        onChange={e => setNbNewPages(e.target.value)}
                    />
                    <Form.Text>pages/collections</Form.Text>
                </Form>
                <div className="topic-group-select">
                    <CustomizeTopicsEventsPerTopicGroup
                        handleChangeTopicGroupField={(newValue) => setSelectedTopicGroup(newValue)}
                    />
                </div>
            </Modal.Footer>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    );
}

export default TemplateSearchModal;