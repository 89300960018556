import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import FilterByStore from '../../filters/store';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

import './styles.scss';

const UploadTicketPage = () => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    const [uploadTicketsByProduct, setUploadTicketsByProduct] = useState([]);
    const [productInModal, setProductInModal] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPendingUploadTickets();
    }, []);

    const fetchPendingUploadTickets = async () => {
        setLoading(true);
        const res = await getDataFromAPI('product-dev/upload-tickets/?done=false');

        const uploadTicketsByProduct = [];

        // group tickets by product
        for (let i = 0; i < res.data.length; i++) {
            const ticket = res.data[i];
            const productIndex = uploadTicketsByProduct.findIndex(p => p.customID === ticket['customID']);
            if (productIndex > -1) {
                uploadTicketsByProduct[productIndex]['tickets'].push(ticket);
            } else {
                uploadTicketsByProduct.push({
                    customID: ticket['customID'],
                    store: ticket['store'],
                    title: ticket['productTitle'],
                    tickets: [ticket],
                });
            }
        }

        // merge all tickets in a product to format field from => to
        for (let i = 0; i < uploadTicketsByProduct.length; i++) {
            const product = uploadTicketsByProduct[i];
            let tickets = product.tickets;
            tickets.sort((a, b) => b.id - a.id);
            const changes = {};
            for (let j = 0; j < tickets.length; j++) {
                const ticket = tickets[j];
                const data = JSON.parse(ticket['fieldsToUpdate']);
                const fields = Object.keys(data);
                for (let k = 0; k < fields.length; k++) {
                    if (changes[fields[k]] === undefined) {
                        changes[fields[k]] = data[fields[k]];
                    } else {
                        changes[fields[k]]['from'] = data[fields[k]]['from'];
                    }
                }
            }
            uploadTicketsByProduct[i]['changes'] = changes;
        }

        setUploadTicketsByProduct(uploadTicketsByProduct);
        setLoading(false);
    }

    const setTicketDone = async (product) => {
        const res = await updateDataAPI('POST', 'product-dev/set-tickets-done-by-customID/?customID=' + product.customID);
        if (res.status !== 200) {
            window.alert('error');
        }
        setUploadTicketsByProduct(uploadTicketsByProduct.filter(p => p.customID !== product.customID));
    }

    const DisplayFieldChange = ({ field, value }) => {
        if (field === 'description') {
            return <ReactQuill theme="snow" value={value} readOnly={true} />;
        } else if (field === 'thumbnail') {
            return <img src={value} alt="thumbnail" />
        }

        return <Form.Control value={value} as="textarea" rows={4} disabled />;
    }

    return (
        <Container className="upload-ticket-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore hasAllOption={true} />
                    </div>
                </div>
                <div className="filter-right">
                    <div>
                    </div>
                </div>
            </div>
            <div className="tickets">
                {uploadTicketsByProduct.filter(product => {
                    if (selectedStoreID !== null && product.store !== selectedStoreID) {
                        return false;
                    }
                    return true;
                }).map(product => (
                    <div key={product.customID} className="tickets-by-product">
                        <div className="product-title">
                            {product.customID}. {product.title}
                            <div className="actions">
                                <Button onClick={() => setProductInModal(product)} size="sm">View more</Button>
                                <Button variant="success" onClick={() => setTicketDone(product)} size="sm">Set Done</Button>
                            </div>
                        </div>
                        {product.changes && (
                            <Table striped bordered hover>
                                <tbody>
                                    {Object.keys(product.changes).map((field, fieldIndex) => (
                                        <tr key={fieldIndex}>
                                            <td>{fieldIndex + 1}</td>
                                            <td>{field}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                ))}
            </div>

            {productInModal !== null && (
                <Modal show={true} onHide={() => setProductInModal(null)} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {productInModal.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Object.keys(productInModal.changes).map((field, fieldIndex) => (
                            <div className="field-changes" key={fieldIndex}>
                                <div className="field-title">{field}</div>
                                <Row>
                                    <Col>
                                        <DisplayFieldChange field={field} value={productInModal.changes[field]['from']} />

                                    </Col>
                                    <Col>

                                        <DisplayFieldChange field={field} value={productInModal.changes[field]['to']} />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Modal.Body>
                </Modal >
            )}

            {
                loading && (
                    <div className="loading-container">
                        <Spinner animation="border" variant="dark" />
                    </div>
                )
            }
        </Container >
    )
}

export default UploadTicketPage;