import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import FontsAndFramesTab from './fonts-and-frames-tab';
import StoreThumbnailTemplateTab from './store-thumbnail-templates-tab';
import IconsTab from './icons-tab';
import StoreAnswerSheetTemplateTab from './store-answer-sheet-templates-tab';
import StoreDescriptionTemplatesTab from './store-description-templates-tab';

export const generateOptions = (input = []) => {
    return [...input].map((item) => ({ ...item, label: item.name, value: item.id }))
};

const AssetsStoreManagementModal = ({
    storeID,
    fontFamilies = [],
    closeModal = () => { }
}) => {
    const [selectedFontFamilies, setSelectedFontFamilies] = useState([]);
    const [selectedFrames, setSelectedFrames] = useState([]);
    const [storeThumbnailPreviewTemplates, setStoreThumbnailPreviewTemplates] = useState([]);
    const [storeAnswerSheetPreviewTemplates, setStoreAnswerSheetPreviewTemplates] = useState([]);
    const [currentStoreIcons, setCurrentStoreIcons] = useState([]);

    const [activeTab, setActiveTab] = useState("fontsAndFrames");

    useEffect(() => {
        const fetchAssetsStore = async () => {
            if (storeID) {
                const url = `stores/${storeID}/?additionalFields=thumbnailTemplates,answerSheetTemplates,icons`;
                try {
                    const res = await getDataFromAPI(url);
                    const currentStoreFontFamilies = generateOptions(res.data.fontFamilies || []);
                    const currentStoreFrames = generateOptions(res.data.frames || []);
                    const currentStoreThumbnailTemplates = res.data?.thumbnailTemplates || [];
                    const currentStoreAnswerSheetTemplates = res.data?.answerSheetTemplates || [];
                    const storeIcons = res.data?.icons || [];

                    setSelectedFontFamilies(currentStoreFontFamilies);
                    setSelectedFrames(currentStoreFrames);
                    setStoreThumbnailPreviewTemplates(currentStoreThumbnailTemplates);
                    setStoreAnswerSheetPreviewTemplates(currentStoreAnswerSheetTemplates);
                    setCurrentStoreIcons(storeIcons);
                } catch (err) {
                    console.error(`An error occured when fetching store: ${err.message}`)
                }
            };
        };

        fetchAssetsStore();
    }, [storeID]);

    const hanldeChangeAssetsStore = async (assetName = 'font-families', selectedValues = []) => {
        const lastSelectedOption = selectedValues[selectedValues.length - 1];
        let lastTouchedOption, storeIDAfterAction;

        switch (assetName) {
            case 'font-families':
                lastTouchedOption = selectedValues.length > selectedFontFamilies.length
                    ? lastSelectedOption
                    : selectedFontFamilies[selectedFontFamilies.length - 1];
                storeIDAfterAction = selectedValues.length > selectedFontFamilies.length ? storeID : null;
                setSelectedFontFamilies(selectedValues);
                break;
            case 'frames':
                lastTouchedOption = selectedValues.length > selectedFrames.length
                    ? lastSelectedOption
                    : selectedFrames[selectedFrames.length - 1];
                storeIDAfterAction = selectedValues.length > selectedFrames.length ? storeID : null;
                setSelectedFrames(selectedValues);
                break;
            default:
                break;
        };

        let updatedStore = [...lastTouchedOption.store];

        if (storeIDAfterAction !== null) {
            updatedStore = [...updatedStore, storeIDAfterAction];
        } else {
            updatedStore = updatedStore.filter(assetStoreID => assetStoreID != storeID);
        };

        const url = `auto-content/${assetName}/${lastTouchedOption?.id}/`;

        try {
            await updateDataAPI('PATCH', url, { store: updatedStore });
        } catch (err) {
            console.error(`An error occurred when updating ${assetName}: ${err.message}`);
        }
    }

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    }

    return (
        <Modal className="assets-store-management-modal" show={true} fullscreen={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Assets store management</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs fill activeKey={activeTab} onSelect={handleTabSelect}>
                    <Tab eventKey={"fontsAndFrames"} title={"Fonts and Frames"}>
                        <FontsAndFramesTab
                            hanldeChangeAssetsStore={hanldeChangeAssetsStore}
                            selectedFontFamilies={selectedFontFamilies}
                            selectedFrames={selectedFrames}
                            fontFamilies={fontFamilies}
                        />
                    </Tab>
                    <Tab eventKey={"thumbnails"} title={"Thumbnail templates"}>
                        <StoreThumbnailTemplateTab
                            storeID={storeID}
                            thumbnailPreviewTemplates={storeThumbnailPreviewTemplates}
                            setStoreThumbnailPreviewTemplates={setStoreThumbnailPreviewTemplates}
                        />
                    </Tab>
                    <Tab eventKey={"answer_sheets"} title={"Answer Sheet templates"}>
                        <StoreAnswerSheetTemplateTab
                            storeID={storeID}
                            answerSheetPreviewTemplates={storeAnswerSheetPreviewTemplates}
                            setStoreAnswerSheetPreviewTemplates={setStoreAnswerSheetPreviewTemplates}
                        />
                    </Tab>
                    <Tab eventKey={"description_templates"} title={"Description Templates"}>
                        <StoreDescriptionTemplatesTab
                            storeID={storeID}
                        />
                    </Tab>
                    <Tab eventKey={"icons"} title={"Icons"}>
                        <IconsTab
                            storeID={storeID}
                            storeIcons={currentStoreIcons}
                            setCurrentStoreIcons={setCurrentStoreIcons}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    );
};

export default AssetsStoreManagementModal;