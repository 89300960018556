import { Alert, Button, ButtonGroup, Dropdown, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Constant from '../../../Constant';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { useDispatch, useSelector } from 'react-redux';

import './styles.scss';
import { setIdeaTemplateContents, updateIdeaTemplateContent } from '../autoContentSlice';
import BulkGenerateModal from '../bulk-generate-modal';
import ApplyManualAIResponseModal from '../apply-manual-ai-response-modal';
import WorksheetMakerIframeModal from '../worksheet-maker-iframe-modal';
import { hasPermission } from '../../../utils/auth';
import InteractivePromptModal from '../interactive-prompt-modal';

import moment from 'moment';
import { toast } from 'react-toastify';
import { warningUserWhenPageIsValidate } from '../../../utils/autoContentUtils';
import ImageWrapper from '../../common/image-wrapper';

const AutoContentPromptCell = ({
    handleChangeTemplateVersion = () => {},
    socket,
    page,
    pages,
    idea,
    ideaTemplate,
    updateIdeaTemplate,
    refreshPage,
    setLoading,
    handleShowNonAIGenerateModal = () => { }
}) => {
    const dispatch = useDispatch();

    const content = useSelector(state => state.autoContent.ideaTemplateContents[ideaTemplate.id]);

    const [showPromptModal, setShowPromptModal] = useState(false);
    const [showBulkGenerateModal, setShowBulkGenerateModal] = useState(false);
    const [showApplyManualContentModal, setShowApplyManualContentModal] = useState(false);
    const [showTemplateIframeModal, setShowTemplateIframeModal] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [isRequestOrNoteChanged, setIsRequestOrNoteChanged] = useState(false);
    const [contentPrompt, setContentPrompt] = useState({
        activity: ideaTemplate?.activity,
        note: ideaTemplate?.note,
    });
    const [cbcSubject, setCbcSubject] = useState(null);
    const [cbcCategoryDesc, setCbcCategoryDesc] = useState(null);
    const [cbcValueDesc, setCbcValueDesc] = useState(null);
    const [showCBCModal, setShowCBCModal] = useState(false);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    useEffect(() => {
        if (content && !content.loading && content['text']) {
            saveAIResponse();
        }
    }, [content?.loading]);

    const getAutoAIResponse = async () => {
        if (warningUserWhenPageIsValidate([page])) return;
        // if generate the main template of a collection, remove all contents from other pages of this collection
        if (ideaTemplate.collection !== null && ideaTemplate.templateData.collectionMainTemplate) {
            const childrenIdeaTemplates = pages.filter(page => page.type === 'auto' && page.content.collection === ideaTemplate.collection && page.content.collectionIndex === ideaTemplate.collectionIndex).map(page => page.content);
            for (let i = 0; i < childrenIdeaTemplates.length; i++) {
                dispatch(setIdeaTemplateContents({
                    ideaTemplateID: childrenIdeaTemplates[i].id,
                    worksheet: {},
                    answerKey: null,
                    icons: [],
                }));
            }
        }
        dispatch(setIdeaTemplateContents({
            ideaTemplateID: ideaTemplate.id,
            worksheet: {},
            answerKey: null,
            icons: [],
            loading: true,
        }));
        await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
            activity: ideaTemplate.activity,
            note: ideaTemplate.note,
            trainingText: ideaTemplate.trainingText,
        });
        await updateDataAPI('POST', 'auto-content/reset-zones-and-linked-text-items-in-page/?ideaTemplateID=' + ideaTemplate.id);
        socket.send(JSON.stringify({
            text: 'auto generate content for idea template ' + ideaTemplate.id,
            command: 'auto_generate',
            ideaTemplateID: ideaTemplate.id,
            sender: 'client',
            staffID: localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID),
            formatData: true,
        }));
    }

    const saveAIResponse = async () => {
        const resAIResponsValid = await updateDataAPI('POST', 'auto-content/check-ai-response-is-valid/?ideaTemplateID=' + ideaTemplate.id, {
            aiRes: content['text']
        });
        const aiResponsValid = resAIResponsValid.data['valid'];

        if (aiResponsValid) {
            await updateDataAPI('POST', 'auto-content/content-histories/', {
                ideaTemplate: ideaTemplate.id,
                rawContent: JSON.stringify(content['text']),
                source: 'autoGenerate',
                staff: parseInt(localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID)),
            });
            console.log('save ai res');
            const res = await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
                aiResponse: content['text'],
                answerKey: content['answerKey'],
                lastAiResponseUpdate: new Date()
            });
            console.log('save ai res successful');

            console.log('update iframe 1', moment().format('YYYY-MM-DD HH:mm:ss'), res.data);
            console.log(res.data['aiResponse']);
            dispatch(updateIdeaTemplateContent({
                ideaTemplateID: ideaTemplate.id,
                field: 'updated',
                value: true,
            }));
        } else {
            toast.error('The AI response is not valid! Please try again.');
        }
    }

    if (ideaTemplate.templateData.fieldsFormat === null) {
        return null;
    }

    const handleChangeAutoContentPromptCell = (e, fieldName = 'activity') => {
        if (e.target.value !== ideaTemplate?.fieldName) {
            updateIdeaTemplate([[fieldName, e.target.value]])
            setIsRequestOrNoteChanged(true);
            setContentPrompt((prev) => ({
                ...prev,
                [fieldName]: e.target.value,
            }));
        };
    };

    const handleSaveAutoContentPromptCellChange = async () => {
        const url = `auto-content/idea-templates/${ideaTemplate.id}/`;

        try {
            await updateDataAPI('PATCH', url, contentPrompt);
            setIsRequestOrNoteChanged(false);
        } catch(err) {
            toast.error(`An error occured when saving prompt: ${err.message}`);
        };
    }

    const generateExample = async() => {
        setLoading(true);
        const resPrompt = await updateDataAPI('POST', 'auto-content/get-idea-template-prompt/?ideaTemplateID=' + ideaTemplate.id);
        const resExample = await updateDataAPI('POST', 'auto-content/generate-raw-example/', {prompt: resPrompt.data});
        updateIdeaTemplate([['rawExample', resExample.data]]);
        await updateDataAPI('PATCH', 'auto-content/idea-templates/' + ideaTemplate.id + '/', {
            rawExample: resExample.data,
        });
        setLoading(false);
    }

    const generateCBC = async() => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'product-dev/test-cbc-page/', {
                ideaTemplateID: ideaTemplate.id,
                subject: cbcSubject,
                categoryDesc: cbcCategoryDesc,
                valueDesc: cbcValueDesc,
            });
            console.log(res.data);
            window.alert('Success, please refresh');
        } catch (err) {
            window.alert('Error, please try again');
        }
        setLoading(false);
    }

    const syncPromptToOtherPages = async(type) => {
        if (window.confirm('Are you sure you want to do it?')) {
            setLoading(true);
            let activity, note, ideaTemplateIDs;
            if (type === 'page') {
                activity = ideaTemplate.activity;
                note = ideaTemplate.note;
                ideaTemplateIDs = pages.filter(page => page.content && page.content.template === ideaTemplate.template && page.content.id !== ideaTemplate.id).map(page => page.content.id);
            } else if (type === 'template') {
                const resTemplate = await getDataFromAPI('auto-content/templates/' + ideaTemplate.template + '/');
                activity = resTemplate.data.activity;
                note = resTemplate.data.note;
                ideaTemplateIDs = pages.filter(page => page.content && page.content.template === ideaTemplate.template).map(page => page.content.id);
            }

            await updateDataAPI('POST', 'auto-content/update-multi-idea-templates/', {
                ideaTemplateIDs,
                changes: [{field: 'activity', value: activity}, {field: 'note', value: note}],
            });
            window.location.reload();
            setLoading(false);
        }
    }

    const copyPrompt = async () => {
        const res = await updateDataAPI('POST', 'auto-content/get-idea-template-prompt/?ideaTemplateID=' + ideaTemplate.id, {
            activity: ideaTemplate.activity,
            note: ideaTemplate.note,
            trainingTextID: ideaTemplate.trainingText,
        });
        navigator.clipboard.writeText(res.data.map(item => item.content).join('\n\n'));
    }

    return (
        <div className="auto-content-prompt-cell">
            <div className="mb-3">
                <Form.Group className="form-group">
                    <Form.Label>Request</Form.Label>
                    <Form.Control
                        value={ideaTemplate.activity}
                        as="textarea"
                        rows={3}
                        onChange={e => handleChangeAutoContentPromptCell(e, 'activity')}
                    />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Note</Form.Label>
                    <Form.Control
                        value={ideaTemplate.note}
                        as="textarea"
                        rows={5}
                        onChange={e => handleChangeAutoContentPromptCell(e, 'note')}
                    />
                </Form.Group>
                <div className="text-center mt-3 promt-actions">
                    {content?.loading ? (
                        <>
                            <Button variant="danger" size="sm" onClick={() => dispatch(updateIdeaTemplateContent({
                                ideaTemplateID: ideaTemplate.id,
                                field: 'loading',
                                value: false,
                            }))}>Stop</Button>
                            <Alert>Content Generating...</Alert>
                        </>
                    ) : (
                        <div>
                            <div className="btns">
                                <Button
                                    variant={isRequestOrNoteChanged ? "warning" : "success"}
                                    disabled={!isRequestOrNoteChanged}
                                    onClick={handleSaveAutoContentPromptCellChange}
                                    size="sm"
                                >
                                    {isRequestOrNoteChanged ? 'Save' : 'Saved'}
                                </Button>
                                {socket ? (
                                    <div>
                                        {/* {ideaTemplate.templateData.bulkGeneratable && (
                                            <Button size="sm" variant="warning" onClick={() => setShowBulkGenerateModal(true)} className="bulk-generate-btn">
                                                Bulk Generate
                                            </Button>
                                        )} */}
                                        <Dropdown as={ButtonGroup} size="sm">
                                            <Button variant="info" onClick={getAutoAIResponse}>
                                                Auto Generate
                                            </Button>

                                            <Dropdown.Toggle split id="custom-auto-generate" variant="info" />

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    if (warningUserWhenPageIsValidate([page])) return;
                                                    setShowApplyManualContentModal(true)
                                                }}>Apply Manual</Dropdown.Item>
                                                <Dropdown.Item onClick={generateExample}>Generate Example</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    if (warningUserWhenPageIsValidate([page])) return;
                                                    handleShowNonAIGenerateModal(page);
                                                }}>Non-AI Generate</Dropdown.Item>
                                                <Dropdown.Item onClick={copyPrompt}>Copy Prompt</Dropdown.Item>
                                                <Dropdown.Item onClick={() => syncPromptToOtherPages('page')}>Sync prompt with pages using same template</Dropdown.Item>
                                                <Dropdown.Item onClick={() => syncPromptToOtherPages('template')}>Sync prompt from original template</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                ) : (
                                    <div>Connecting to server...</div>
                                )}
                            </div>
                            <div className="btns">
                                <Button size="sm" variant="secondary" onClick={() => setShowPromptModal(true)} className="show-prompt-btn">
                                    Prompt
                                </Button>
                                <Dropdown as={ButtonGroup} size="sm">
                                    <Button onClick={e => {
                                        if (e.ctrlKey || e.metaKey) {
                                            window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=template&id=' + ideaTemplate.template, '_blank', 'noopener,noreferrer');
                                        } else {
                                            setShowTemplateIframeModal(true);
                                        }
                                    }} variant="secondary">
                                        Open Template
                                    </Button>

                                    <Dropdown.Toggle split id="open-and-update-variant-template" variant="secondary" />

                                    <Dropdown.Menu>
                                        {hasPermission(userRoles, ['designer']) && (
                                            <Dropdown.Item onClick={handleChangeTemplateVersion}>Change Version</Dropdown.Item>
                                        )}
                                        {/* {hasPermission(userRoles, ['product-collector']) && (
                                            <>
                                                <Dropdown.Item onClick={() => dispatchChanges('page')}>Dispatch PAGE to OTHER PAGES</Dropdown.Item>
                                                <Dropdown.Item onClick={() => dispatchChanges('template')}>Dispatch TEMPLATE to ALL PAGES</Dropdown.Item>
                                            </>
                                        )} */}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <Button size="sm" variant="info" onClick={applyTemplateDesignToAllPages} className="mt-3">
                                    Apply Template Design to all pages
                                </Button> */}
                            </div>
                            {ideaTemplate.template && ideaTemplate.templateData.imageUrl && (
                                <div className="template-preview">
                                    <ImageWrapper src={ideaTemplate.templateData.imageUrl} />
                                    <div className="template-name">{ideaTemplate.template}. {ideaTemplate.templateData.name}</div>
                                    {ideaTemplate.templateData.versionName !== null ? (
                                        <i>
                                            <span>(version: {ideaTemplate.templateData.versionName}</span>
                                            {ideaTemplate.templateData.ideaVersion === null ? (
                                                <span>- Global</span>
                                            ) : (
                                                <span>- Idea: {ideaTemplate.templateData.ideaVersion}</span>
                                            )}
                                            {ideaTemplate.templateData.store ? (
                                                <span>- Store {ideaTemplate.templateData.store})</span>
                                            ) : (
                                                <span>)</span>
                                            )}
                                        </i>
                                    ) : (
                                        <i>(Original)</i>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {showBulkGenerateModal && (
                <BulkGenerateModal
                    socket={socket}
                    ideaTemplate={ideaTemplate}
                    hideModal={() => setShowBulkGenerateModal(false)} />
            )}

            {showPromptModal && (
                <InteractivePromptModal
                    idea={idea}
                    page={page}
                    ideaTemplate={ideaTemplate}
                    updateIdeaTemplate={(changes) => updateIdeaTemplate(changes)}
                    hideModal={() => setShowPromptModal(false)} />
            )}

            {showApplyManualContentModal && (
                <ApplyManualAIResponseModal
                    socket={socket}
                    ideaTemplate={ideaTemplate}
                    hideModal={() => setShowApplyManualContentModal(false)} />
            )}

            {showTemplateIframeModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id={ideaTemplate.template}
                    hideModal={() => {
                        setShowTemplateIframeModal(false);
                        refreshPage(page.id);
                    }} />
            )}

            {showCBCModal && (
                <Modal show={true} onHide={() => setShowCBCModal(false)} size="lg" className="template-versions-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Color By Code
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Subject</Form.Label>
                            <Form.Control value={cbcSubject} onChange={e => setCbcSubject(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label>Category Definition</Form.Label>
                            <Form.Control value={cbcCategoryDesc} onChange={e => setCbcCategoryDesc(e.target.value)} />
                            <Form.Text>A category is a sight word.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label>Value Definition</Form.Label>
                            <Form.Control value={cbcValueDesc} onChange={e => setCbcValueDesc(e.target.value)} />
                            <Form.Text>A value is a word that contains this category.</Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={generateCBC}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

export default AutoContentPromptCell;