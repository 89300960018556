import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Select from 'react-select';

export const KeywordOption = ({ option }) => {
    return (<span>{option.label} <span className="float-end">{option?.avgPopularity}</span></span>)
}

const SelectKeywords = ({
    selectedKeywords = [],
    setSelectedKeywords = () => { },
}) => {
    const [keywordOptions, setKeywordOptions] = useState([]);
    const [querySearch, setQuerySearch] = useState('');
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const fetchKeywordsData = async () => {
        let formatedOptions = [];

        if (querySearch && querySearch !== '') {
            const nbExpectedKeywords = 10;
            const url = `${process.env.REACT_APP_API_TPTSELLER}keywords/get-the-most-relevant-keywords/?query=${querySearch}&nbResults=${nbExpectedKeywords}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: 'Token ' + process.env.REACT_APP_API_TPTSELLER_TOKEN
                }
            });

            formatedOptions = res.data.map((item) => ({
                ...item,
                value: item.name,
                label: `${item.name}`,
            }));
        };

        setKeywordOptions(formatedOptions);
        handleOpenMenu();
    };

    const handleCloseMenu = () => {
        setMenuIsOpen(false);
    };

    const handleOpenMenu = () => {
        setMenuIsOpen(true);
    };

    return (
        <div>
            <div className="d-flex mb-2">
                <Form.Control
                    type="text"
                    placeholder="Enter keyword's query"
                    value={querySearch}
                    onChange={(e) => setQuerySearch(e.target.value)}
                    className="me-2"
                />
                <Button
                    size="sm"
                    variant="primary"
                    onClick={fetchKeywordsData}
                >Search</Button>
            </div>
            <Select
                key={'select-keywords-title'}
                defaultValue={selectedKeywords}
                isMulti
                isClearable={false}
                value={selectedKeywords}
                onChange={(values) => setSelectedKeywords(values)}
                name="keywords"
                options={keywordOptions}
                className="keywords-select"
                classNamePrefix="select"
                formatOptionLabel={(option) => (<KeywordOption option={option}/>)}
                menuIsOpen={menuIsOpen}
                onMenuOpen={handleOpenMenu}
                onMenuClose={handleCloseMenu}
            />
        </div>
    )
};

export default SelectKeywords;