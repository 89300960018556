import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setIdeaPageThemes } from '../../auto-content/autoContentSlice';
import EventListSelect from '../../event-groups/event-list-select';

import './styles.scss';

export const initOptions = (input = []) => {
    return input.map((item) => {
        let label = item.id + '. ' + item?.name;
        if (item?.templates) {
            label += ' (' + item?.templates.nb + ' templates)';
        } else {
            label += ' (' + item?.events.length + ' events, ' + item?.topics.length + ' topics)';
        }
        return {
            ...item,
            label,
            value: item.id,
        }
    });
}

const QuickAddNewPagesModal = ({
    idea,
    startPageIndex = 0,
    hideModal = () => { }
}) => {
    const dispatch = useDispatch();
    const [collectionOptions, setCollectionOptions] = useState([]);
    const [topicGroupOptions, setTopicGroupOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([{
        collection: null,
        topicGroup: null,
        duplication: 1,
    }]);

    useEffect(() => {
        const fetchCollections = async () => {
            const res = await getDataFromAPI('auto-content/collections/?onlyActiveCollections=true');

            setCollectionOptions(initOptions(res.data));
        };

        fetchCollections();
    }, []);

    useEffect(() => {
        const fetchTopicGroups = async () => {
            const url = 'resources/get-all-topic-groups/';

            const res = await getDataFromAPI(url);

            console.log('>>>', initOptions(res.data));

            setTopicGroupOptions(initOptions(res.data));
        };

        fetchTopicGroups();
    }, []);

    const removeRow = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const addRow = () => {
        setRows([...rows, { collection: null, topicGroup: null, duplication: 1 }]);
    }

    const handleRowChange = (index, selectedField, selectedOption) => {
        const updatedRows = [...rows];
        updatedRows[index][selectedField] = selectedOption;
        setRows(updatedRows);
    }

    const checkValuesBeforeSubmit = (input = []) => {
        return input.every((item) => item);
    }

    const handleCreatePages = async () => {
        if (rows.length > 0) {
            setLoading(true);
            const uniqueRows = [...new Set(rows)];

            if (!uniqueRows.every((row) => checkValuesBeforeSubmit([row.collection, row.topicGroup]))) {
                toast.warn('Fill all required fields.');
                setLoading(false);
                return;
            }

            const data = {
                rows: uniqueRows.map((row) => ({
                    collections: [row.collection],
                    templates: [],
                    themes: [...row.topicGroup.eventDatas.map((event) => event.name), ...(row.topicGroup?.topics || '').split('\n').filter((item) => item.trim() !== '')],
                    duplication: row.duplication,
                    topicGroupID: row.topicGroup?.id,
                })),
                customID: idea.customID,
                startPageIndex,
            };

            try {
                const res = await updateDataAPI('POST', 'resources/quick-create-idea-pages/', data);
                dispatch(setIdeaPageThemes(res.data))
                hideModal('add');
            } catch (err) {
                console.error(`An error occured when creating idea pages.`);
            }
            setLoading(false);
        }
    }

    return (
        <Modal
            centered
            show={true} size="xl"
            onHide={() => hideModal('hide')}
            className="quick-add-new-pages-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add New Pages
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Collections</th>
                            <th>Duplication</th>
                            <th>Topic Groups</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td className="collection-cell">
                                    <Select
                                        value={row.collection}
                                        isSearchable
                                        isClearable
                                        options={collectionOptions}
                                        onChange={(value) => handleRowChange(index, 'collection', value)}
                                    />
                                </td>
                                <td className="duplication-cell">
                                    <Form.Control value={row.duplication} type="number" onChange={(e) => handleRowChange(index, 'duplication', parseInt(e.target.value))} />
                                </td>
                                <td className="topic-group-cell">
                                    {topicGroupOptions.length > 0 && (
                                        <Select
                                            value={row.topicGroup}
                                            isSearchable
                                            isClearable
                                            options={topicGroupOptions}
                                            onChange={(value) => {
                                                const updatedTopicGroup = value !== null ? { ...value, topics: (value?.topics || []).join('\n')} : null;
                                                handleRowChange(index, 'topicGroup', updatedTopicGroup);
                                            }}
                                        />
                                    )}
                                    {row.topicGroup && (
                                        <div>
                                            <div className="mt-2">
                                                <span>Customize topics:</span>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    value={row.topicGroup?.topics || []}
                                                    onChange={(e) => handleRowChange(index, 'topicGroup',{ ...row.topicGroup, topics: e.target.value })}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <span>Customize events:</span>
                                                <EventListSelect
                                                    topicGroup={row.topicGroup}
                                                    handleUpdateTopicGroupFields={(fieldName, fieldValue) => handleRowChange(index, 'topicGroup', { ...row.topicGroup, [fieldName]: fieldValue })}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <Button size="sm" variant="danger" onClick={() => removeRow(index)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button variant="success" onClick={addRow}>Add more row</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => hideModal('hide')}>Close</Button>
                {loading ? (
                    <span>Loading...</span>
                ) : (
                    <Button onClick={handleCreatePages}>Add</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default QuickAddNewPagesModal;