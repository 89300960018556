import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { Button, Container, Spinner, Table } from 'react-bootstrap';
import './styles.scss';

const TemplateAnswerSheetsPage = () => {
    const [answerSheetTemplates, setAnswerSheetTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAnswerSheetTemplates();
    }, []);

    const fetchAnswerSheetTemplates = async () => {
        setLoading(true);
        const res = await getDataFromAPI('auto-content/templates/?type=answer_sheet');
        setAnswerSheetTemplates(res.data['results']);
        setLoading(false);
    };

    const deleteAnswerSheetTemplate = async (template) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI(
                'DELETE',
                'auto-content/templates/' + template.id + '/'
            );
            setAnswerSheetTemplates(answerSheetTemplates.filter((a) => a.id !== template.id));
            setLoading(false);
        }
    }

    return (
        <Container className="template-answer-sheets-page" fluid>
            <div className="btns">
                <Button onClick={() => window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=answer_sheet_template&id=', '_blank', 'noopener,noreferrer')}>
                    Add New Answer sheet
                </Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Image</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {answerSheetTemplates.map((answerSheetTemplate) => (
                        <tr key={answerSheetTemplate.id}>
                            <td>{answerSheetTemplate.id}</td>
                            <td>
                                <img src={answerSheetTemplate.imageUrl} alt="answersheet" />
                            </td>
                            <td>
                                <Button
                                    size="sm"
                                    className="editBtn"
                                    onClick={() => window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=answer_sheet_template&id=' + answerSheetTemplate.id, '_blank', 'noopener,noreferrer')}
                                >
                                    Edit
                                </Button>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => deleteAnswerSheetTemplate(answerSheetTemplate)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default TemplateAnswerSheetsPage;