import { toast } from 'react-toastify';
import Constant from '../Constant';
import { getDataFromAPI, updateDataAPI } from './query';

export const searchTemplateByQuery = async (query) => {
    const url = `auto-content/templates/search/?query=${query}`;

    try {
        const res = await getDataFromAPI(url);
        return res.data;
    } catch(e) {
        console.log(`An error occured when filter templates by query: ${e.message}`);
        return [];
    }
}

export const checkCommonSkillFoundInIdeaPage = async (customID, ideaSkillID) => {
    let result = false;
    if  (customID && ideaSkillID) {
        const url = `resources/check-common-skill-in-idea-page/?customID=${customID}&ideaSkillID=${ideaSkillID}`;
        const res = await getDataFromAPI(url);
        if (res.data) {
            result = res.data.result;
        }
    };
    console.log('xyx', result);
    return result;
}

export const fetchExampleFromAIResponseOfTemplate = async (templateID) => {
    const url = 'auto-content/convert-template-ai-reponse-to-example/';

    let result = '';

    try {
        const res = await updateDataAPI('POST', url, { templateID });
        result = res.data;
    } catch (err) {
        console.error('An error occured when generate example from templateID ', templateID, err.message);
    };

    return result;
};

export const getZoneLabel = (zone) => {
    let label = '';

    if (zone === null || zone === undefined) {
        return label;
    }

    if (zone.parentField) {
        label += zone.parentField;

        if (zone.parentFieldIndex) {
            label += '(' + zone.parentFieldIndex + ')';
        }
        label += ' > ';
    }

    label += zone.fieldName + (zone.fieldIndex ? (' (' + zone.fieldIndex + ')') : '');
    return label;
}

export const getDataFromAccessPath = (data, accessPath) => {
    const accessPathParts = accessPath.split(';');
    let value = data;
    try {
        for (let i = 0; i < accessPathParts.length; i++) {
            value = value[isNaN(+accessPathParts[i]) ? accessPathParts[i] : parseInt(accessPathParts[i])]
        }
    } catch (err) {
        value = ''
    }
    return value;
}

export const checkAIResponseValid = async (template, content = {}) => {
    let zones = await getZonesOfResource(template.id);

    try {
        const response = await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
            zones: zones.map(zone => ({
                id: zone.id,
                fieldName: zone.fieldName,
                fieldIndex: zone.fieldIndex,
                parentField: zone.parentField,
                parentFieldIndex: zone.parentFieldIndex,
            })),
            answers: template.answerFields,
            templateID: template.id,
        });

        let accessPaths = response.data['accessPaths'];

        if (accessPaths) {
            for (let i = 0; i < zones.length; i++) {
                let textContent = getDataFromAccessPath(content, accessPaths[zones[i].id]) ?? '###';
                if (!textContent) {
                    return false;
                }
            }
            return true;
        };

    } catch (err) {
        window.alert('Error formatting fields');
    }

    return false;
}

export const getZonesOfResource = async (resourceID) => {
    let result = [];
    if (resourceID) {
        const res = await getDataFromAPI(`auto-content/design-items/?templateID=${resourceID}`);
        let items = res.data.map((item) => ({...item.attributes, id: item.id.toString(), accessPath: item.accessPath}));
        result = items.filter((item) => item.type === Constant.TYPE_RESOURCE_ZONE);
    };
    return result;
}

export const warningUserWhenPageIsValidate = (pages, requirements = ['dataValidated', 'designValidated']) => {
    const invalidRequirements = [];
    for (let i = 0; i < requirements.length; i++) {
        for (let j = 0; j < pages.length; j++) {
            if (pages[j][requirements[i]] && !invalidRequirements.includes(requirements[i])) {
                invalidRequirements.push(requirements[i]);
            }
        }
    }
    if (invalidRequirements.includes('dataValidated') && invalidRequirements.includes('designValidated')) {
        toast.warn('One of the selected pages\' content and design are validated. If you want to change it, invalidate it first then try again.');
        return true;
    } else if (invalidRequirements.includes('dataValidated')) {
        toast.warn('One of the selected pages\' content is validated. If you want to change it, invalidate it first then try again.');
        return true;
    } else if (invalidRequirements.includes('designValidated')) {
        toast.warn('One of the selected pages\' design is validated. If you want to change it, invalidate it first then try again.');
        return true;
    }
    return false;
}
