import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { getDataFromAPI } from '../query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const AsyncSelectComponent = ({ searchUrl, value, onChange, loading, setLoading, clearSelection, labelField = 'name' }) => {
    const loadOptions = useCallback((inputValue, callback) => {
        fetchOptions(inputValue, callback);
    }, []);

    const searchByQuery = async (query) => {
        try {
            const res = await getDataFromAPI(`${searchUrl}${query}`);
            if (res.data['results']) {
                return res.data['results'];
            }
            return res.data;
        } catch(e) {
            console.log(`An error occured when filter templates by query: ${e.message}`);
            return [];
        }
    }

    const fetchOptions = debounce(async (inputString, callback) => {
        setLoading(true);
        const data = await searchByQuery(inputString);
        const formattedData = data.map((item) => ({
            ...item,
            value: item.id,
            label: item[labelField],
        }));
        callback(formattedData);
        setLoading(false);
    }, 300);

    return (
        <div className="async-select-component">
            <AsyncSelect
                value={value}
                onChange={selectedValue => onChange(selectedValue)}
                isLoading={loading}
                loadOptions={loadOptions}
                defaultOptions
                formatOptionLabel={item => (<span>{item.value}. {item.label}</span>)}
            />
            <FontAwesomeIcon icon={faClose} onClick={clearSelection} className="clear-icon" />
        </div>
    )
}

export default AsyncSelectComponent;