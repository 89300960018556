import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './styles.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constant from '../../Constant';
import { Button, Dropdown, NavDropdown } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import axios from 'axios';
import { hasPermission, logout } from '../../utils/auth';
import { useSelector } from 'react-redux';
import HotKeyTutorialModal from './hotkey-tutorial-modal';
import StaffPerformanceBar from './staff-performance';

const Header = () => {
    const { routePermissions, activityPermissions } = useSelector((state) => state.permissions);

    const [userRoles, setUserRoles] = useState([]);
    const location = useLocation();
    const [showHotKeyTutorialModal, setShowHotKeyTutorialModal] = useState(false);

    const [latestVersion, setLatestVersion] = useState(null);
    const [systemTicket, setSystemTicket] = useState();
    const [showNewVersionAlert, setShowNewVersionAlert] = useState(false);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
        fetchVersion();
        fetchResetSystemTicket();
    }, []);

    const fetchVersion = async() => {
        const res = await getDataFromAPI('resources/versions/');
        if (res.data.length > 0) {
            setLatestVersion(res.data[0]['studioVersion']);

            if (res.data[0]['studioVersion'] !== process.env.REACT_APP_VERSION) {
                setShowNewVersionAlert(true);
            }
        }
    }

    const fetchResetSystemTicket = async() => {
        const res = await getDataFromAPI('product-dev/system-tickets/1/');
        setSystemTicket(res.data);
    }

    const pickAStaff = async (id) => {
        const res = await axios.get(Constant.API_URL + 'product-dev/get-staff-by-id/?id=' + id, {
            headers: {
                Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN)
            }
        });
        localStorage.setItem(Constant.LOCAL_STORAGE_TOKEN, res.data['token']);
        localStorage.setItem(Constant.LOCAL_STORAGE_ROLE, res.data['role']);
        localStorage.setItem(Constant.LOCAL_STORAGE_USER_EMAIL, res.data['email']);
        localStorage.setItem(Constant.LOCAL_STORAGE_STAFF_ID, id);
        window.location.reload();
    }

    const backToAdmin = () => {
        localStorage.setItem(Constant.LOCAL_STORAGE_TOKEN, localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN));
        localStorage.setItem(Constant.LOCAL_STORAGE_ROLE, 'admin');
        localStorage.setItem(Constant.LOCAL_STORAGE_USER_EMAIL, localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_EMAIL));
        localStorage.setItem(Constant.LOCAL_STORAGE_STAFF_ID, localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_ID));
        window.location.reload();
    }

    const resetSystem = async(type) => {
        if (type === 'ai') {
            const res = await updateDataAPI('PATCH', 'product-dev/system-tickets/1/', {
                restartAI: !systemTicket['restartAI'],
            });
            setSystemTicket(res.data);
        } else {
            const res = await updateDataAPI('PATCH', 'product-dev/system-tickets/1/', {
                restartDesign: !systemTicket['restartDesign'],
            });
            setSystemTicket(res.data);
        }
    }

    return (
        <Navbar bg="light" className="header">
            <Container fluid>
                <Navbar.Brand href="/">
                    <img className="logo" src="/logo.png" alt="logo" />
                    <div className="version">
                        v{process.env.REACT_APP_VERSION}
                        {latestVersion && latestVersion === process.env.REACT_APP_VERSION && ' - latest'}
                    </div>
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="/task-management" className={location.pathname === '/task-management' && 'active'}>
                        Tasks
                    </Nav.Link>

                    {hasPermission(userRoles, routePermissions['/ideas']) && (
                        <Nav.Link href="/ideas" className={location.pathname === '/ideas' && 'active'}>
                            Ideas
                        </Nav.Link>
                    )}

                    {hasPermission(userRoles, routePermissions['/templates-management']) && (
                        <NavDropdown title="Templates" id="nav-templates" className={['/templates-management', '/template-answer-sheets'].indexOf(location.pathname) > -1 && 'active'}>
                            {hasPermission(userRoles, routePermissions['/templates-management']) && (
                                <NavDropdown.Item href="/templates-management" className={location.pathname === '/templates-management' && 'active'}>Templates</NavDropdown.Item>
                            )}
                            {hasPermission(userRoles, routePermissions['/template-answer-sheets']) && (
                                <NavDropdown.Item href="/template-answer-sheets" className={location.pathname === '/template-answer-sheets' && 'active'}>Answer Sheets</NavDropdown.Item>
                            )}
                        </NavDropdown>
                    )}

                    {hasPermission(userRoles, routePermissions['/training-texts']) && (
                        <Nav.Link href="/training-texts" className={location.pathname === '/training-texts' && 'active'}>
                            Training Texts
                        </Nav.Link>
                    )}

                    <NavDropdown title="Data Set" id="nav-data-set" className={['/data-set', '/events', '/topics'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/data-set']) && (
                            <NavDropdown.Item href="/data-set" className={location.pathname === '/data-set' && 'active'}>Data Set</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/events']) && (
                            <NavDropdown.Item href="/events" className={location.pathname === '/events' && 'active'}>Events</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/topics']) && (
                            <NavDropdown.Item href="/topics" className={location.pathname === '/topics' && 'active'}>Topics</NavDropdown.Item>
                        )}
                    </NavDropdown>

                    <NavDropdown title="Tickets" id="nav-tickets" className={['/design-tickets', '/user-feedbacks'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/design-tickets']) && (
                            <NavDropdown.Item href="/design-tickets" className={location.pathname === '/design-tickets' && 'active'}>Design Tickets</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/user-feedbacks']) && (
                            <NavDropdown.Item href="/user-feedbacks" className={location.pathname === '/user-feedbacks' && 'active'}>User Feedback Tickets</NavDropdown.Item>
                        )}
                    </NavDropdown>

                    <NavDropdown title="Dashboard" id="nav-dashboards" className={['/bot-status-dashboard', '/staff-gen-data-tasks-dashboard'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/bot-status-dashboard']) && (
                            <NavDropdown.Item href="/bot-status-dashboard" className={location.pathname === '/bot-status-dashboard' && 'active'}>Bot Dashboard</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/staff-gen-data-tasks-dashboard']) && (
                            <NavDropdown.Item href="/staff-gen-data-tasks-dashboard" className={location.pathname === '/staff-gen-data-tasks-dashboard' && 'active'}>Staff Tasks Dashboard</NavDropdown.Item>
                        )}
                    </NavDropdown>

                    {hasPermission(userRoles, routePermissions['/multi-languages-ideas']) && (
                        <Nav.Link href="/multi-languages-ideas" className={location.pathname === '/multi-languages-ideas' && 'active'}>
                            Multi Langs
                        </Nav.Link>
                    )}

                    {hasPermission(userRoles, routePermissions['/outlines']) && (
                        <Nav.Link href="/outlines" className={location.pathname === '/outlines' && 'active'}>
                            Outlines
                        </Nav.Link>
                    )}
                </Nav>
                {/* {userRoles.includes('admin') && ( */}
                    <StaffPerformanceBar />
                {/* )} */}
                {userRoles && (
                    <Navbar.Collapse className="justify-content-end">
                        {/* {localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN) && (
                            <Form className="pick-a-staff-form">
                                <select className="form-select" onChange={e => pickAStaff(e.target.value)}>
                                    <option>Select Staff View</option>
                                    {staffs.map((staff, index) => (
                                        <option key={staff.id} value={staff.id}>{staff.name} ({staff.roles.join(',')})</option>
                                    ))}
                                </select>
                                <Button size="sm" variant="link" onClick={backToAdmin}>Cancel</Button>
                            </Form>
                        )} */}
                        <Navbar.Text>
                            <Dropdown>
                                <Dropdown.Toggle size="sm" id="user-dropdown-header">
                                    {localStorage.getItem(Constant.LOCAL_STORAGE_USER_NAME) && (
                                        <span className="username">{localStorage.getItem(Constant.LOCAL_STORAGE_USER_NAME)}</span>
                                    )}
                                    {localStorage.getItem(Constant.LOCAL_STORAGE_ROLES) && (
                                        <span className="user-roles"><br />{JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)).join(', ')}</span>
                                    )}
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end">
                                    {hasPermission(userRoles, activityPermissions['RESET_AI']) && systemTicket && (
                                        <>
                                            <Dropdown.Item className={systemTicket['restartAI'] ? 'to-reset' : ''} onClick={() => resetSystem('ai')}>Reset AI</Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    )}
                                    {hasPermission(userRoles, activityPermissions['RESET_DESIGN']) && systemTicket && (
                                        <>
                                            <Dropdown.Item className={systemTicket['restartDesign'] ? 'to-reset' : ''} onClick={() => resetSystem('design')}>Reset Design</Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    )}
                                    <Dropdown.Item href="/staff-performance">Performance</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => setShowHotKeyTutorialModal(true)}>HotKey Tutorial</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>
                    </Navbar.Collapse>
                )}
            </Container>

            <div>
                {showNewVersionAlert && process.env.REACT_APP_DEV !== 'true' && (
                    <div className="new-version-alert">
                        <div><strong>Not latest version</strong></div>
                        <div><i>Refresh to update (or hard refresh if not working)</i></div>
                        <Button size="sm" onClick={() => window.location.reload()}>Refresh</Button>
                    </div>
                )}
            </div>

            {showHotKeyTutorialModal && (
                <HotKeyTutorialModal hideModal={() => setShowHotKeyTutorialModal(false)} />
            )}
        </Navbar>
    );
}

export default Header;