import { Badge } from 'react-bootstrap';
import PageButtons from '../page-buttons';
import { useEffect, useState } from 'react';
import Constant from '../../../Constant';

import './styles.scss';

const PageIndexCell = ({pages, page, index, refresh, setLoading}) => {
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    // const addMissingPagesToCollection = async() => {
    //     setLoading(true);
    //     await updateDataAPI('POST', 'auto-content/add-missing-pages-to-collection/?ideaTemplateID=' + page.content.id);
    //     setLoading(false);
    // }

    return (
        <div className="page-index-cell">
            <div>{page.pageIndex + 1}</div>
            <div className="page-badges">
                <Badge className={'badge-' + page.type}>{page.type}</Badge>
                {page.type === 'auto' && page.content && page.content.collection !== null && (
                    <div className={'badge-collection ' + (page.content.collectionData.mainTemplate ? 'main-template' : 'child-template')}>
                        <Badge bg="warning">
                            {page.content.collectionIndex + ' - ' + page.content.collectionData?.name}
                            {page.content.collectionData.mainTemplate ? ' - main' : ''} (collection {page.content.collection})
                        </Badge>
                    </div>
                )}
            </div>
            <PageButtons
                page={page}
                pageIndex={index}
                pages={pages}
                refresh={refresh}
                setLoading={setLoading} />

            <div className="fingerprint">
                {page.content && userRoles.includes('admin') && page.type === 'auto' && (
                    <div>
                        <a href={Constant.API_URL + 'admin/auto_content/template/' + page.content.template} target="_blank">
                            Template: {page.content.template}
                        </a>
                    </div>
                )}
                <div>
                    {userRoles.includes('admin') && (
                        <a href={Constant.API_URL + 'admin/free_resources/ideapage/' + page.id} target="_blank">
                            Page: {page.id}
                        </a>
                    )}
                </div>
                {page.content && page.type === 'auto' && (
                    userRoles.includes('admin') ? (
                        <div>
                            <a href={Constant.API_URL + 'admin/auto_content/ideatemplate/' + page.content.id} target="_blank">
                                Idea Template: {page.content.id}
                            </a>
                        </div>
                    ) : (
                        <span>Page: {page.content.id}</span>
                    )
                )}
                {page.type === 'manual' && userRoles.includes('admin') && page.content && (
                    <div>
                        <a href={Constant.API_URL + 'admin/product_dev/designworksheet/' + page.content.id} target="_blank">
                            Design Worksheet: {page.content.id}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PageIndexCell;