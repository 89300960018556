import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { faCheckCircle, faEdit, faFolderTree } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import TemplatesInCollectionModal from '../templates-in-collection-modal';
import { getDataFromAPI } from '../../../../utils/query';
import CollectionInfoModal from '../collection-info-modal';
import ContentTags from '../template-tags';

const CollectionList = ({ collections, setCollections, selectedCollections, setSelectedCollections, fetchCollections, refreshCategories, categories, fetchAllCategories }) => {
    const [collectionInModal, setCollectionInModal] = useState(null);
    const [edittingCollectionInInfoModal, setEdittingCollectionInInfoModal] = useState(false);

    const refreshCollection = async(id) => {
        const res = await getDataFromAPI('auto-content/collections/' + id + '/');
        setCollections(collections.map(collection => {
            if (collection.id === id) {
                return res.data;
            }
            return collection;
        }));
    }

    return (
        <div className="collection-list">
            {collections.map(collection => (
                <div key={collection.id} className="collection" onClick={() => {
                    if (setSelectedCollections) {
                        if (selectedCollections.find(c => c.id === collection.id) !== undefined) {
                            setSelectedCollections(selectedCollections.filter(t => t.id !== collection.id));
                        } else {
                            setSelectedCollections(selectedCollections.concat(collection));
                        }
                    }
                }}>
                    <div className={'collection-thumbnail ' + (selectedCollections.find(c => c.id === collection.id) !== undefined ? 'selected ' : ' ')}>
                        {collection.templates.mainTemplate && (
                            <img src={collection.templates.mainTemplate.imageUrl ? collection.templates.mainTemplate.imageUrl : collection.templates.mainTemplate.imageUrl} />
                        )}
                        {selectedCollections.find(c => c.id === collection.id) !== undefined && (
                            <FontAwesomeIcon className="check-icon" icon={faCheckCircle} />
                        )}
                    </div>
                    <div className="collection-name">
                        <div><strong>{collection.id}. {collection.name}</strong></div>
                        <div>({collection.templates.nb} templates)</div>
                        <div>
                            <FontAwesomeIcon icon={faEdit} onClick={() => setEdittingCollectionInInfoModal(collection)} />
                            <FontAwesomeIcon icon={faFolderTree} onClick={() => setCollectionInModal(collection)} />
                        </div>
                    </div>
                    <ContentTags
                        content={collection}
                        contentType={'collection'}
                        tags={collection.tagsData}
                        categories={categories}
                        refreshContents={() => {
                            fetchCollections();
                            refreshCategories();
                        }}
                        refreshCategories={fetchAllCategories}
                        updateTagsInContents={(collectionID, data) => {
                            setCollections(collections.map(t => {
                                if (t.id === collectionID) {
                                    return data;
                                }
                                return t;
                            }));
                        }} />
                </div>
            ))}

            {collectionInModal && (
                <TemplatesInCollectionModal
                    collection={collectionInModal}
                    hideModal={() => {
                        refreshCollection(collectionInModal.id);
                        setCollectionInModal(null);
                    }}
                />
            )}

            {edittingCollectionInInfoModal && (
                <CollectionInfoModal
                    collection={edittingCollectionInInfoModal}
                    hideModal={() => setEdittingCollectionInInfoModal(null)}
                    collections={collections}
                    setCollections={setCollections} />
            )}
        </div>
    )
}

export default CollectionList;