import { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import AsyncSelect from 'react-select/async';
import { searchTemplateByQuery } from '../../../../utils/autoContentUtils';
import { updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';

import './styles.scss';
import { getLastPositionIndexTemplateInCollection } from '../../../../utils/templateManagementUtils';

export const TemplateOptionLabel = ({ template }) => (
    <span>{template.value}. {template.label}</span>
);

const AddTemplateToCollection = ({
    collection = {},
    templates = [],
    updateTemplatePositionInCollection = () => {},
}) => {
    const [loading, setLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const loadOptions = useCallback((inputValue, callback) => {
        // Use the debounced fetch function
        fetchTemplateOptions(inputValue, callback);
    }, [templates]);

    const fetchTemplateOptions = debounce(async (inputString, callback) => {
        setLoading(true);
        const data = await searchTemplateByQuery(inputString);
        const filteredTemplates = data.filter((template) => !templates.some((t) => t.id === template.id));
        const formattedData = filteredTemplates.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
        }));
        setLoading(false);
        callback(formattedData);
    }, 300);

    const handleChangeSelectTemplate = (selectedValue) => {
        setSelectedTemplate(selectedValue);
    };

    const addTemplateToCollection = async () => {
        if (selectedTemplate !== null && selectedTemplate.hasOwnProperty('id')) {
            const url = 'auto-content/template-collections/';

            try {
                const lastPositionIndex = getLastPositionIndexTemplateInCollection(templates, collection['id']);
                const newTemplateCollection = {
                    template: selectedTemplate['id'],
                    collection: collection['id'],
                    positionIndex: lastPositionIndex + 1,
                };
                const res = await updateDataAPI('POST', url, newTemplateCollection);

                const newTemplatesData = [
                    ...templates,
                    {
                        ...selectedTemplate,
                        collectionMainTemplate: false,
                        positionIndex: lastPositionIndex + 1,
                        templateCollectionID: res.data['id']
                    },
                ];

                setSelectedTemplate(null);

                updateTemplatePositionInCollection(newTemplatesData);
            } catch (e) {
                toast.error(`An error occured when adding template: ${e.message}`);
            };
        };
    };

    return (
        <div className="add-template-to-collection">
            <AsyncSelect
                value={selectedTemplate}
                onChange={handleChangeSelectTemplate}
                isLoading={loading}
                // cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                formatOptionLabel={(template) => (<TemplateOptionLabel key={template.id} template={template} />)}
            />
            <Button variant="success" onClick={addTemplateToCollection}>
                Add Template
            </Button>
        </div>
    )
}

export default AddTemplateToCollection;