import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faRobot,
    faCake,
    faInbox,
    faChessKing,
    faCopy,
    faRemove,
    faTrash,
    faChevronUp,
    faChevronDown,
    faLink,
    faFolderTree,
} from '@fortawesome/free-solid-svg-icons';
import ContentTags from '../template-tags';
import './styles.scss';
import WorksheetMakerIframeModal from '../../worksheet-maker-iframe-modal';
import { Fragment, useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { toast } from 'react-toastify';
import PagePagination from '../page-pagination';
import TemplateRequirementsSettingModal from '../template-requirements-setting-modal';
import { hasPermission, hasRightToDoAction } from '../../../../utils/auth';
import Constant from '../../../../Constant';
import { getLastPositionIndexTemplateInCollection } from '../../../../utils/templateManagementUtils';
import TemplateVersionsModal from '../template-versions-modal';

const TemplateList = ({
    templates,
    selectedTemplates = [],
    setSelectedTemplates,
    usedTemplateIDs = [],
    categories = [],
    setTemplates,
    fetchTemplates,
    fetchAllCategories,
    showOnlyActiveTemplates,
    showTemplateActions = false,
    editVersionInfo = () => {},
    currentVersion,
    removeTemplateFromCollection = () => { },
    updateTemplatePositionInCollection = async () => { },
    currentPage = 1,
    resetTemplatesPagination = () => { },
    totalPages = 1,
    handlePageChange = () => { },
    showPagination = false,
    mode="normal",
    collection,
    customID,
}) => {
    const [templateInModal, setTemplateInModal] = useState(null);

    const [showTemplateRequirementsSetting, setShowTemplateRequirementsSetting] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [templateInVersionsModal, setTemplateInVersionsModal] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const refreshTemplateThenCloseModal = async () => {
        const templateID = templateInModal.id;
        setTemplateInModal(null);
        const res = await getDataFromAPI('auto-content/templates/' + templateID + '/');
        setTemplates(templates.map(t => {
            if (t.id === templateID) {
                return res.data;
            }
            return t;
        }));
    }

    const createTemplateCollection = async (newTemplateCollectionData, newTemplateData) => {
        const url = 'auto-content/template-collections/';

        try {
            const res = await updateDataAPI('POST', url, newTemplateCollectionData);
            const templatesData = [
                ...templates,
                {
                    ...newTemplateData,
                    collectionMainTemplate: false,
                    positionIndex: res.data['positionIndex'],
                    templateCollectionID: res.data['id']
                },
            ];

            updateTemplatePositionInCollection(templatesData);
        } catch(e) {
            toast.error(`An error occured when creating template collection: ${e.message}`);
            console.log(`An error occured when creating template collection: ${e.message}`);
        };
    };

    const duplicate = async (template) => {
        if (window.confirm('Are you sure?')) {
            try {
                const staffID = Math.floor(localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID));
                const res = await updateDataAPI('POST', 'auto-content/duplicate-template/?templateID=' + template.id + "&staffID=" + staffID);
                setTemplates([res.data].concat(templates));
                if (mode === 'listInCollectionModal' && collection) {
                    const lastPositionIndex = getLastPositionIndexTemplateInCollection(templates, collection['id']);
                    const newTemplateCollectionData = {
                        template: res.data['id'],
                        collection: collection['id'],
                        positionIndex: lastPositionIndex + 1,
                    }
                    await createTemplateCollection(newTemplateCollectionData, res.data);
                }
            } catch (e) {
                window.alert(e);
            }
        }
    }

    const deleteTemplate = async (template) => {
        if (mode === 'listVersions') {
            if (template.versionName === null) {
                window.alert('Can not delete original version');
                return;
            }

            if (currentVersion && currentVersion.id === template.id) {
                window.alert('Can not delete current version');
                return;
            }
        }

        if (window.confirm('Do you really want to remove this template?')) {
            try {
                await updateDataAPI('POST', 'auto-content/delete-template/?templateID=' + template.id);
                setTemplates(templates.filter(t => t.id !== template.id));
            } catch (e) {
                window.alert('This template is used in a product. Can not delete.')
            }
        }
    }

    const moveItemToTheLastPositionOfMainTemplate = (array, item, currentIndex) => {
        // Move template to the position after the last main template
        const lastMainTemplateIndex = array
            .slice(0, currentIndex)
            .reverse()
            .findIndex((t) => t.collectionMainTemplate);
        if (lastMainTemplateIndex !== -1) {
            // Move template to the position after the last main template
            const newPosition = currentIndex - lastMainTemplateIndex;
            array.splice(currentIndex, 1);
            array.splice(newPosition, 0, { ...item, collectionMainTemplate: true });
        } else {
            // Move template to the fist position if don't have any main template
            array.splice(currentIndex, 1);
            array.splice(0, 0, { ...item, collectionMainTemplate: true });
        };
        return array;
    }

    const pushCollectionMainTemplateToTheTop = async (templateID, newCollectionMainTemplateValue = false) => {
        const updatedData = [...templates];
        const templateIndex = updatedData.findIndex((template) => template.id === templateID);

        if (templateIndex !== -1) {
            const template = updatedData[templateIndex];

            if (newCollectionMainTemplateValue) {
                // Move template to the position after the last main template
                moveItemToTheLastPositionOfMainTemplate(updatedData, template, templateIndex);
            } else {
                const nextItem = updatedData[templateIndex + 1];
                if (nextItem?.collectionMainTemplate) {
                    updatedData.splice(templateIndex, 1);
                    updatedData.push({ ...template, collectionMainTemplate: false });
                } else {
                    updatedData[templateIndex]['collectionMainTemplate'] = false;
                };

            };
        };

        await updateTemplatePositionInCollection(updatedData);
    };

    const deleteTemplateRequirementWhenTemplateBecomeMain = async (templateID) => {
        const url = `auto-content/template-requirements/?templateID=${templateID}`;

        try {
            const res = await getDataFromAPI(url);
            const data = res.data;
            if (data.result) {
                await updateDataAPI('DELETE', `auto-content/template-requirements/${data['data']['id']}`);
            };
        } catch(e) {
            console.log(`An error occured when deleting template requirements with the current template: ${e.message}`);
        };
    };

    const toggleCollectionMainTemplate = async (template, newCollectionMainTemplateValue = false) => {
        if (template.templateCollectionID) {
            if (newCollectionMainTemplateValue) {
                await deleteTemplateRequirementWhenTemplateBecomeMain(template['id']);
            };
            try {
                await pushCollectionMainTemplateToTheTop(template['id'], newCollectionMainTemplateValue);
            } catch (e) {
                console.log(`An error occured when toggling collection main temlate: ${e.message}`);
            };
        };
    };

    const handleMoveTemplateUp = (template) => {
        const templateIndex = templates.findIndex((obj) => obj.id === template.id);
        const updatedData = [...templates];
        const currentItem = updatedData[templateIndex];
        const previousItem = updatedData[templateIndex - 1];

        // Prioritize main templates
        if (!currentItem.collectionMainTemplate && previousItem.collectionMainTemplate) {
            // Skip moving main template above a non-main template
            toast.error("You can't moving main template above a non-main template");
            return;
        };
        updatedData.splice(templateIndex - 1, 2, currentItem, previousItem);
        updateTemplatePositionInCollection(updatedData);
    };

    const handleMoveTemplateDown = (template) => {
        const templateIndex = templates.findIndex((obj) => obj.id === template.id);
        const updatedData = [...templates];
        const currentItem = updatedData[templateIndex];
        const nextItem = updatedData[templateIndex + 1];

        // Prioritize main templates
        if (currentItem.collectionMainTemplate && !nextItem.collectionMainTemplate) {
            // Skip moving main template below a non-main template
            toast.error("You can't moving main template below a non-main template");
            return;
        };
        updatedData.splice(templateIndex, 2, nextItem, currentItem);
        updateTemplatePositionInCollection(updatedData);
    };

    return (
        <div className="d-flex flex-column">
            <div className="template-list">
                {templates.filter(t => {
                    if (showOnlyActiveTemplates && !t.active) {
                        return false;
                    }
                    return true;
                }).map(template => (
                    <div key={template.id} className="template">
                        <div className={'template-img ' +
                            (selectedTemplates.find(t => t.id === template.id) !== undefined ? 'selected ' : ' ')}
                            onClick={() => {
                                if (setSelectedTemplates) {
                                    if (selectedTemplates.find(t => t.id === template.id) !== undefined) {
                                        setSelectedTemplates(selectedTemplates.filter(t => t.id !== template.id));
                                    } else {
                                        setSelectedTemplates(selectedTemplates.concat(template));
                                    }
                                }
                            }}
                        >
                            <img src={template.imageUrl ? template.imageUrl : template.imageUrl} />
                            {selectedTemplates.find(t => t.id === template.id) !== undefined && (
                                <div className="template-selected-index">{selectedTemplates.findIndex(t => t.id === template.id) + 1}</div>
                            )}
                        </div>
                        <div className="template-icons d-flex">
                            {template.fieldsFormat !== null ? (
                                <div>
                                    <FontAwesomeIcon icon={faRobot} />
                                    <span>AI</span>
                                </div>
                            ) : (
                                <div>
                                    <FontAwesomeIcon icon={faCake} />
                                    <span>Non-AI</span>
                                </div>
                            )}
                            {usedTemplateIDs.indexOf(template.id) > -1 && (
                                <div>
                                    <FontAwesomeIcon icon={faInbox} />
                                    <span>used</span>
                                </div>
                            )}
                            {template?.templateCollectionID && (
                                <div className={'set-main-template ' + (template.collectionMainTemplate ? 'main' : '')}
                                    onClick={() => toggleCollectionMainTemplate(template, !template.collectionMainTemplate)}>
                                    <FontAwesomeIcon icon={faChessKing} />
                                    <span>Main</span>
                                </div>
                            )}
                            {!template.active && (
                                <div>
                                    <span>Unpublished</span>
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="template-name">
                                {mode === 'listVersions' ? (
                                    <div className="version-info" onClick={() => editVersionInfo(template)}>
                                        {template.versionName !== null ? (
                                            <div>
                                                <span>Version: {template.versionName}</span>
                                                {template.ideaVersion === null && template.store === null && (
                                                    <span>- Global</span>
                                                )}
                                                {template.store && (
                                                    <span>- Store {template.store}</span>
                                                )}
                                                {template.ideaVersion && (
                                                    <span>- Idea {template.ideaVersion}</span>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <strong>Version Original</strong>
                                            </div>
                                        )}
                                        {currentVersion?.id === template.id && (
                                            <div>(CURRENT)</div>
                                        )}
                                        <span>(id: {template.id})</span>
                                    </div>
                                ) : (
                                    <strong>{template.id}. {template.name}</strong>
                                )}
                                <div>
                                    <span onClick={e => {
                                        if (e.ctrlKey || e.metaKey) {
                                            window.open(process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=template&id=' + template.id, '_blank', 'noopener,noreferrer');
                                        } else {
                                            setTemplateInModal(template);
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </span>
                                    <span onClick={() => duplicate(template)}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </span>
                                    {mode !== 'listVersions' && hasPermission(userRoles, ['designer']) && (
                                        <span onClick={() => setTemplateInVersionsModal(template)}>
                                            <FontAwesomeIcon icon={faFolderTree} />
                                        </span>
                                    )}
                                    {(template.author === null || hasRightToDoAction(template.author)) && (
                                        <span onClick={() => deleteTemplate(template)} title="remove template">
                                            <FontAwesomeIcon icon={faRemove} />
                                        </span>
                                    )}
                                    {showTemplateActions && mode === 'listInCollectionModal' && (
                                        <span onClick={() => removeTemplateFromCollection(template)} title="remove template from collection">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </span>
                                    )}
                                    {showTemplateActions && template.hasOwnProperty('positionIndex') && (
                                        <Fragment>
                                            {template['positionIndex'] > 0 && (
                                                <span onClick={() => handleMoveTemplateUp(template)}>
                                                    <FontAwesomeIcon icon={faChevronUp} />
                                                </span>
                                            )}
                                            {template['positionIndex'] < (templates.length - 1) && (
                                                <span onClick={() => handleMoveTemplateDown(template)}>
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                </span>
                                            )}
                                        </Fragment>
                                    )}
                                    {template.templateCollectionID && !template.collectionMainTemplate && (
                                        <span onClick={() => {
                                            setShowTemplateRequirementsSetting(true);
                                            setCurrentTemplate(template)
                                        }} className={'template-requirements ' + (template.requirements.length > 0 ? 'active' : '')}>
                                            <FontAwesomeIcon icon={faLink} />
                                        </span>
                                    )}
                                </div>
                            </div>
                            {template.activity && (
                                <div className="template-activity">{template.activity.substr(0, 200)}...</div>
                            )}
                            <hr />
                            <ContentTags
                                content={template}
                                contentType={'template'}
                                tags={template.tagsData}
                                categories={categories}
                                refreshContents={() => {
                                    fetchTemplates();
                                    resetTemplatesPagination();
                                    fetchAllCategories();
                                }}
                                refreshCategories={fetchAllCategories}
                                updateTagsInContents={(templateID, data) => {
                                    setTemplates(templates.map(t => {
                                        if (t.id === templateID) {
                                            return data;
                                        }
                                        return t;
                                    }));
                                }} />
                        </div>
                    </div>
                ))}
            </div>

            {templateInModal && (
                <WorksheetMakerIframeModal
                    type="template"
                    id={templateInModal.id}
                    hideModal={refreshTemplateThenCloseModal} />
            )}
            {showPagination && (
                <PagePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                />
            )}
            {(showTemplateRequirementsSetting && currentTemplate !== null) && (
                <TemplateRequirementsSettingModal
                    currentTemplate={currentTemplate}
                    templates={templates}
                    setTemplates={setTemplates}
                    listMainTemplates={templates.filter(template => template.collectionMainTemplate)}
                    collection={collection}
                    hideModal={() => {
                        setShowTemplateRequirementsSetting(false);
                        setCurrentTemplate(null);
                    }}
                />
            )}

            {templateInVersionsModal && (
                <TemplateVersionsModal
                    originalTemplate={templateInVersionsModal}
                    customID={customID}
                    hideModal={(action, newVersion = null) => {
                        if (action === 'open_template_iframe') {
                            setTemplateInModal(newVersion);
                        }
                        setTemplateInVersionsModal(null);
                    }}
                />
            )}
        </div>
    );
}

export default TemplateList;