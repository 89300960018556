import axios from 'axios';
import { useEffect, useState } from 'react';
import Constant from '../../Constant';
import { Button, Container } from 'react-bootstrap';
import './styles.scss';
import { getDataFromAPI } from '../../utils/query';

const StaffsPage = () => {
    const [staffs, setStaffs] = useState([]);
    const [selectedStaffID, setSelectedStaffID] = useState(null);
    const [nbTasksDoneByStaff, setNbTasksDoneByStaff] = useState([]);

    useEffect(() => {
        fetchStaffsExceptAdmin();
    }, []);

    useEffect(() => {
        if (selectedStaffID !== null) {
            fetchStaffDoneTasks();
        }
    }, [selectedStaffID]);

    const fetchStaffsExceptAdmin = async () => {
        const res = await getDataFromAPI('staffs/');
        setStaffs(res.data.filter(staff => staff.role !== 'admin'));
    }

    const fetchStaffDoneTasks = async () => {
        const selectedStaff = staffs.find(s => s.id === selectedStaffID);
        const listes = Constant.TRELLO_DONE_LIST_IDS.filter(l => l.role === selectedStaff.role);
        const nbTasksDoneByStaff = [];
        for (let i = 0; i < listes.length; i++) {
            const trelloList = listes[i];
            const res = await axios.get(Constant.TRELLO_LIST_API_URL + trelloList['id'] + '/cards?' + Constant.TRELLO_TOKEN);
            nbTasksDoneByStaff.push({ ...trelloList, nbTasksDone: res.data.filter(card => card.idMembers.indexOf(selectedStaff.trelloID) > -1).length });
        }
        setNbTasksDoneByStaff(nbTasksDoneByStaff);
    }

    const archiveList = async () => {
        if (window.confirm('Are you sure?')) {
            const selectedStaff = staffs.find(s => s.id === selectedStaffID);
            const listIDs = Constant.TRELLO_DONE_LIST_IDS.filter(l => l.archivable && l.role === selectedStaff.role).map(l => l.id);
            for (let i = 0; i < listIDs.length; i++) {
                const res = await axios.post(Constant.TRELLO_LIST_API_URL + listIDs[i] + '/archiveAllCards?' + Constant.TRELLO_TOKEN);
                if (res.status === 200) {
                    window.alert('Archive successfully');
                    window.location.reload();
                }
            }
        }
    }

    return (
        <Container className="staffs-container">
            <h1>Chọn nhân viên</h1>
            <div>
                {staffs.map(staff => (
                    <div key={staff.id} className="staff" onClick={() => setSelectedStaffID(staff.id)}>
                        <div className="staff-name text-primary">{staff.name}</div>
                        {selectedStaffID === staff.id && (
                            <div className="staff-nb-tasks-done">
                                {nbTasksDoneByStaff.map((list, index) => (
                                    <div key={index} className="tasks-done-by-list">
                                        <div>{list['title']} - {list['nbTasksDone']} tasks done</div>
                                        {list['archivable'] && list['nbTasksDone'] > 0 && (
                                            <Button size="sm" variant="danger" onClick={() => archiveList(list['id'])}>Archive</Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </Container>
    )
}

export default StaffsPage;