import { useEffect, useState } from 'react';

import IdeasListTable from '../../components/idea-list/idea-list-table';
import IdeaModal from './idea-modal';
import { Button, Container, Spinner } from 'react-bootstrap';
import FilterByStore from '../../filters/store';
import FilterByCategory from '../../filters/category';
import FilterBySearch from '../../filters/search';
import { useSelector } from 'react-redux';
import Notification from '../../utils/notification';
import FilterByGrades from '../../filters/grade';
import { getDataFromAPI } from '../../utils/query';
import Constant from '../../Constant';
import { hasPermission } from '../../utils/auth';
import ReuploadFilesModal from '../../components/idea-list/re-upload-files-modal';
import FilterBySubjects from '../../filters/subject';
import { loadFonts } from '../../utils/commonUtil';
import AssetsStoreManagementModal from '../../components/idea-list/assets-store-management-modal';
import FilterByTaskStep from '../../filters/task-step';

const IdeaListPage = () => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [userRoles, setUserRoles] = useState([]);
    const [showCreateIdeaModal, setShowCreateIdeaModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ideas, setIdeas] = useState([]);
    const [notification, setNotification] = useState(null);
    const [selectedIdeaIDs, setSelectedIdeaIDs] = useState([]);
    const [showReuploadFilesModal, setShowReuploadFilesModal] = useState(false);
    const [editingIdea, setEditingIdea] = useState(null);
    const [fontFamilies, setFontFamilies] = useState([]);
    const [showAssetsStoreManagement, setShowAssetsStoreManagement] = useState(false);

    useEffect(() => {
        fetchIdeas();
        setSelectedIdeaIDs([]);
        fetchFontFamilies();
    }, [selectedStoreID]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const fetchIdeas = async () => {
        setLoading(true);
        let res;
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            res = await getDataFromAPI('product-ideas/?additionalFields=storeData,relatedProductIDs,nbReviews,bundleChildrenIdeas&store=' + selectedStoreID);
        } else if (selectedStoreID === 'all') {
            res = await getDataFromAPI('product-ideas/?additionalFields=storeData,relatedProductIDs,nbReviews,bundleChildrenIdeas');
        }

        if (res) {
            const ideas = res.data;
            ideas.sort((a, b) => {
                let regex = /^(\d+)([a-zA-Z]*)$/i;
                let matchA = a.customID.match(regex);
                let matchB = b.customID.match(regex);

                try {
                    let numA = parseInt(matchA[1], 10);
                    let numB = parseInt(matchB[1], 10);

                    if (numA === numB) {
                        return matchA[2].localeCompare(matchB[2]);
                    } else {
                        return numA - numB;
                    }
                } catch (err) {
                    return 1;
                }
            });
            setIdeas(ideas);
        }
        setLoading(false);
    }

    const fetchFontFamilies = async () => {
        const res = await getDataFromAPI('auto-content/font-families/');
        if (res.data) {
            for (const fontObj of res.data) {
                await loadFonts(fontObj);
            }
            setFontFamilies(res.data);
        }
    }

    const showIdeaModal = async (idea) => {
        const res = await getDataFromAPI('product-ideas/' + idea.id + '/?additionalFields=highlightImages');
        setEditingIdea(res.data);
    }

    return (
        <Container className="idea-list-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore hasAllOption={true} showNbIdeas={true} defaultStore="first" />
                        <FilterByCategory />
                        <FilterByGrades />
                        <FilterBySearch />
                        <FilterByTaskStep />
                        {selectedStoreID && (
                            <FilterBySubjects storeID={selectedStoreID} />
                        )}
                    </div>
                </div>
                <div className="filter-right">
                    <div>
                        {selectedStoreID && (
                            <Button variant="info" className="mt-2" onClick={() => setShowAssetsStoreManagement(true)}>
                                Assets
                            </Button>
                        )}
                        {hasPermission(userRoles, activityPermissions['CREATE_PRODUCT_IDEA']) && (
                            <Button onClick={() => setShowCreateIdeaModal(true)}>New Product</Button>
                        )}
                        {userRoles.indexOf('admin') !== -1 && selectedIdeaIDs.length > 0 && (
                            <Button onClick={() => setShowReuploadFilesModal(true)} variant="danger">Re-upload files</Button>
                        )}
                    </div>
                </div>
            </div>

            <IdeasListTable
                editingIdea={editingIdea}
                setEditingIdea={setEditingIdea}
                showIdeaModal={showIdeaModal}
                ideas={ideas}
                fetchIdeas={fetchIdeas}
                selectedIdeaIDs={selectedIdeaIDs}
                setSelectedIdeaIDs={setSelectedIdeaIDs}
            />

            {showCreateIdeaModal && (
                <IdeaModal
                    defaultStore={selectedStoreID}
                    closeModal={action => {
                        if (action === 'create') {
                            setNotification('Create successfully');
                        }
                        setShowCreateIdeaModal(false);
                        fetchIdeas();
                    }}
                    openEditIdeaModal={(idea) => showIdeaModal(idea)}/>
            )}
            {showReuploadFilesModal && (
                <ReuploadFilesModal
                    selectedProductIDs={selectedIdeaIDs}
                    setLoading={setLoading}
                    closeModal={action => setShowReuploadFilesModal(false)}
                />
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
            {notification && (
                <Notification title={notification} closeNotification={() => setNotification(null)} />
            )}

            {showAssetsStoreManagement && selectedStoreID && (
                <AssetsStoreManagementModal
                    storeID={selectedStoreID}
                    fontFamilies={fontFamilies}
                    closeModal={() => setShowAssetsStoreManagement(false)}
                />
            )}
        </Container>
    );
}

export default IdeaListPage;