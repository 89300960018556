import { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import './styles.scss';

export const IdeaLabel = ({ option }) => (
    <span>{option.customID}. {option.label}</span>
);

export const TemplateLabel = ({ option }) => (
    <span>{option.id}. {option.label}</span>
);

export const LIST_RESET_DESIGN_ITEMS = ['frame', 'headline', 'texts', 'others'];

export const isSelectAll = (currentResetDesignItems = []) => {
    return JSON.stringify(currentResetDesignItems.sort()) === JSON.stringify(LIST_RESET_DESIGN_ITEMS.sort());
};

const SettingDesignTicketModal = ({
    hideModal,
    initialTicket = null
}) => {
    const currentResetDesignItems = initialTicket?.resetDesignItems || [];
    const [idea, setIdea] = useState(initialTicket?.idea ? { label: initialTicket.idea, value: initialTicket.idea } : '');
    const [template, setTemplate] = useState(initialTicket?.template ? { label: initialTicket.template, value: initialTicket.template } : '');
    const [selectAll, setSelectAll] = useState(isSelectAll(currentResetDesignItems) || false);
    const [resetFrame, setResetFrame] = useState(currentResetDesignItems.includes('frame') || false);
    const [resetHeadline, setResetHeadline] = useState(currentResetDesignItems.includes('headline') || false);
    const [resetTexts, setResetTexts] = useState(currentResetDesignItems.includes('texts') || false);
    const [resetOthers, setResetOthers] = useState(currentResetDesignItems.includes('others') || false);
    const [resetPageIndex, setResetPageIndex] = useState(currentResetDesignItems.includes('page_index') || false);
    const [autoResizeText, setAutoResizeText] = useState(initialTicket?.autoResizeText || true);
    const [loading, setLoading] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([]);

    const loadOptions = useCallback((url, callback) => {
        // Use the debounced fetch function
        fetchOptions(url, callback);
    }, []);

    useEffect(() => {
        if (selectAll) {
            setResetFrame(true);
            setResetHeadline(true);
            setResetTexts(true);
            setResetOthers(true);
            setResetPageIndex(true);
        }
    }, [selectAll]);

    useEffect(() => {
        const fetchAllTemplateRelatedToSelectedIdea = async () => {
            setLoading(true);
            if (idea?.value) {
                const res = await getDataFromAPI(`auto-content/get-all-templates-related-to-idea/?ideaID=${idea.value}`);
                const options = res.data.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                }));
                setTemplateOptions(options);
            };
            setLoading(false);
        };

        fetchAllTemplateRelatedToSelectedIdea();
    }, [idea]);

    const handleSettingTicket = async () => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'product-dev/check-design-ticket-valid/', { ideaID: idea?.value, templateID: template?.value });
        if (!res.data['valid']) {
            window.alert('Some pages are design validated. Invalidate those pages then try again.');
            setLoading(false);
            return;
        }
        const resetDesignItems = [];
        if (resetFrame) {
            resetDesignItems.push('frame');
        }
        if (resetHeadline) {
            resetDesignItems.push('headline');
        }
        if (resetTexts) {
            resetDesignItems.push('texts');
        }
        if (resetOthers) {
            resetDesignItems.push('others');
        }
        if (resetPageIndex) {
            resetDesignItems.push('page_index');
        }
        let url;
        const data = {
            template: template?.value || null,
            resetDesign: true,
            resetDesignItems,
            autoResizeText,
        };

        if (initialTicket === null) {
            data['customID'] = idea['customID'];
            url = 'product-dev/design-tickets/';
            await updateDataAPI('POST', 'product-dev/design-tickets/', data);
        } else if (initialTicket?.id) {
            console.log(data);
            data['idea'] = idea['value'];
            console.log(data);
            url = `product-dev/design-tickets/${initialTicket?.id}/`;
            await updateDataAPI('PATCH', url, data);
        }

        setLoading(false);
        hideModal('refresh');
    }

    const fetchOptions = debounce(async (url, callback) => {
        setLoading(true);
        try {
            const res = await getDataFromAPI(url);

            const formattedData = res.data.map((item) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            callback(formattedData);
        } catch (err) {

        }
        setLoading(false);
    }, 300);

    const hanldeIdeaChange = (value) => {
        setIdea(value);
        setTemplate('');
    }

    return (
        <Modal show={true} onHide={hideModal} className="new-design-ticket-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {initialTicket === null ? 'New Design Ticket' : 'Edit Design Ticket'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Idea*</strong>&#40; Search by customID and title &#41;: {idea?.value}</Form.Label>
                        <AsyncSelect
                            value={idea}
                            onChange={hanldeIdeaChange}
                            isLoading={loading}
                            loadOptions={(inputString, callback) => loadOptions(`resources/search-product-ideas/?keyword=${inputString}&filterByTitle=false`, callback)}
                            defaultOptions
                            isClearable
                            isSearchable
                            formatOptionLabel={(option) => (<IdeaLabel key={option.id} option={option} />)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Template: {template?.value}</Form.Label>
                        <Select
                            className="select-template"
                            classNamePrefix="select"
                            isLoading={loading}
                            isClearable
                            isSearchable
                            name="template"
                            value={template}
                            options={templateOptions}
                            formatOptionLabel={(option) => (<TemplateLabel key={option.id} option={option} />)}
                            onChange={(selectedValue) => setTemplate(selectedValue)}
                        />
                    </Form.Group>
                    <div className="form-header">
                        <h2>Items to reset</h2>
                        <span className="select-all-btn" onClick={() => setSelectAll(!selectAll)}>Select all</span>
                    </div>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="newDesignTicketResetFrameCheckbox">
                                <Form.Check type="checkbox" label="Frame" checked={resetFrame}
                                    onChange={e => setResetFrame(!resetFrame)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="newDesignTicketResetHeadlineCheckbox">
                                <Form.Check type="checkbox" label="Headline" checked={resetHeadline}
                                    onChange={e => setResetHeadline(!resetHeadline)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="newDesignTicketResetTextsCheckbox">
                                <Form.Check type="checkbox" label="Texts" checked={resetTexts}
                                    onChange={e => setResetTexts(!resetTexts)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="newDesignTicketResetShapesAndImagesCheckbox">
                                <Form.Check type="checkbox" label="Others (Shapes, Images...)" checked={resetOthers}
                                    onChange={e => setResetOthers(!resetOthers)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h2>Others</h2>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="newDesignAutoResizeTextCheckbox">
                                <Form.Check type="checkbox" label="Auto Resize Text" checked={autoResizeText}
                                    onChange={e => setAutoResizeText(!autoResizeText)} />
                                <Form.Text>Auto set text's font size</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="newDesignTicketResetPageIndexCheckbox">
                                <Form.Check type="checkbox" label="Page Index" checked={resetPageIndex}
                                    onChange={e => setResetPageIndex(!resetPageIndex)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <Button onClick={handleSettingTicket} disabled={idea === null || idea === ''}>{initialTicket === null ? 'Create' : 'Save'}</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default SettingDesignTicketModal;