import { useEffect, useRef, useState } from 'react';
import Constant from '../../../Constant';

const DEFAULT_TEXT_LINKED_ZONE = {
    id: null,
    idType: '',
    activityId: '',
    pageIndex: 0,
    x: null,
    y: null,
    type: 4,
    worksheet: '',
    width: null,
    height: null,
    rotate: 0,
    textAttribute: null,
    wordList: [],
    isShow: true,
    opacity: 1,
    moduleId: '',
    isGroup: false,
    resourceIds: [],
    backgroundColor: '#FFFFFF',
    customZoneID: null,
}

const TextZone = ({ zone, isText, isThumbnail, size }) => {
    const textAttribute = zone?.textAttribute ?? DEFAULT_TEXT_LINKED_ZONE;

    const [fontSize, setFontSize] = useState(textAttribute?.fontSize);
    const contentRef = useRef(null);
    const scaleRatio = isThumbnail ? Constant.LITE_CANVAS_THUMBNAIL_SCALE : ((size === 'medium' ? 1 : 1.5) * Constant.LITE_CANVAS_SCALE);

    useEffect(() => {
        const contentElement = contentRef.current;
        const containerWidth = contentElement.offsetWidth;
        const containerHeight = contentElement.offsetHeight;
        const contentWidth = contentElement.scrollWidth;
        const contentHeight = contentElement.scrollHeight;

        if (contentWidth > containerWidth || contentHeight > containerHeight) {
            const newFontSize = Math.min(
                containerWidth / contentWidth,
                containerHeight / contentHeight
            ) * fontSize;
            setFontSize(newFontSize);
        }
    }, [fontSize]);

    // useEffect(() => {
    //     const resizeText = () => {
    //         if (contentRef.current) {
    //             const textComponent = contentRef.current;
    //             let currentFontSize = parseInt(textComponent.style.fontSize, 10);

    //             currentFontSize = parseInt(textComponent.style.fontSize, 10);

    //             const containerHeight = zone['height'];

    //             let { height: textHeight } = getSizeDivText({
    //                 textAttribute,
    //                 width: zone.width,
    //             });

    //             const fontSizeMin = 5;

    //             while ((textHeight > containerHeight) && (currentFontSize > fontSizeMin)) {
    //                 currentFontSize -= 1;

    //                 textHeight = getSizeDivText({
    //                     textAttribute: {
    //                         ...textAttribute,
    //                         fontSize: currentFontSize,
    //                     },
    //                     width: zone.width,
    //                 }).height;
    //             };

    //             textComponent.style.fontSize = `${currentFontSize}px`;
    //             setFontSize(currentFontSize);
    //         };
    //     };

    //     // Resize text initially and on window resize
    //     resizeText();
    //     window.addEventListener('resize', resizeText);

    //     // Clean up event listener on component unmount
    //     return () => {
    //         window.removeEventListener('resize', resizeText);
    //     };
    // })

    return (
        <div className="text-zone"
            ref={contentRef}
            style={{
                width: `${zone.width * scaleRatio}px`,
                height: `${zone.height * scaleRatio}px`,
                transform: `translate(${zone.x * scaleRatio}px, ${zone.y * scaleRatio}px) rotate(${zone.rotate}deg)`,
                fontSize: `${isText ? (zone.textAttribute.fontSize * scaleRatio) : fontSize}px`,
                touchAction: 'pan-x pan-y pinch-zoom',
                color: `${textAttribute.fontColor}`,
                textAlign: textAttribute.align,
                color: textAttribute.fontColor ?? '#212121',
                fontFamily: textAttribute.fontFamily,
                fontStyle: textAttribute.isItalic ? 'italic' : 'normal',
                fontWeight: textAttribute.isBold ? 'bold' : null,
            }}
        >
            {(textAttribute?.content !== null && typeof textAttribute.content === 'string') ? textAttribute?.content : '###'}
        </div>
    )
}

export default TextZone;