import './styles.scss';
import TextZone from './text-zone';
import Constant from '../../../Constant';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';
import { useSelector } from 'react-redux';
import ZoomInLiteCanvasModal from './zoom-in-lite-canvas-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const RESOURCE_TEXT_TYPE = 4;
const RESOURCE_ZONE_TYPE = 50;

export const getDimensionOfWorksheet = (templateData, isThumbnail, size) => {
    const orientation = templateData?.orientation ?? 'vertical';
    let width;
    let height;

    if (orientation === 'horizontal') {
        width = isThumbnail ? Constant.THUMBNAIL_HEIGHT_A4 : ((size === 'medium' ? 1 : 1.5) * Constant.HEIGHT_A4);
        height = isThumbnail ? Constant.THUMBNAIL_WIDTH_A4 : ((size === 'medium' ? 1 : 1.5) * Constant.WIDTH_A4);
    } else {
        width = isThumbnail ? Constant.THUMBNAIL_WIDTH_A4 : ((size === 'medium' ? 1 : 1.5) * Constant.WIDTH_A4);
        height = isThumbnail ? Constant.THUMBNAIL_HEIGHT_A4 : ((size === 'medium' ? 1 : 1.5) * Constant.HEIGHT_A4);
    }

    return { width, height };
};

const LiteCanvas = ({
    ideaTemplate,
    isThumbnail = false,
    size="medium",
}) => {
    const { ideaSetting, ideaTemplateContents } = useSelector(state => state.autoContent);
    const [designItems, setDesignItems] = useState([]);
    const [showZoomInLiteCanvasModal, setShowZoomInLiteCanvasModal] = useState(false);

    const [dimensionOfWs, setDimensionOfWs] = useState({
        width: isThumbnail ? Constant.THUMBNAIL_WIDTH_A4 : ((size === 'medium' ? 1 : 1.5) * Constant.WIDTH_A4),
        height: isThumbnail ? Constant.THUMBNAIL_HEIGHT_A4 : ((size === 'medium' ? 1 : 1.5) * Constant.HEIGHT_A4),
    });

    useEffect(() => {
        fetchDesignItems();
    }, []);

    useEffect(() => {
        setDimensionOfWs(getDimensionOfWorksheet(ideaTemplate?.templateData, isThumbnail, size));
    }, [ideaTemplate]);

    const fetchDesignItems = async () => {
        let res = await getDataFromAPI(`auto-content/design-items/?ideaTemplateID=${ideaTemplate.id}`);
        let items = [];
        for (let i = 0; i < res.data.length; i++) {
            const item = res.data[i].attributes;
            item['id'] = res.data[i].id.toString();
            item['isMathFormula'] = res.data[i].isMathFormula;
            if (item.type === RESOURCE_TEXT_TYPE || item.type === RESOURCE_ZONE_TYPE) {
                item['textAttribute']['isAppliedAutoFontSize'] = true;
            }
            items.push(item);
        }

        const initItems = [];
        if (ideaTemplate['templateData']['autoAddHeadline']) {
            let headline;
            const headlineItem = items.find(item => item.isHeadline);
            if (headlineItem === undefined) {
                headline = Constant.DEFAULT_HEADLINE;
            } else {
                headline = headlineItem;
            }
            headline['textAttribute']['content'] = ideaSetting.headline;
            initItems.push(headline);
        }

        if (ideaTemplate['templateData'].orientation === 'vertical' && items.filter(item => item.isNameText).length === 0) {
            initItems.push(Constant.NAME_TEXT_ITEM);
        }

        await generateLinkedTexts(initItems.concat(items))
    }

    const generateLinkedCommonFields = (zone) => ({
        id: Math.floor(Math.random() * 999999999).toString(),
        x: zone.x,
        y: zone.y,
        width: zone.width,
        height: zone.height,
        customZoneID: zone.id,
        rotate: zone.rotate,
    });

    const generateLinkedTexts = async (items) => {
        const textZones = items.filter(item => item.type === RESOURCE_TEXT_TYPE || item.type === RESOURCE_ZONE_TYPE);
        const textItems = [];

        for (let i = 0; i < textZones.length; i++) {
            const linkedCommonFields = generateLinkedCommonFields(textZones[i]);

            let zoneContent = '';

            if (textZones[i].fieldName === 'page_index') {
                zoneContent = (ideaTemplate.pageIndex + 1).toString();
            } else if (textZones[i].fieldName !== 'icon') {
                zoneContent = ideaTemplateContents[ideaTemplate.id]?.worksheet[textZones[i].id] ?? '###';
            }

            if (textZones[i].type === Constant.TYPE_RESOURCE_ZONE) {
                textItems.push({
                    ...linkedCommonFields,
                    type: RESOURCE_ZONE_TYPE,
                    textAttribute: {
                        ...textZones[i].textAttribute,
                        content: zoneContent,
                    },
                });
            } else if (textZones[i].isHeadline) {
                textItems.push({
                    ...Constant.DEFAULT_HEADLINE,
                    ...linkedCommonFields,
                    textAttribute: {
                        ...textZones[i].textAttribute,
                    },
                });
            } else {
                textItems.push(textZones[i]);
            }
        }

        setDesignItems(textItems);
    }

    return (
        <div className="lite-canvas-container">
            <div style={{ ...dimensionOfWs, }} className="lite-canvas">
                {designItems.filter(item => item.type === RESOURCE_TEXT_TYPE || item.type === RESOURCE_ZONE_TYPE).map((designItem) => (
                    <TextZone
                        key={designItem.id}
                        zone={designItem}
                        isText={designItem.type === RESOURCE_TEXT_TYPE}
                        isThumbnail={isThumbnail}
                        size={size}
                    />
                ))}

            </div>
            <FontAwesomeIcon className="zoom-icon" icon={faMagnifyingGlass} onClick={() => setShowZoomInLiteCanvasModal(true)} />

            {showZoomInLiteCanvasModal && (
                <ZoomInLiteCanvasModal
                    ideaTemplate={ideaTemplate}
                    hideModal={() => setShowZoomInLiteCanvasModal(false)} />
            )}

        </div>
    )
}

export default LiteCanvas;