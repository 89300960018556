import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import { Button, Container, ProgressBar, Spinner, Table } from 'react-bootstrap';
import SettingGenDataTicketModal from '../../components/gen-data-ticket/setting-design-ticket-modal';
import Constant from '../../Constant';
import Moment from 'react-moment';

const GenDataTicketsPage = () => {
    const [tickets, setTickets] = useState([]);
    const [showSettingTicketModal, setShowSettingTicketModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
        fetchTickets();
    }, []);

    const fetchTickets = async() => {
        setLoading(true);
        const res = await getDataFromAPI(`product-dev/gen-data-tickets`);
        setTickets(res.data);
        setLoading(false);
    }

    const deleteTicket = async(ticket) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            await updateDataAPI('DELETE', 'product-dev/gen-data-tickets/' + ticket.id + '/');
            setTickets(tickets.filter(t => t.id !== ticket.id));
            setLoading(false);
        }
    }

    const refreshIdeaParts = async(customID) => {
        setLoading(true);
        await updateDataAPI('POST', 'product-dev/refresh-idea-parts/?customID=' + customID);
        setLoading(false);
    }

    return (
        <Container className="gen-data-tickets-page" fluid>
            <h2>Gen Data Tickets</h2>
            <div className="btns">
                <Button onClick={fetchTickets}>Refresh</Button>
                <Button onClick={() => setShowSettingTicketModal(true)}>Add New Ticket</Button>
            </div>
            <div>Pending: {tickets.filter(ticket => !ticket.done).length} tickets</div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Idea</th>
                        <th>Progress</th>
                        <th>Author</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map(ticket => (
                        <tr key={ticket.id}>
                            <td><a href={'/content-studio/?customID=' + ticket.customID} target="_blank">{ticket.customID}</a></td>
                            <td className="progresses-cell">
                                <div>
                                    <span>Data</span>
                                    <ProgressBar striped variant={ticket.done ? 'success' : 'warning'}
                                        now={ticket.done ? 100 : Math.floor(((ticket.nbFinishedParts ? ticket.nbFinishedParts : 0) / ticket.totalParts) * 100)} />
                                </div>
                                {ticket.designTicket && (
                                    <div>
                                        <span>Design</span>
                                        <ProgressBar striped variant={ticket.designTicketData.done ? 'success' : 'warning'}
                                            now={ticket.designTicketData.done ? 100 : Math.floor(((ticket.designTicketData.finishedPages ? ticket.designTicketData.finishedPages.length : 0) / ticket.designTicketData.totalPages) * 100)} />
                                    </div>
                                )}
                            </td>
                            <td>{ticket.authorName}</td>
                            <td><Moment format="HH:mm DD/MM/YYYY">{ticket.createdAt}</Moment></td>
                            <td className="actions-cell">
                                <Button size="sm" variant="danger" onClick={() => deleteTicket(ticket)}>Delete</Button>
                                {userRoles.indexOf('admin') > -1 && (
                                    <Button size="sm" onClick={() => refreshIdeaParts(ticket.customID)}>Refresh Parts</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showSettingTicketModal && (
                <SettingGenDataTicketModal
                    initialTicket={showSettingTicketModal}
                    hideModal={action => {
                        setShowSettingTicketModal(false);
                        if (action === 'refresh') {
                            fetchTickets();
                        }
                    }} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default GenDataTicketsPage;