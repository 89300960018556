import { useEffect, useState, Fragment } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { checkObjectHasNullebaleValue } from '../../utils/commonUtil';
import UploadNewTopicImage from '../../components/topics/upload-new-topic-image';
import { toast } from 'react-toastify';

import './styles.scss';

export const sortTopicsByAlphabet = (input = []) => {
    return input.sort(function(a, b) {
        return a.name.localeCompare(b.name);
    });
}

const TopicsPage = () => {
    const [topics, setTopics] = useState([]);

    const [inputTopicName, setInputTopicName] = useState('');
    const [editedCell, setEditedCell] = useState(null);

    useEffect(() => {
        const fetchTopics = async () => {
            const res = await getDataFromAPI('resources/topics/');
            setTopics(sortTopicsByAlphabet(res.data));
        };

        fetchTopics();
    }, []);

    const handleCreateNewTopic = async (e) => {
        e.preventDefault();
        if (!checkObjectHasNullebaleValue({ inputTopicName })) return;
        try {
            const res = await updateDataAPI('POST', 'resources/topics/', {
                name: inputTopicName,
            });
            setTopics((prev) => sortTopicsByAlphabet([...prev, res.data]));
        } catch (err) {
            console.error(`An error occured when creating new topic: ${err.message}`);
        }
    }

    const handleCellChange = (event, field) => {
        const { value } = event.target;
        setEditedCell((prevCell) => ({
            ...prevCell,
            [field]: value
        }));
    };

    const deleteTopic = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this topic?');

        if (confirmed) {
            setTopics((prev) => prev.filter((topic) => topic.id !== id));

            try {
                await updateDataAPI('DELETE', `resources/topics/${id}/`);
                toast.success('Deleted successfully.');
                setEditedCell(null);
            } catch (e) {
                toast.error(`An error occured when deleting: ${e.message}`);
            };
        };
    };

    const updateTopicOnServer = async () => {
        try {
            await updateDataAPI('PATCH', `resources/topics/${editedCell['id']}/`, editedCell);
            toast.success('Updated successfully.');
            setEditedCell(null);
        } catch (e) {
            toast.error(`An error occured when updating: ${e.message}`);
        };
    };

    const updateTopic = async () => {
        if (editedCell.name !== '') {
            setTopics((prev) => prev.map((topic) => {
                if (topic.id === editedCell.id) {
                    return editedCell;
                };
                return topic;
            }));
            await updateTopicOnServer();
        };
    };

    return (
        <Container className="topics-page">
            <div className="mb-2">
                <div>
                    <Form>
                        <Form.Label>Create new topic</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Create new topic"
                            name="topicName"
                            value={inputTopicName}
                            onChange={(e) => setInputTopicName(e.target.value)}
                        />
                    </Form>
                    <Button variant="primary" type="submit" onClick={handleCreateNewTopic} className="float-end mt-2 mb-2">
                        Create
                    </Button>
                </div>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Name</th>
                        <th>Images</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {topics.map((topic) => (
                        <tr key={topic.id}>
                            <td>{topic.id}</td>
                            <td>
                                {editedCell && editedCell.id === topic.id ? (
                                    <Form.Control
                                        name="name"
                                        value={editedCell?.name}
                                        onChange={(e) => handleCellChange(e, 'name')}
                                        autoFocus
                                    />
                                ) : (
                                    topic.name
                                )}
                            </td>
                            <td>
                                <UploadNewTopicImage
                                    topic={topic}
                                    topicName={topic.name}
                                    handleUploadedTopic={(newTopic) => {
                                        setTopics((prev) => prev.map((topic) => {
                                            if (topic.name === newTopic.name) {
                                                return newTopic;
                                            }
                                            return topic;
                                        }))
                                    }}
                                    type='topics'
                                />
                            </td>
                            <td>
                                {(editedCell && editedCell.id === topic.id) && (
                                    <Fragment>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => updateTopic(topic)}
                                            className="me-2"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => setEditedCell(null)}
                                            className="me-2"
                                        >
                                            Cancel
                                        </Button>
                                    </Fragment>
                                )}
                                {editedCell === null && (
                                    <Button variant="primary" size="sm" onClick={() => setEditedCell(topic)}>
                                        Edit
                                    </Button>
                                )}
                                {" "}
                                <Button variant="danger" size="sm" onClick={() => deleteTopic(topic.id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default TopicsPage;