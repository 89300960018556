import React, { Fragment } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PagePagination = ({
    currentPage = 1,
    totalPages = 10,
    handlePageChange = () => { },
}) => {
    return (
        <Fragment>
            {totalPages > 0 && (
                <Pagination className="d-flex justify-content-center">
                    <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            )}
        </Fragment>
    );
};

export default PagePagination;