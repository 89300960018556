import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { updateDataAPI } from '../../../../utils/query';
import ReactQuill from 'react-quill';

const IdeaModalTitleDescriptionTab = ({
    customID,
    title,
    setTitle,
    titleReachMaxLength,
    setTitleReachMaxLength,
    titleFreeReachMaxLength,
    setTitleFreeReachMaxLength,
    titleWarning,
    isTitleVerified,
    titleFree,
    setTitleFree,
    titleFreeWarning,
    isTitleFreeVerified,
    freeSkills,
    description,
    setDescription,
    descriptionFree,
    setDescriptionFree,
}) => {
    const handleChangeTitle = async (e) => {
        const { value } = e.target;

        if (value.length <= 80) {
            setTitle(value);
        }
        setTitleReachMaxLength(value.length >= 80);
    }

    const autoGenerateDescription = async() => {
        try {
            const res = await updateDataAPI('POST', 'resources/generate-auto-idea-description/?customID=' + customID);
            setDescription(res.data['description']);
            setDescriptionFree(res.data['descriptionFree']);
        } catch (err) {
            window.alert('Can not auto fill description. Check store template or other info using in desc like title, subjects, grades then try again');
            return;
        }
    }

    return (
        <div>
            <Row className="mt-3">
                <Col>
                    <Form.Group className="mb-3 field-with-event">
                        <Form.Label>
                            Title PAID (max 80 characters)
                            {/* <FontAwesomeIcon icon={faCalendar} className="show-product-idea-event-icon" onClick={() => setEdittingFieldByEvent('title')} /> */}
                        </Form.Label>
                        <Form.Control as="textarea" rows={4} value={title} maxLength="80" onChange={(e) => handleChangeTitle(e)} />
                        {titleReachMaxLength && (
                            <Alert variant="warning" className="mt-3">Title reaches 80 characters</Alert>
                        )}
                        {!isTitleVerified && (
                            <Alert variant="warning" className="mt-3">{titleWarning}</Alert>
                        )}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3 field-with-event">
                        <Form.Label>
                            Title FREE (max 80 characters)
                        </Form.Label>
                        <Alert variant="warning">
                            <div><u>Remove the number of pages</u> of the PAID product in the Free Product</div>
                        </Alert>
                        {freeSkills.length > 0 && (
                            <Alert variant="warning">
                                <div>Free Skills: {freeSkills.map(skill => skill.name).join(', ')}</div>
                            </Alert>
                        )}
                        <Form.Control as="textarea" rows={4} value={titleFree} maxLength="80" onChange={e => {
                            if (e.target.value.length <= 80) {
                                setTitleFree(e.target.value);
                            }
                            setTitleFreeReachMaxLength(e.target.value.length >= 80);
                        }} />
                        {titleFreeReachMaxLength && (
                            <Alert variant="warning" className="mt-3">Title reaches 80 characters</Alert>
                        )}
                        {!isTitleFreeVerified && (
                            <Alert variant="warning" className="mt-3">{titleFreeWarning}</Alert>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <div className="description-header">
                            <Form.Label>Description PAID</Form.Label>
                            <Button variant="link" onClick={autoGenerateDescription}>Auto Generate</Button>
                        </div>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description FREE</Form.Label>
                        <Alert variant="danger">
                            KHÔNG ghi các thông tin mà sản phẩm free không có trong Description của sản phẩm free. <br />
                            Tip: copy desc của sản phẩm paid ở trên, bỏ hết các thông tin về <u>số trang</u> và các <u>thông tin chi tiết có thể bị bắt bẻ</u> về các hoạt động như what's included, tránh bị user report khi viết activity trong desc free mà sp free lại ko có activity đó</Alert>
                        <ReactQuill theme="snow" value={descriptionFree} onChange={setDescriptionFree} />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default IdeaModalTitleDescriptionTab;