import React from 'react';
import UploadNewTopicImage from '../upload-new-topic-image';
import ImageWrapper from '../../../components/common/image-wrapper';
import { updateDataAPI } from '../../../utils/query';

const UploadTopicImageCell = ({ topic, handleUpdatedTopic = () => { }, topicGroup = {}, topicGroupIcon, setTopicGroupIcon }) => {

    const handleChangeTopicGroupIcon = async (updatedIconID) => {
        try {
            let res;
            const url = topicGroupIcon ? `resources/topic-group-icons/${topicGroupIcon.id}/` : 'resources/topic-group-icons/';

            if (topicGroupIcon) {
                res = await updateDataAPI('PATCH', url, {
                    icon: updatedIconID || null,
                });
            } else {
                res = await updateDataAPI('POST', url, {
                    topic: topic.id,
                    topicGroup: topicGroup.id,
                    icon: updatedIconID || null,
                });
            }

            setTopicGroupIcon(res.data);
        } catch (err) {
            const errorMessage = topicGroupIcon
                ? `An error occurred when updating topic group icon with id ${topicGroupIcon.id}: ${err.message}`
                : `An error occurred when creating topic group icon: ${err.message}`;

            console.error(errorMessage);
        }
    };

    return (
        <div>
            <div className="d-flex align-items-center gap-2">
                <div className="m-auto mb-2">
                    {topic?.imageUrl && (
                        <ImageWrapper src={topic.imageUrl} alt={`Topic image ${topic.name}`} className="topic-image" />
                    )}
                </div>
            </div>
            <UploadNewTopicImage
                topic={topic}
                topicIcon={topicGroupIcon?.icon}
                topicName={topic.name}
                handleUploadedTopic={handleUpdatedTopic}
                handleChangeIconOption={handleChangeTopicGroupIcon}
                type='topicGroups'
            />
        </div>
    )
}

export default UploadTopicImageCell;