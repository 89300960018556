import { createSlice } from '@reduxjs/toolkit';

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        stores: [],
        selectedStoreID: null,
        selectedCategory: null,
        selectedEvent: null,
        selectedTag: null,
        searchKeyword: '',
        selectedGrades: [],
        selectedSubjects: [],
        selectedStaff: null,
        startDate: null,
        endDate: null,
        selectedTaskFlow: null,
        selectedTaskStep: null,
        selectedIconsResource: 'all',
    },
    reducers: {
        setStores: (state, action) => {
            state.stores = action.payload;
        },
        setSelectedStoreID: (state, action) => {
            state.selectedStoreID = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedEvent: (state, action) => {
            state.selectedEvent = action.payload;
        },
        setSelectedTag: (state, action) => {
            state.selectedTag = action.payload;
        },
        setSearchKeyword: (state, action) => {
            state.searchKeyword = action.payload;
        },
        addSelectedGrade: (state, action) => {
            state.selectedGrades.push(action.payload);
            state.selectedGrades.sort();
        },
        removeSelectedGrade: (state, action) => {
            const index = state.selectedGrades.indexOf(action.payload);
            if (index > -1) {
                state.selectedGrades.splice(index, 1);
                state.selectedGrades.sort();
            }
        },
        addSelectedSubject: (state, action) => {
            state.selectedSubjects.push(action.payload);
            state.selectedSubjects.sort();
        },
        removeSelectedSubject: (state, action) => {
            const index = state.selectedSubjects.indexOf(action.payload);
            if (index > -1) {
                state.selectedSubjects.splice(index, 1);
                state.selectedSubjects.sort();
            }
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setSelectedStaff: (state, action) => {
            state.selectedStaff = action.payload;
        },
        setSelectedTaskFlow: (state, action) => {
            state.selectedTaskFlow = action.payload;
        },
        setSelectedTaskStep: (state, action) => {
            state.selectedTaskStep = action.payload;
        },
        setSelectedIconsResource: (state, action) => {
            state.selectedIconsResource = action.payload;
        }
    },
})

export const {
    setStores,
    setSelectedStoreID,
    setSelectedCategory,
    setSelectedEvent,
    setSelectedTag,
    setSearchKeyword,
    addSelectedGrade,
    removeSelectedGrade,
    addSelectedSubject,
    removeSelectedSubject,
    setStartDate,
    setEndDate,
    setSelectedStaff,
    setSelectedTaskFlow,
    setSelectedTaskStep,
    setSelectedIconsResource,
} = filtersSlice.actions;

export default filtersSlice.reducer;