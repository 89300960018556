import { useState, useEffect } from 'react';
import { getDataFromAPI } from '../../../utils/query';
import './styles.scss';
import StaffTodayTasks from '../staff-today-task';

const StaffPerformanceBar = () => {
    const [staffPerformanceThisWeek, setStaffPerformanceThisWeek] = useState(0);

    useEffect(() => {
        fetchStaffPerformance();
    }, []);

    const fetchStaffPerformance = async () => {
        try {
            const res = await getDataFromAPI('product-dev/get-staff-kpi-per-week/');
            const data = res.data;
            setStaffPerformanceThisWeek(data);
        } catch (err) {
            console.error(`An error occured when fetching staff performance: ${err.message}`);
        };
    };

    return (
        <div className="staff-performance-bar">
            {/* <div className="d-flex justify-content-between align-items-center">
                <span className="me-2">Staff KPI this week: {staffPerformanceThisWeek.staffPoints} / {staffPerformanceThisWeek.totalPoints} points</span>
                <Button variant="primary" onClick={() => {
                    fetchStaffPerformance();
                    getStaffTodayTasks();
                }}>
                    <FontAwesomeIcon fontSize={20} icon={faArrowsRotate} />
                </Button>
            </div>
            <ProgressBar
                now={staffPerformanceThisWeek.staffPerformance}
                label={`${staffPerformanceThisWeek.staffPerformance}%`}
                variant="success"/> */}
            <StaffTodayTasks />
        </div>
    )
}

export default StaffPerformanceBar;