import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { getDataFromAPI } from '../../../utils/query';

const StaffTodayTasks = () => {
    const [todayTasks, setTodayTasks] = useState([]);

    useEffect(() => {
        getStaffTodayTasks();
    }, []);

    const getStaffTodayTasks = async () => {
        const url = 'product-dev/get-staff-tasks-today/';

        const res = await getDataFromAPI(url);

        setTodayTasks(res.data);
    };

    return (
        <Dropdown onClick={getStaffTodayTasks} size="sm">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Done Tasks Today
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {todayTasks.map((task, index) => (
                    <>
                        <Dropdown.Item>{index + 1}. {task}</Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default StaffTodayTasks;